import React from "react";
import "./Footer.scss";


function Footer() {
	return (
		<footer className="footer is-primary">
			<section className="footer-content full-width">
			
			<p className="tagline">
			
			<span style = {{paddingRight:"10px"}}>&copy; {new Date().getFullYear()} Advantage General Insurance Company</span> <span style = {{borderLeft:"1px solid rgb(250, 250, 250)"}}><a href="https://www.sagicor.com/en-JM/Privacy-Policy"  style={{color: "white", paddingLeft:"10px"}}>
			 Privacy Policy
	    	</a> </span></p>
			
			</section>
		</footer>
	);
}

export default Footer;
