import { format, isBefore, isEqual, add, subDays, parse } from "date-fns";
import {
	includedPolicies,
	applicableNarratives,
	homePolicies,
	applicableRiders,
	comprehensiveCodes,
	comprehensiveAgreedValueCodes,
	uninsured_Motorist,
	PA_death_Disablement,
	claimAcceptablePolicies,
	applicable_policy_prefix_promo,
} from "./constants";
import config from "../config";
import PolicyNetworkLayer from "./network";
import { nodeApi } from "api/Middleware";
import { formatPolicyForRenewal } from "./policyFormatting";
import { checkExpToken, getNewGlobalToken } from "helpers/auth";
import { setActivePolicies, setCurrentUser, setToken } from "globals/auth";
import { AuthNetworkLayer, PolicyHelper } from "helpers";
import { store } from "../../globals/index";
import jwtDecoder from "jwt-decode";
import { createLog } from "helpers/log";

export const ALLOWED_CERT_NAR_CODES = [
	"2017",
	"2001",
	"2002",
	"2003",
	"2004",
	"2005",
	"2006",
	"2007",
	"2009",

	"2010",
	"2011",
	"2012",

	"2013",
	"2014",
	"2015",
	"2016",
	"2018",

	"2019",
	"2024",
	"2025",
	"2026",
	"2027",
	"2028",
	"2029",
	"2030",

	"2034",
	"2035",
	"2040",

	"2048",
	"2055",
];

export const APPLICABLE_RIDERS = {
	pa_rider: ["131", "132", "133", "134", "135"],
	um_rider: ["140", "141", "142", "143", "144", "145", "147"],
};

/**
 * A helper function to identify the `policy type` of a policy [ home | motor | ppv ]
 *
 * @param {Object} policy the policy to identify type for
 */

/*function policyType(policy) {}*/

function getRisksofType(policy, type) {
	return policy.risks.length
		? policy.risks.filter(risk => {
				if (!risk) return false;

				let riskType = risk.risk_item_type.toLowerCase();
				return type === "building&content"
					? riskType.includes("building") || riskType.includes("content")
					: riskType.includes(type);
		  })
		: [];
}

/** Get the policies that are applicable for renewal */
function filterForPoliciesWithNoPlateRisks(policies) {
	let filteredPolicies = policies?.filter(p => p.risks?.filter(r => r.registration_number === "")?.length > 0);

	filteredPolicies.forEach(element => {
		element.risks = element.risks?.filter(r => r.registration_number === "");
	});

	return filteredPolicies;
}

/** Get the policies that are applicable for renewal */
function filterForRenewal(policies) {
	return policies
		?.filter(policy => {
			return (
				policy.status === "In Renewal" && policy.renewal_status !== "Scrutiny" && policy.policy_prefix !== "NCBMLC"
			);
		})
		?.map(p => {
			return {
				...p,
				original_renewal_premium: p.renewal_premium,
				risks: p.risks.map(r => {
					return { ...r, original_sum_insured: r.sum_insured };
				}),
			};
		});
}

/** get the claims policies */
function filterForClaims(policies) {
	return policies; //?.filter(policy => [...includedPolicies, ...claimAcceptablePolicies].includes(policy.policy_prefix));
}

/** Get the Policies with a Balance */
function filterForExtension(policies) {
	return policies.filter(policy => {
		return (
			policy.status === "In Force" &&
			policy.renewal_status !== "Scrutiny" &&
			policy.paymentPlan?.payment_terms.some(plan => !plan.transaction_id) &&
			policy.policy_prefix !== "NCBMLC"
		);
	});
}

/** Gets all Active Policies */
function findAllActivePolicies(policies) {
	return policies.filter(policy => {
		return policy.status === "In Force" && policy.renewal_status !== "Scrutiny";
	});
}

function findAllActiveAndRenewablePolicies(policies) {
	return policies.filter(policy => {
		return policy.status === "In Force" || policy.status === "In Renewal";
	});
}

/** Missing Documents */
function listMissingDocuments(naratives) {
	return naratives.filter(narative => applicableNarratives.includes(narative.code));
}

const identifyPolicyType = (policy, policyType) => {
	if (policy && policyType) {
		policyType = policyType.toLowerCase();
		switch (policyType) {
			default:
				return false;
			case "home":
				return homePolicies.includes(policy.policy_prefix || "");
			case "motor":
				return !homePolicies.includes(policy.policy_prefix || "");
			case "ppv":
				return policy.type_of_cover ? policy.type_of_cover.toLowerCase().includes("passenger vehicle") : false;
		}
	}
	return false;
};

/**  */
function isUMRider(code) {
	return applicableRiders.um_rider.includes(code);
}

/**  */
function isPARider(code) {
	return applicableRiders.pa_rider.includes(code);
}

function isUpsellingApproved(policy) {
	let currentyear = new Date().getFullYear();
	return (
		policy?.is_third_party_policy && currentyear - policy.risks[0]?.year < 20 && policy.risks[0].usage_code === "SDP"
	);
}

/**
 *
 * @param {Object} policy

 * @returns
 */
async function getAdditionalBenefits(policy) {
	let payload = {
		policy_prefix: policy?.policy_prefix,
		is_third_party_policy: policy?.is_third_party_policy,
		group: policy?.group_name || "",
		risks: policy?.risks?.map(
			({
				usage_code,
				year: car_year,
				manual_rates: rates,
				risk_id,
				plate_number,
				sum_insured,
				building_year_built,
			}) => ({
				risk_id,
				usage_code,
				car_year,
				rates,
				plate_number,
				sum_insured,
				building_year_built,
			})
		),
	};
	let response = await checkExpToken({
		endpoint: "/additionalbenefit/generate",
		payload,
	}).catch(error => {
		if (error.response) return error.response;
	});

	return response;
}

/**
 *
 * @param {String} phoneNumberString
 */
function formatPhoneNumber(phoneNumberString) {
	var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
	var match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return "(" + match[1] + ") " + match[2] + "-" + match[3];
	}
	return null;
}

/**
 *
 * @param {*} selectedFile
 */
function toBase64(selectedFile) {
	return new Promise((resolve, reject) => {
		let reader = new FileReader();
		reader.readAsDataURL(selectedFile);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
		return reader.result;
	});
}

/**
 * Formats a given value to proper currency format
 * @param {*} amount
 * @param {Number=} decimalCount
 * @param {String=} decimal
 * @param {String=} thousands
 * @param {Boolean=} showNegative
 * @param {Boolean=} includeCurrencySymbol
 */
function formatMoney(
	amount,
	decimalCount = 2,
	decimal = ".",
	thousands = ",",
	showNegative = true,
	includeCurrencySymbol = true
) {
	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = showNegative && amount < 0 ? "-" : "";

		// The integer part of the number
		let integerPortion = parseInt(Math.abs(parseInt(amount) || 0).toFixed(decimalCount)).toString();
		// number or 3
		let j = integerPortion.length > 3 ? integerPortion.length % 3 : 0;

		const moneySymbol = includeCurrencySymbol ? "$" : "";
		const head = j ? integerPortion.substr(0, j) + thousands : "";
		const body = integerPortion.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands);
		const cents = decimal + (Math.abs(parseFloat(amount)) - parseFloat(integerPortion)).toFixed(decimalCount).slice(2);
		const formattedMoney = `${negativeSign}${moneySymbol}${head}${body}${cents}`;

		return formattedMoney;
	} catch (e) {
		console.error(e);
		return null;
	}
}

function toProperCase(str) {
	return str
		.toLowerCase()
		.replace(/\b\w/g, s => s.toUpperCase())
		.replace(/Mc(.)/g, (match, s) => "Mc" + s.toUpperCase())
		.replace(/Mac(.)/g, (match, s) => "Mac" + s.toUpperCase());
}

/** Removes all PA/UM rider premiums */
function removeAllRiderPremiums(risks) {
	const riders_list = risks?.[0]?.manual_rates;
	let totalDeduction = 0;

	if (riders_list?.length)
		totalDeduction = riders_list
			.filter(rider => [...applicableRiders.pa_rider, ...applicableRiders.um_rider].includes(rider.code))
			.reduce((total, rider) => total + rider.premium, 0);

	return totalDeduction;
}

// Remove the riders to calculate something
const removeRiderPremium = (rider, risks, premium) => {
	const riders_list = risks[0].manual_rates;

	riders_list.forEach(currentRider => {
		if (rider === "pa_rider" && applicableRiders.pa_rider.includes(currentRider.code)) {
			premium = premium - currentRider.premium;
		} else if (rider === "um_rider" && applicableRiders.um_rider.includes(currentRider.code)) {
			premium = premium - currentRider.premium;
		}
	});

	return premium;
};

const getRiderPremium = (rider, risks) => {
	const riders_list = risks[0].manual_rates;
	let premium = 0;

	riders_list.forEach(currentRider => {
		if (rider === "pa_rider" && applicableRiders.pa_rider.includes(currentRider.code)) {
			premium = premium + currentRider.premium;
		} else if (rider === "um_rider" && applicableRiders.um_rider.includes(currentRider.code)) {
			premium = premium + currentRider.premium;
		} else if (rider === "" && hasRider(currentRider.code)) {
			premium = premium + currentRider.premium;
		}
	});

	return premium;
};

// Calculate the half payment for a policy with the calculater
async function calculatePayment(branch_name, policy, user, currency, addedCost = 0, rates = []) {
	const results = await getPremiumFromCalculator(branch_name, policy, user, currency);
	if (results.success) {
		const tax_percent = policy.tax_percent;

		let payment_plans = results.payment_plans.map(plan => {
			// Map all except [2] of these payment
			const payment_terms = plan.payment_terms;
			const half_premium = plan.payment_terms[0].payment_term_premium;
			let second_premium_payment = plan.payment_terms[1].payment_term_premium;

			let service_charge =
				plan.payment_terms[0].service_charge_type === "PP"
					? (plan.payment_terms[0].service_charge_amount / 100) * half_premium
					: plan.payment_terms[0].service_charge_amount;
			// let gct = (tax_percent / 100) * removeRiderPremium("pa_rider", policy.risks, half_premium);
			let gct = (tax_percent / 100) * half_premium;

			let second_premium_payment_gct = (tax_percent / 100) * second_premium_payment;
			second_premium_payment = second_premium_payment + second_premium_payment_gct;
			let total_premium = half_premium + gct;
			let half_premium_with_gct = half_premium + gct;

			let effective_date = plan.payment_terms[0].expiry_date;

			return {
				name: plan.payment_plan_name,
				id: plan.payment_plan_id,
				renewal_premium: half_premium - service_charge,
				renewal_premium_with_gct: half_premium_with_gct,
				second_premium_payment: second_premium_payment,
				GCT: gct,
				service_charge: service_charge,
				total: total_premium + addedCost || total_premium,
				effective_date: effective_date,
				expiry_date: plan.expiry_date,
				payment_terms,
			};
		});

		let gct = (tax_percent / 100) * results.full_payment;
		// let gct = (tax_percent / 100) * removeRiderPremium("pa_rider", policy.risks, results.full_payment);
		let full_payment = results.full_payment + gct;

		return { full_payment: { full_payment, gct }, payment_plans, risks: results.risks };
	} else {
		return false;
	}
}

// find whether it's serious
function checkAgainstPremiumCode(keyWord, manual_rates) {
	let premium_code = keyWord.toLowerCase();
	//let tempRatesArry = [];

	if (premium_code === "uninsured") {
		for (let i = 0; i < uninsured_Motorist.length; i++) {
			for (let j = 0; j < manual_rates.length; j++) {
				if (uninsured_Motorist[i] === manual_rates[j].code) {
					//tempRatesArry.push(manual_rates[j]);
					return manual_rates[j];
				}
			}
		}
	} else if (premium_code === "death" || premium_code === "medical" || premium_code === "disablement") {
		for (let i = 0; i < PA_death_Disablement.length; i++) {
			for (let j = 0; j < manual_rates.length; j++) {
				if (PA_death_Disablement[i] === manual_rates[j].code) {
					//tempRatesArry.push(manual_rates[j]);
					return manual_rates[j];
				}
			}
		}
	}
}

// Calculate the premium for Ultimate woman and PA
/**
 * This function is a magical pony. It may be understandable with effort but
 * it was copied as is and it's use throughout th application is a littl cryptic. All in All, this
 * function identifies Pa/Um riders
 *
 * @param {*} extensions
 * @param {*} risks
 * @returns
 */
function calcPremiumPA_UM(extensions, risks) {
	let pa_um = { pa: 0, um: 0 };
	let matchedRates = [];
	let uninsured = "Uninsured";
	let uninsured_av = "Agreed Value";
	let pa_1 = "Death";
	let pa_2 = "Medical";
	let pa_4 = "Disablement";

	for (let n = 0; n < extensions.length; n++) {
		if (extensions[n].extension.indexOf(uninsured) !== -1) {
			extensions[n].typeOfExt = "Uninsured Motorist";
			let temp = checkAgainstPremiumCode(uninsured, risks[0].manual_rates);
			if (temp) {
				matchedRates.push(temp);
				extensions[n].premium_um = temp.premium;
				pa_um.um = temp.premium;
			}
		} else if (
			extensions[n].extension.indexOf(pa_1) !== -1 ||
			extensions[n].extension.indexOf(pa_2) !== -1 ||
			extensions[n].extension.indexOf(pa_4) !== -1
		) {
			extensions[n].typeOfExt = "Personal Accident";
			if (extensions[n].extension.indexOf(pa_1) !== -1) {
				extensions[n].tier = "Death";
			} else if (extensions[n].extension.indexOf(pa_2) !== -1) {
				extensions[n].tier = "Medical";
			}
			let temp = checkAgainstPremiumCode(pa_1, risks[0].manual_rates);
			if (temp) {
				matchedRates.push(temp);
				extensions[n].premium_pa = temp.premium;
				pa_um.pa = temp.premium;
			}
		} else if (extensions[n].extension.indexOf(uninsured_av) !== -1) {
			extensions[n].typeOfExt = "Endorsement";
		}
	}
	if (isNaN(pa_um.pa) || isNaN(pa_um.um)) {
		pa_um = { pa: 0, um: 0 };
	}

	return { ...pa_um, extensions: extensions, codes: matchedRates };

	/* this.policy.renewal_premium = this.removeRiderPremium(
			this.policy.renewal_premium,
			extension.premium_pa + extension.premium_um
		);
		await this.policyService.setCurrentPolicy(this.policy);

		this.session.setRatesToDel(this.tempRatesArry);

		this.session.setPremiumPA_UM(this.pa_um);
		return extension; */
}

// @ts-ignore
/**
 *
 * @param {{policy: Object, global_name_id: string, total: any, premiumPA_UM:any}} args
 * @returns
 */
function assignReceipt({ policy, global_name_id, total, premiumPA_UM }) {
	//const convertCurrency = new CurrencyConversion();
	//let data = [];
	let middlewareReceiptCurrency = "jmd";
	const formatCurrency = currency =>
		currency.toLowerCase() === "jmd" ? "JA" : currency.toLowerCase === "usd" ? "US" : currency;

	// these are commented to remove the second receipt, don't want to need to readd logic if they chang what happened
	/* if (premiumPA_UM && premiumPA_UM.pa > 0) {
		return [
			{
				// TODO BRANCH Is Mandatory try "HEAD OFFICE"
				auto_authorize: true,
				branch: config.receipt_branch_name,
				receipt_date: format(new Date(), "MM/dd/yyyy"),
				policy_id: policy.policy_id,
				//amount: this.renewal_details.total - this.session.getPremiumPA_UM().pa,
				amount: total - premiumPA_UM.pa,
				payment_type: "credit cards",
				currency: formatCurrency(middlewareReceiptCurrency),
				is_tax_exempt: false,
			},
			{
				auto_authorize: true,
				branch: config.receipt_branch_name,
				receipt_date: format(new Date(), "MM/dd/yyyy"),
				policy_id: policy.policy_id,
				amount: premiumPA_UM.pa,
				payment_type: "credit cards",
				currency: formatCurrency(middlewareReceiptCurrency),
				is_tax_exempt: true,
			},
		];
	} else { */
	return {
		auto_authorize: true,
		// TODO BRANCH Is Mandatory try "HEAD OFFICE"
		branch: config.receipt_branch_name,
		receipt_date: format(new Date(), "MM/dd/yyyy"),
		policy_id: policy.policy_id,
		client_global_name_id: global_name_id,
		manual_receipt_number: "",
		amount: total,
		payment_type: "Advantage General On-line",
		currency: formatCurrency(middlewareReceiptCurrency),
		is_tax_exempt: false,
		source: "",
	};
	// }
}

/**
 *
 * @param {*} policy
 * @param {Boolean} half=
 * @param {Number} otherDiscounts
 * @returns
 */
function calculateBreakdown(policy, half = false, otherDiscounts = 0) {
	/** Calculate the policy */
	let breakdownData = {
		market_value: 0.0,
		total_discounts: 0.0,
		total_premium: 0.0,
		renewal_premium: 0.0,
		GCT: 0.0,
		total: 0.0,
		name: "full",
	};

	for (let a = 0; a < policy?.risks?.length; a++) {
		let risk = policy.risks[a];

		/* Market Value is the sum of insured for all risks */
		breakdownData.market_value += risk.sum_insured;

		/* Total Discounts is the some of all the discounts for each risk */
		// discount for this Risk
		let riskTotalDiscounts = risk.discounts.reduce((total, discount) => total + discount.premium, 0);
		breakdownData.total_discounts += riskTotalDiscounts;

		/* Total Premium is the sum of premium for all risks */
		let riskPremium = risk.renewal_premium - riskTotalDiscounts; // premium for this Risk
		breakdownData.total_premium += riskPremium;

		/* Renewal Premium is the sum of the total Premium + total_discounts ? Fact check with Tashani */
		breakdownData.renewal_premium += riskPremium + riskTotalDiscounts;

		/* GCT is calculated  */
	}

	breakdownData.renewal_premium = half ? breakdownData.renewal_premium / 2 : policy.renewal_premium;

	const pa_premium = getRiderPremium("pa_rider", policy.risks);
	const um_premium = getRiderPremium("um_rider", policy.risks);
	const riders_premium = pa_premium + um_premium;
	breakdownData.GCT = (policy.tax_percent / 100) * breakdownData.renewal_premium; //removeRiderPremium("pa_rider", policy.risks, breakdownData.renewal_premium);

	//This variable is used to decide if discounts should be applied on recalculation
	let at_minimum = breakdownData.renewal_premium - riders_premium === policy.renewal_minimum_premium;
	breakdownData.renewal_premium = at_minimum
		? breakdownData.renewal_premium
		: breakdownData.renewal_premium - otherDiscounts;
	breakdownData.total = breakdownData.renewal_premium + breakdownData.GCT;

	return breakdownData;
}

function getRenewalStartDate(policyRenewalDateString) {
	const policyRenewalDate = format(new Date(policyRenewalDateString), "MM/dd/yyyy H:mm:ss");
	let tempDate = new Date();
	let startDate = format(tempDate, "MM/dd/yyyy H:mm:ss");

	if (isBefore(tempDate, new Date(policyRenewalDateString)) || isEqual(tempDate, new Date(policyRenewalDateString))) {
		const tempDate = format(add(new Date(policyRenewalDate), { days: 1 }), "MM/dd/yyyy");
		startDate = format(add(new Date(tempDate), { minutes: 1 }), "MM/dd/yyyy H:mm:ss");
	}
	return startDate;
}

/**
 * Remove the riders [comprehensive?]
 *
 * @param {*} ridersList
 */
function removeRiders(ridersList) {
	let tempList = [];

	ridersList.forEach(function (rider) {
		if (!hasRider(rider.code)) {
			tempList.push(rider);
		}
	});
	return tempList;
}

function hasRider(rider_code) {
	return Object.keys(applicableRiders).find(val => applicableRiders[val].includes(rider_code) === true);
}

function isComprehensive(prefix) {
	return comprehensiveCodes.includes(prefix);
}

function isComprehensiveAgreedValue(prefix) {
	return comprehensiveAgreedValueCodes.includes(prefix);
}

/**
 * Checks to see if document's POCA information is valid
 * @param startDate Date of policy to be renewed
 * @param poca Policy's POCA object
 * @returns {boolean|boolean}
 */
const isPocaComplianceCheck = (startDate, poca) => {
	return poca.is_poca_compliant && Date.parse(poca.poca_documents_expire) >= Date.parse(startDate);
};

/**
 * Checks to ensure customer has no pending documents outstanding to be submitted
 * @param narratives Narratives for the policy
 * @param allowedCodes Codes which are permitted for Policy
 * @returns {boolean}
 */
const hasNoPendingDocs = (narratives, allowedCodes) => {
	if (!narratives.length) return false;
	let k = 0;
	for (let i = 0; i < narratives.length; i++) {
		for (let j = 0; j < allowedCodes.length; j++) {
			if (narratives[i].code === allowedCodes[j]) {
				k++;
			}
		}
	}
	return k !== narratives.length;
};

/**
 * Removes mortgagee from specified policy
 * @param policy Specified Policy
 * @returns {{global_names}|*}
 */
const removeMortgagee = policy => {
	if (policy.global_names)
		policy.global_names = policy.global_names.filter(name => !name.service_type.toLowerCase().includes("mortgagee"));
	return policy;
};

/**
 * This method is used to generate the pdf uri for the payment schedule
 *TODO: Should be removed later and placed into a separate file called nodeAxios
 * @param  {Array} payment_terms
 * @param  {Object} user
 * @param  {{policy_number, risks, tax_percent}} policy
 */

async function getPaymentScheduleInfo(payment_terms, user, { policy_number }) {
	const endpoint = "payments/payment-schedule";

	const headers = {
		"Content-Type": "application/json",
	};

	// const taxPercent = 1 + tax_percent / 100;
	// const totalDeductionWithTax = (removeAllRiderPremiums(risks) / payment_terms?.length) * taxPercent;
	const data = payment_terms.map(term => {
		return {
			index: term.payment_number,
			date: format(subDays(parse(term.effective_date, "MM/dd/yyyy", new Date()), 1), "d-MMM-yyyy").toUpperCase(),
			amount: formatMoney(term.payment_term_premium_with_tax),
			status: term.status,
		};
	});
	data[0].amount = "-";

	let addressInfo = user?.locations?.find(location => location.is_main_location);

	let payload = {
		terms: data,
		policy_user: user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.company_name,
		policy_number: policy_number,
		policy_holder_address: `${addressInfo.street_number} ${addressInfo.street_name} ${addressInfo.street_type}, 
								${addressInfo.general_area}, ${addressInfo.town}, ${addressInfo.parish}`,
	};

	let response = await checkExpToken({ endpoint, payload, headers: headers });

	return response;
}

/**
 * Gets renewal date for a policy based on the start date of the policy
 * @param startDate Start date of the policy
 * @returns {string}
 */
const getRenewalEndDate = startDate => {
	let tempDate = format(subDays(add(new Date(startDate), { months: 12 }), 1), "MM/dd/yyyy");
	return format(add(new Date(tempDate), { hours: 23, minutes: 59 }), "MM/dd/yyyy H:mm:ss");
};

/**
 * Retrieves risk codes from policy's risks array
 * @param risks Policy's risks
 * @returns {*}
 */
const getRenewalRisk = risks => {
	return risks.map(risk => {
		let temp = { ...risk };
		temp.ncd_percent = temp.renewal_ncd_percent;
		//temp.manual_rates = temp.manual_rates.filter(rate => rate.code);
		return temp;
	});
};

/**
 *
 * @param ext
 * @param extManRate
 * @param manualRates
 * @returns {*}
 */
//eslint-disable-next-line
const removeExtFromManulR = (ext, extManRate, manualRates) => {
	let deleteArray = [];

	for (let i = 0; i < ext.length; i++) {
		for (let j = 0; j < extManRate.profile_extensions.length; j++) {
			if (ext[i].code && ext[i].extension_code === extManRate.profile_extensions[j].extension_code) {
				deleteArray[i] = extManRate.profile_extensions[j].rating_code;
			}
		}
	}
	for (let i = 0; i < deleteArray.length; i++) {
		for (let j = 0; j < manualRates.length; j++) {
			if (deleteArray[i] === manualRates[j].code) {
				manualRates.splice(j, 1);
			}
		}
	}

	return manualRates;
};

const removeRatingCodesFromRisks = (risks, ratingCodes) => {
	let temp_risks;

	temp_risks = risks?.map(risk => {
		let temp_risk;
		let tempRates = risk?.manual_rates;

		risk?.manual_rates?.forEach(rate => {
			for (let i = 0; i < ratingCodes.length; i++) {
				tempRates = tempRates.filter(tempRate => tempRate.code != ratingCodes[i]);
			}
		});

		const newRates = tempRates;
		temp_risk = {
			...risk,
			manual_rates: newRates,
		};

		return temp_risk;
	});

	return temp_risks;
};

/**
 *
 * @param extensions
 * @param position
 * @param start_date
 * @param riderRates
 * @param risks
 * @param extension_details
 * @returns {Promise<*>}
 */
const addExtension = async (user, policy, extensions, position, start_date, riderRates, risks, extension_details) => {
	const rate = riderRates[position] || [];
	if (extension_details.success) {
		if (APPLICABLE_RIDERS.pa_rider.includes(rate.rating_code || "")) {
			let filtered_extension_details = await extension_details.profile_extensions.filter(
				extension => extension.rating_code === rate.rating_code
			);

			if (filtered_extension_details[0]) {
				let ext = {
					trans_wording: { trans_wording: "Personal Accident Extension" },
					parameter: {
						extensions: [
							{
								extension_code: filtered_extension_details[0].extension_code,
								limit_amount: rate.premium,
							},
						],
					},
					action: "add_extension",
					effective_date: start_date,
					is_tax_exempt: true,
				};
				await PolicyNetworkLayer.modifyPolicy(policy.policy_id, ext);
			}
		} else if (APPLICABLE_RIDERS.um_rider.includes(rate.rating_code || "")) {
			let filtered_extension_details = await extension_details.profile_extensions.filter(
				extension => extension.rating_code === rate.rating_code
			);

			if (filtered_extension_details[0]) {
				let ext = {
					trans_wording: {
						trans_wording: "Uninsured motorist Endorsement",
					},
					parameter: {
						extensions: [
							{
								extension_code: filtered_extension_details[0].extension_code,
								limit_amount: rate.premium,
							},
						],
					},
					action: "add_extension",
					effective_date: start_date,
					is_tax_exempt: false,
				};
				await PolicyNetworkLayer.modifyPolicy(policy.policy_id, ext);
			}
		}

		// todo: ??
		return riderRates[position + 1]
			? await addExtension(user, policy, extensions, position + 1, start_date, riderRates, risks, extension_details)
			: null;
	}
};

/**
 * Retrieves riders for a specified policy
 * @returns {any[]}
 */
const loadRiders = policy => {
	const risks = policy.risks;
	let riders = [];
	risks.forEach(risk => {
		risk.manual_rates.forEach(rate => {
			if (APPLICABLE_RIDERS.pa_rider.includes(rate.code)) {
				riders.push({
					risk_id: risk.risk_id,
					rider_name: "pa_rider",
					premium: rate.premium,
					rating_code: rate.code,
				});
			} else if (APPLICABLE_RIDERS.um_rider.includes(rate.code)) {
				riders.push({
					risk_id: risk.risk_id,
					rider_name: "um_rider",
					premium: rate.premium,
					rating_code: rate.code,
				});
			}
		});
	});
	return riders;
};

/**
 * Runs functions required to renew and update a specified policy
 
 * @param {Object} _policy Specified policy
 * @param {Object} user
 * @param {[]} _codes
 * @param {Object} paymentInfo
 * @param {React.MutableRefObject<{receipt: Boolean, billing: Boolean, renewal: Boolean}>} completedSteps
 * @returns {Promise}
 */
const renewAndUpdatePolicy = async (_policy, user, _codes = [], paymentInfo, completedSteps) => {
	let policy = { ..._policy };
	const IS_PART_PAYMENT = paymentInfo.paymentPlan.name.toLowerCase().includes("full") === false;
	const codes = [..._codes]; //populateTempRates()
	const extensionDetails = await PolicyNetworkLayer.getPolicyProfileExt(policy.policy_prefix);

	if (!extensionDetails.success) {
		createLog({
			trn: user?.national_id,
			type: `Renewal-Paymentform-getPolicyProfileExt`,
			data: {
				extensionDetails,
			},
			errors: { message: !extensionDetails?.success ? extensionDetails?.error_message : "" },
		});
	}

	const startDate = getRenewalStartDate(policy.end_date) || "";
	const endDate = getRenewalEndDate(startDate) || "";
	const risk = getRenewalRisk(policy.risks) || [];

	// If type of cover is a PPV then only offer cover for 7 days, otherwise give the 30
	const effectiveCover =
		policy.type_of_cover && policy.type_of_cover.toLowerCase().includes("passenger vehicle") ? 30 : 7;

	const isPocaCompliant = isPocaComplianceCheck(format(new Date(startDate), "MM/dd/yyyy"), user.poca);
	const noPendingDocuments = listMissingDocuments(policy.renewal_narratives).length === 0;
	// [LOADER]

	let paymentPlanId = "";
	if (IS_PART_PAYMENT) {
		/* const paymentPlanResult = await PolicyNetworkLayer.getPaymentPlans(
			
			user.national_id,
			"JA",
			policy.policy_prefix
		); */
		paymentPlanId = paymentInfo.paymentPlan.id; //paymentPlanResult.payment_plans[0].id;
	}


	if (completedSteps.current.renewal?.success !== true) {
		let shouldCreateCertificate = isPocaCompliant && noPendingDocuments;
		let shouldCreateCoverNote = !shouldCreateCertificate;

		if (policy.department_class.toLowerCase() === "fire") {
			shouldCreateCertificate = false;
			shouldCreateCoverNote = false;
		}

		const renewalResult = await PolicyNetworkLayer.renewAndUpdatePolicy(
			policy,
			paymentPlanId,
			codes,
			shouldCreateCertificate,
			shouldCreateCoverNote,
			effectiveCover,
			identifyPolicyType(policy, "home"),
			startDate,
			endDate,
			risk,
			user
		);

		if (!renewalResult.success) return { ...renewalResult };
		else completedSteps.current = { ...completedSteps.current, renewal: true };

		return {
			...renewalResult,
			isPocaCompliant: isPocaCompliant,
			noPendingDocuments: noPendingDocuments,
			paymentInfo: paymentInfo,
			startDate: startDate,
			extensionDetails: extensionDetails,
		};
	}
};

/** Extends a policy */
async function extendPolicy(policy, paymentPlan, total, national_id) {
	// extend policy
	const today = new Date();
	let effectiveTime = format(today, "HH:mm");

	//if (isAfter(expiryDate, today)) {  // expiry isAfter today
	/**
	 * @returns {{transaction_id: String; success: Boolean}}
	 */

	let extensionResponse = await PolicyNetworkLayer.extendPolicy(policy, effectiveTime, total);

	return extensionResponse;
}

/**
 * Generates Extension documents
 * @param {*} policy
 * @param {*} national_id
 * @param {*} transaction_id
 */
async function generateExtensionDocuments(policy, national_id, transaction_id) {
	let scheduleResponse = await PolicyNetworkLayer.getTransactionSchedule(transaction_id);

	let files = [];
	if (scheduleResponse?.data?.data) {
		files.push({
			//...scheduleResponse.data,
			filename: "Policy_Schedule.pdf" /* scheduleResponse.data.file_name */,
			fileContent: scheduleResponse.data.data,
			mime_type: "application/pdf",
			/*file_name: undefined,
			data: undefined,*/
		});
	}

	if (policy.department_class.toLowerCase() !== "fire")
		files = files.concat(await getCertsForRisks(policy.policy_id, policy.risks));

	return files;
}

/**
 * Handles the generation of documents for user renewal
 * @param {*} _policy
 * @param {*} user
 * @param {*} renewalResult - Contains poca and pending document check results
 */
const generateDocuments = async (_policy, user, renewalResult, paymentInfo) => {
	let files;
	let policy = { ..._policy };
	const { isPocaCompliant, noPendingDocuments, startDate, extensionDetails } = renewalResult;
	const resp_transaction_schedule = await PolicyNetworkLayer.getTransactionSchedule(renewalResult.transaction_id);

	if (resp_transaction_schedule.success) {
		const transactionscheduledata = resp_transaction_schedule.data;
		files = [
			{
				filename: "Policy_Schedule.pdf",
				fileContent: transactionscheduledata.data,
				mime_type: "application/pdf",
			},
		];
	}

	if (policy.department_class.toLowerCase() !== "fire") {
		// the certificates are generated under this condition
		if (isPocaCompliant && noPendingDocuments) {
			files = files.concat(await getCertsForRisks(policy.policy_id, policy.risks));
		} else {
			files = files.concat(await getCoverNotesForRisks(policy.policy_id, policy.risks));
		}
	}

	return files;
};

/**
 * fetch the covernote for array of risks
 *
 
 * @param {String} policy_id
 * @param {*} risks
 * @returns
 */
async function getCoverNotesForRisks(policy_id, risks) {
	let files = [];

	if (!risks || !Array.isArray(risks)) throw new Error("empty list");
	const resp_covernotes = await Promise.all(
		risks.map(risk => PolicyNetworkLayer.getCoverNote(policy_id, risk.risk_id))
	);

	const someFetched = resp_covernotes.filter(res => res.success);

	if (someFetched.length) {
		resp_covernotes.forEach((noteResp, index) => {
			if (noteResp.success) {
				files.push({
					filename: `Cover_Note_For_${risks[index]?.make?.toUpperCase()}_${risks[index]?.registration_number}.pdf`,
					fileContent: noteResp.data.data,
					mime_type: "application/pdf",
				});
			}
		});
	}

	return files;
}

/**
 * fetch the certificates for array of risks
 *
 
 * @param {String} policy_id
 * @param {*} risks
 * @returns
 */
async function getCertsForRisks(policy_id, risks) {
	let files = [];

	if (!risks || !Array.isArray(risks)) throw new Error("empty list");

	const resp_certificates = await Promise.all(
		risks.map(risk => PolicyNetworkLayer.getCertificate(policy_id, risk.risk_id))
	);

	const someFetched = resp_certificates.filter(res => res.success);

	if (someFetched.length) {
		resp_certificates.forEach((certResp, index) => {
			if (certResp.success) {
				files.push({
					filename: `Certificate_Of_Insurance_For_${risks[index]?.make?.toUpperCase()}_
					${risks[index]?.registration_number}.pdf`,
					fileContent: certResp.data.data,
					mime_type: "application/pdf",
				});
			}
		});
	}
	return files;
}

function addRidersToPremium(payment_plan, renewal_premium, ridersList) {
	let new_premium = { ...payment_plan };
	ridersList.forEach(function (rider) {
		if (hasRider(rider.code)) {
			new_premium.payment_terms[0].payment_term_premium =
				new_premium.payment_terms[0].payment_term_premium + rider.premium;
			new_premium = { ...new_premium, renewal_premium: renewal_premium + rider.premium };
		}
	});
	return new_premium;
}

async function getPremiumFromCalculator(branch, policy, user, currency /* , originalRisks */, full = false) {
	// Mutability reasons
	let risks = [...policy?.risks];
	risks[0] = { ...risks[0] };

	let planIds = [];
	const { success, payment_plans: plans } = await PolicyNetworkLayer.getPaymentPlans(currency, policy.policy_prefix);

	// extract an array of payment plan ids from plans array
	planIds = plans?.map(({ id }) => id);

	const premiumCalculatorResults = await PolicyNetworkLayer.premiumCalculator(policy, risks, planIds);

	let finalPremiums = premiumCalculatorResults.policy_payment_plans;

	if (policy.risks[0].manual_rates) {
		if (success && premiumCalculatorResults.success) {
			// calculate full payment
			let full_payment = premiumCalculatorResults.renewal_premium;

			return {
				success: true,
				full_payment,
				payment_plans: finalPremiums,
				risks: premiumCalculatorResults.risk_ratings,
			};
		} else {
			return { success: false };
		}
	}
}
/**
 * Creates the detailed breakdown of the cash payment instructions
 * @param {*} total
 * @param {*} user
 * @param {*} policy
 * @param {*} service_charge
 * @param {*} part_payment
 * @param {Boolean} isRenewal
 */
function cashPaymentInstructions(total, user, policy, service_charge, part_payment, isRenewal, lateFee) {
	const total_premium = total.toFixed(2);
	let premium_instructions = "";
	let pa_instructions = "\nSeparate Transaction:\n";
	let um_instructions = "";
	let trn_instructions = "\nTRN: " + user.national_id;
	let paRiderCount = 0;

	if (!isRenewal) {
		premium_instructions = "Total Premium\n";
		premium_instructions += formatMoney(total_premium) + "\n";
	} else {
		premium_instructions = lateFee ? `Late Fee: ${lateFee}\n` : "";
		premium_instructions += "Total Premium\n";
		premium_instructions += formatMoney(total_premium) + "\n";
	}

	return premium_instructions /* + (paRiderCount > 0 ? pa_instructions : "") + um_instructions */ + trn_instructions;
}

const isPromoCodeApplicable = policy_prefix => {
	return applicable_policy_prefix_promo.includes(policy_prefix);
};

const populateParishTowns = async () => {
	try {
		var parishTowns = [];
		let request = await PolicyNetworkLayer.getParishTowns();
		if (request.success) {
			const { data } = request;
			parishTowns = data;
		} else {
			parishTowns = [];
		}
	} catch (e) {
		parishTowns = [];
	}
	return parishTowns;
};

const populateStreetTypes = async () => {
	try {
		var streetTypes = [];
		let request = await PolicyNetworkLayer.getStreetTypes();
		if (request.success) {
			const { data } = request;
			streetTypes = data;
		} else {
			streetTypes = [];
		}
	} catch (e) {
		streetTypes = [];
	}
	return streetTypes;
};

const populateGeneralAreas = async () => {
	try {
		var generalAreas = [];
		let request = await PolicyNetworkLayer.getGeneralAreas();
		if (request.success) {
			const { data } = request;
			generalAreas = data;
		} else {
			generalAreas = [];
		}
	} catch (e) {
		generalAreas = [];
	}
	return generalAreas;
};

const checkGlassLimits = limitsArray => {
	if (limitsArray?.length > 0) {
		for (let i = 0; i < limitsArray.length; i++) {
			const limit = limitsArray[i];
			if (limit.limit_code === "Glass_AOE" || limit.limit_code === "Glass_AOP") {
				return true;
			}
		}
	}
	return false;
};

export const refreshPolicies = async (user, policy, transactionType) => {
	const { is_a_company } = user;

	const userId = user.global_name_id;
	const globalName = user.maiden_name;
	const national_id = is_a_company ? user.national_id : user.drivers_licence_number;

	const policyNum = policy.policy_number;
	const licenseNum = policy.risks[0].registration_number;
	const chassisNum = policy.risks[0].chassis_number;

	try {
		let response;
		let count = 2;
		let isClaims = false;
		let isPolicyManager = true;

		while (count > 0) {
			response = await AuthNetworkLayer.alternateVerification(
				isClaims,
				isPolicyManager,
				userId, // change with the otp thing
				licenseNum,
				policyNum,
				chassisNum,
				national_id,
				globalName,
				transactionType,
				is_a_company
			);

			if (!response.success && response.message?.includes("status code 401")) {
				//_token = (await getNewGlobalToken(3)).token;
				--count;
			} else {
				count = -1;
			}
		}

		if (!response.success)
			throw `Oops! Your changes were successful. However, we are having a challenge retrieving your newly edited data.
			 Please do not seek to make the same changes again.`;

		/** @type {Object} */
		const data = jwtDecoder(response.data);
		// check for jwt
		const jwt_auth = process.env.REACT_APP_JWT_AUTHENTICATION;
		if (jwt_auth) {
			store.dispatch(setToken(data.token));
		}
		store.dispatch(setCurrentUser(data.user));
		let activePolicies = PolicyHelper.findAllActiveAndRenewablePolicies(data.policies);
		let motorPolicies = activePolicies
			.filter(policy => policy.department_class !== "Liability")
			.filter(policy => policy.department_class !== "Fire");

		store.dispatch(setActivePolicies(motorPolicies));
		//history.push("/update-your-policy/policy-listing");
	} catch (e) {
		throw e;
	}
};

export {
	checkGlassLimits,
	toProperCase,
	populateStreetTypes,
	populateGeneralAreas,
	populateParishTowns,
	getAdditionalBenefits,
	getRenewalEndDate,
	getRisksofType,
	getRenewalRisk,
	filterForClaims,
	filterForRenewal,
	filterForExtension,
	filterForPoliciesWithNoPlateRisks,
	findAllActivePolicies,
	findAllActiveAndRenewablePolicies,
	isPocaComplianceCheck,
	listMissingDocuments,
	identifyPolicyType,
	isPARider,
	isUMRider,
	isUpsellingApproved,
	toBase64,
	formatMoney,
	formatPhoneNumber,
	calculateBreakdown,
	getRenewalStartDate,
	isComprehensive,
	isComprehensiveAgreedValue,
	getPremiumFromCalculator,
	removeRiderPremium,
	removeRiders,
	calculatePayment,
	renewAndUpdatePolicy,
	generateDocuments,
	removeMortgagee,
	calcPremiumPA_UM,
	hasNoPendingDocs,
	assignReceipt,
	getPaymentScheduleInfo,
	extendPolicy,
	generateExtensionDocuments,
	removeAllRiderPremiums,
	cashPaymentInstructions,
	isPromoCodeApplicable,
	formatPolicyForRenewal,
	removeRatingCodesFromRisks,
};
