import { globalNameGet } from "api/dNet/GlobalName";
import { submitNote } from "api/dNet/Renewal";
import {
	submitClaim,
	submitFile,
	linkFile,
	getRegistryTypes,
	getClaim,
	getClaimLossCauses,
	updateClaim,
	getParticipantType,
	addParticipant,
	getClaimLossResult,
	getSourceTypes,
	getClaimsHandler,
	getPolicy,
} from "api/dNet/Claims";
import { nodeApi } from "api/Middleware";
import Compressor from "compressorjs";
import { handleCompressPDF } from "api/external/pdf";
import { checkExpToken } from "helpers/auth";

export default class ClaimsNetworkLayer {
	static async getSubmitClaim(
		policy_id,

		risk_id,
		dateReported,
		lossDate,
		streetNumber,
		streetType,
		streetName,
		parish,
		town,
		general_area,
		lossCause,
		lossResult,
		lossDescription,
		locus_of_event,
		participants
	) {
		// const endpoint = "middleware/epic_mwClaimSubmit";

		// const headers = {
		// 	"x-auth-token": token,
		// 	"Content-Type": "application/json",
		// };

		const payload = {
			policy_id: policy_id,
			date_reported_to_insurer: dateReported,
			loss_date: lossDate,
			loss_cause: lossCause,
			loss_result: lossResult,
			description: lossDescription,
			notification_method: "WebSite",
			risk_ids: [risk_id],
			location: {
				general_area,
				altitude: "",
				block: "",
				building_label: "",
				building_number: "",
				complex_name: "",
				country: "Jamaica",
				international_area: "",
				// is_international_address: "",
				island: "",
				latitude: "",
				longitude: "",
				parish,
				postal_zip_code: "",
				street_name: streetName,
				street_number: streetNumber,
				street_type: streetType,
				town,
				unit_number: "",
			},
			locus_of_event,
			participants,
		};
		return submitClaim(payload);
		// return checkBadKey({ endpoint, payload, headers });
	}

	// static async submitThirdPartyLetter(payload) {
	// 	const endpoint = "/email/claim/third-party";

	// 	try {
	// 		const statementResponse = await nodeApi.post(endpoint, payload, {
	// 			timeout: 25000,
	// 			headers: {
	// 				Accept: "application/json;*/*",
	// 				"Content-Type": "application/json",
	// 			},
	// 		});
	// 		if (!statementResponse.data) throw new Error("No data found");
	// 		return { result: statementResponse.data, success: true };
	// 	} catch (e) {
	// 		return { success: false };
	// 	}
	// }
	static async getPolicyForClaim(policy_id, loss_date, risk_ids) {
		const payload = {
			policy_id,
			loss_date,
			risk_ids,
		};
		let response = await getPolicy(payload);
		return response;
	}

	static async getHandler() {
		const payload = {
			active_only: false,
			user_department: "",
		};
		let response = await getClaimsHandler(payload);
		return response;
	}

	static async submitServiceProviderLetter(payload) {
		const endpoint = "/email/claim/provider-email";

		try {
			const statementResponse = await checkExpToken({endpoint, payload, 
				timeout: 25000,
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			});
			if (!statementResponse) throw new Error("No data found");
			return statementResponse;
		} catch (e) {
			return { success: false };
		}
	}

	static async submitAccidentDetail(claimId, note) {
		const payload = {
			link_id: claimId,
			description: "Accident Detail from the customer's claim",
			notes: note,
			link_to: "claim",
			type: "Note For File",
			alert: false,
		};
		let response = await submitNote(payload);

		return response;
	}

	static async submitAdditionalNote(claimId, note, description) {
		const payload = {
			link_id: claimId,
			description: description,
			notes: note,
			link_to: "claim",
			type: "Note For File",
			alert: false,
		};

		let response = await submitNote(payload);
		return response;
	}
	static async submitAcknowledgementNote(claimId, claim_number, customer, date) {
		let note = `${!customer ? "Dear Customer, " : `Dear ${customer}`}, \n
	We acknowledge receipt of your claim form on ${date} and have assigned claim number ${claim_number} for future reference.

	Your Third-Party Policy provides coverage for damage to which you are liable to the third party, 
	your policy does not cover damage or loss to your vehicle.
	From the information you have provided, the Third Party is the liable party for the accident. In 
	view of the foregoing, you are provided with a Deal Direct Letter to communicate with the Third Party's
	insurer. We have written to the Third Party's insurer indicating your intention submit a claim 
	for your loss. 

	In the interim, please prepare the following for submission to the Third Party’s insurer: 

- Pictures from the accident scene, pictures of the damage to your vehicle and the third party’s vehicle 
- Estimate of Repairs  
- Assessor’s Report  
- Loss of Use Claim  
- Witness Statement 

Kindly be informed that should you decide against having your vehicle inspected and assessed by 
an independent assessor, it is likely that the Third Party's insurer will deduct a percentage from 
the cost of repairs for non- assessment. 
We thank you for your choosing AGIC and aim to offer exceptional claims service throughout this
process. Please contact us at (888) 687-2442 or at claims@advantagegeneral.com should you require any 
additional information or clarification throughout the process. 
	`;
		const payload = {
			link_id: claimId,
			description: "Acknowledgement Letter sent to insured",
			notes: note,
			link_to: "claim",
			type: "Note For File",
			alert: false,
		};

		let response = await submitNote(payload);
		return response;
	}

	static async submitDemandLetterNote(claimId, providerName, description) {
		let note = `Dear ${providerName}, \n
		
		    Our customer has requested that their claim be addressed by your company; please find a pdf document attached for your handling. 
			
			Should you have any concerns, please contact our claims department. 
			
			Kind regards,
			
			Advantage General Insurance Company Limited
			`;
		const payload = {
			link_id: claimId,
			description /* : "Demand letter sent to third party insurance" */,
			notes: note,
			link_to: "claim",
			type: "Note For File",
			alert: false,
		};

		let response = await submitNote(payload);
		return response;
	}
	static async submitAccidentDescription(claimId, note) {
		const payload = {
			link_id: claimId,
			description: "Accident Description from the customer's claim",
			notes: note,
			link_to: "claim",
			type: "Note For File",
			alert: false,
		};

		let response = await submitNote(payload);

		return response;
	}

	static async uploadDocuments(
		recordId,
		fileName,
		fileType,
		fileData,
		description,
		doc_type,
		callback,
		table = "claim"
	) {
		const payload = {
			table: table,
			record_id: recordId,
			file_id: "",
			file_name: fileName,
			file_type: fileType,
			file_data: fileData?.replace(new RegExp("^data:.*base64,", "i"), ""),
			description: description,
			doc_type: doc_type,
		};
		return submitFile(payload, callback);
		// return checkBadKey({ endpoint, payload, headers });
	}

	static async getClaim(claim_number, loss_date) {
		const payload = {
			claim_number,
			loss_date,
		};
		return getClaim(payload);

		// return checkBadKey({ endpoint, payload, headers });
	}
	static async getLossCauses(policy_prefix) {
		const payload = {
			policy_prefix,
		};

		return getClaimLossCauses(payload);
		// return checkBadKey({ endpoint, payload, headers });
	}

	static async getLossResults(policy_prefix, loss_cause) {
		const payload = {
			policy_prefix,
			loss_cause,
		};

		return getClaimLossResult(payload);
	}

	static async getGlobalName(global_name_id) {
		const response = await globalNameGet(global_name_id);
		return response;
	}

	/**
	 * Fetches a new claims statement from the node endpoint
	 * @param {Object} props
	 * @param {*} props.claimNumber
	 * @param {*} props.customerName
	 * @param {*} props.policyNumber
	 * @param {*} props.make
	 * @param {*} props.model
	 * @param {*} props.year
	 * @param {*} props.regNumber
	 * @param {*} props.phoneNumber
	 * @param {*} props.emailAddress
	 * @param {*} props.accidentType
	 * @param {*} props.accidentAffected
	 * @param {*} props.accidentDetails
	 * @param {*} props.passengerInfo
	 * @param {*} props.thirdPartyInfo
	 * @param {*} props.accidentLocation
	 * @param {*} props.accidentDate
	 * @param {*} props.accidentTime
	 * @param {*} props.accidentStatement
	 * @param {*} props.imagesUploaded
	 * @param {*} props.numberVehiclesInvolved
	 * @param {*} props.yourAccidentDetails
	 * @param {*} props.pedCount
	 * @param {*} props.pedestrians
	 * @param {*} props.witnesses
	 * @param {*} props.propertyOwners
	 * @param {*} props.damagedArea
	 * @param {*} props.selectedProvider
	 * @param {*} props.estimateFileAvailable
	 * @param {*} props.thirdPartyCounter,
	 * @param {*} props.pedInjured,
	 * @param {*} props.hasInfo,
	 * @param {*} props.hasOtherDetails,
	 */
	static async getClaimStatement({
		claimNumber,
		customerName,
		policyNumber,
		make,
		model,
		year,
		regNumber,
		phoneNumber,
		emailAddress,
		accidentType,
		numberVehiclesInvolved,
		accidentAffected,
		accidentDetails,
		passengerInfo,
		thirdPartyInfo,
		yourAccidentDetails,
		pedCount,
		pedestrians,
		witnesses,
		propertyOwners,
		damagedArea,
		accidentLocation,
		accidentDate,
		accidentTime,
		accidentStatement,
		imagesUploaded,
		selectedProvider,
		estimateFileAvailable,
		thirdPartyCounter,
		pedInjured,
		hasInfo,
		hasOtherDetails,
	}) {
		const endpoint = `/claims/statement`;
		const payload = {
			claimNumber,
			customerName,
			policyNumber,
			make,
			model,
			year,
			regNumber,
			phoneNumber,
			emailAddress,
			accidentType,
			accidentAffected,
			accidentDetails,
			passengerInfo,
			thirdPartyInfo,
			yourAccidentDetails,
			pedCount,
			pedestrians,
			witnesses,
			propertyOwners,
			damagedArea,
			accidentLocation,
			accidentDate,
			accidentTime,
			accidentStatement,
			imagesUploaded,
			numberVehiclesInvolved,
			selectedProvider,
			estimateFileAvailable,
			thirdPartyCounter,
			pedInjured,
			hasInfo,
			hasOtherDetails,
		};

		try {
			const statementResponse = await checkExpToken({
				endpoint,
				payload,

				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
				timeout: 25000,
			});
			if (!statementResponse) throw new Error("No data found");
			return { result: statementResponse, success: true };
		} catch (e) {
			return { success: false };
		}
	}

	/**
	 * Fetches a new acknowlegement letter from the node endpoint
	 * @param {Object} props
	 * @param {*} props.date
	 * @param {*} props.customerName
	 * @param {*} props.claim_number
	 */
	static async getAckLetter({ date, customerName, claim_number }) {
		const endpoint = `/claims/acknowledgement`;
		const payload = {
			date,
			customerName,
			claim_number,
		};

		try {
			const statementResponse = await checkExpToken({endpoint, payload, 
				timeout: 20000,
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			});
			if (!statementResponse) throw new Error("No data found");
			return { result: statementResponse, success: true };
		} catch (e) {
			return { success: false };
		}
	}

	/**
	 * Fetches a new authorization letter from the node endpoint
	 * @param {Object} props
	 * @param {*} props.dateOfLoss
	 * @param {*} props.providerName
	 * @param {*} props.providerAddressLine1
	 * @param {*} props.providerAddressLine2
	 * @param {*} props.providerAddressLine3
	 * @param {*} props.providerEmail
	 * @param {*} props.providerAddress
	 * @param {*} props.providerPhone
	 * @param {*} props.customerName
	 * @param {*} props.policyNumber
	 * @param {*} props.make
	 * @param {*} props.model
	 * @param {*} props.regNumber
	 * @param {*} props.vehicleType
	 * @param {*} props.chassisNumber
	 * @param {*} props.engineNumber
	 * @param {*} props.maximumPayable
	 * @param {*} props.authCreated
	 * @param {*} props.authDeadline
	 * @param {*} props.insuredEmail
	 * @param {*} props.insuredPhone
	 * @param {*} props.insuredPhone2
	 * @param {*} props.showGCT
	 */
	static async getAuthLetter({
		dateOfLoss,
		providerName,
		insuredEmail,
		insuredPhone,
		insuredPhone2,
		providerAddressLine1,
		providerAddressLine2,
		providerAddressLine3,
		providerEmail,
		providerAddress,
		providerPhone,
		customerName,
		policyNumber,
		make,
		model,
		regNumber,
		vehicleType,
		chassisNumber,
		engineNumber,
		maximumPayable,
		authCreated,
		authDeadline,
		showGCT,
	}) {
		const endpoint = `/claims/authorization`;
		const payload = {
			dateOfLoss,
			providerName,
			providerAddressLine1,
			providerAddressLine2,
			providerAddressLine3,
			providerEmail,
			providerAddress,
			providerPhone,
			customerName,
			insuredEmail,
			insuredPhone,
			insuredPhone2,
			policyNumber,
			make,
			model,
			regNumber,
			vehicleType,
			chassisNumber,
			engineNumber,
			maximumPayable,
			authCreated,
			authDeadline,
			showGCT
		};

		try {
			const statementResponse = await checkExpToken({endpoint, payload, 
				timeout: 20000,
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			});
			if (!statementResponse) throw new Error("No data found");
			return { result: statementResponse, success: true };
		} catch (e) {
			return { success: false };
		}
	}

	static async sendBrokerEmail({
		date_of_loss,
		broker_name,
		insured,
		type_of_claim,
		vehicle_make,
		vehicle_model,
		plate_number,
		claim_number,
		files,
	}) {
		let endpoint = "/email/claim/broker";
		let payload = {
			date_of_loss,
			broker_name,
			insured,
			type_of_claim,
			vehicle_make,
			vehicle_model,
			plate_number,
			claim_number,
			files,
		};

		try {
			const brokerResponse = await checkExpToken({endpoint, payload, 
				timeout: 20000,
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			});

			return brokerResponse;
		} catch (e) {
			return { success: false };
		}
	}

	static async getThirdPartyProviderLetters({
		dateOfLoss,
		providerName,
		providerAddressLine1,
		providerAddressLine2,
		providerAddressLine3,
		providerEmail,
		providerPhone,
		customerName,
		vehicleDriver,
		policyNumber,
		regNumber,
		vehicleType,
		chassisNumber,
		engineNumber,
		authCreated,
		TpCustomerName,
		TpDriverName,
		TpRegNumber,
		TpVehicleMake,
		TpVehicleModel,
		TpVehicleYear,
	}) {
		let endpoint = "/claims/third-party";
		let payload = {
			dateOfLoss,
			providerName,
			providerAddressLine1,
			providerAddressLine2,
			providerAddressLine3,
			providerEmail,
			providerPhone,
			customerName,
			vehicleDriver,
			policyNumber,
			regNumber,
			vehicleType,
			chassisNumber,
			engineNumber,
			authCreated,
			TpCustomerName,
			TpDriverName,
			TpRegNumber,
			TpVehicleMake,
			TpVehicleModel,
			TpVehicleYear,
		};

		try {
			const statementResponse = await checkExpToken({endpoint, payload, 
				timeout: 20000,
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			});

			return statementResponse;
		} catch (e) {
			return { success: false };
		}
	}

	static async getComprehensiveServiceProviderLetters({
		dateOfLoss,
		providerName,
		providerAddressLine1,
		providerAddressLine2,
		providerAddressLine3,
		providerEmail,
		providerPhone,
		customerName,
		vehicleDriver,
		policyNumber,
		regNumber,
		vehicleType,
		chassisNumber,
		engineNumber,
		authCreated,
		TpCustomerName,
		TpDriverName,
		TpRegNumber,
		TpVehicleMake,
		TpVehicleModel,
		TpVehicleYear,
	}) {
		let endpoint = "/claims/comprehensive";
		let payload = {
			dateOfLoss,
			providerName,
			providerAddressLine1,
			providerAddressLine2,
			providerAddressLine3,
			providerEmail,
			providerPhone,
			customerName,
			vehicleDriver,
			policyNumber,
			regNumber,
			vehicleType,
			chassisNumber,
			engineNumber,
			authCreated,
			TpCustomerName,
			TpDriverName,
			TpRegNumber,
			TpVehicleMake,
			TpVehicleModel,
			TpVehicleYear,
		};

		try {
			const statementResponse = await checkExpToken({endpoint, payload, 
				timeout: 20000,
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			});
			if (!statementResponse.success) throw new Error("No data found");
			return statementResponse;
		} catch (e) {
			return { success: false };
		}
	}

	static async addParticipant(claim_id, global_name) {
		const payload = {
			claim_id,
			global_name,
			link_partc_type: "Insured",
			participant_type: "Driver",
		};

		return addParticipant(payload);
	}

	static async addServiceProvider(claim_id, global_name) {
		const payload = {
			claim_id,
			global_name,
			participant_type: "Repairer",
		};
		return addParticipant(payload);
		// return checkBadKey({ endpoint, payload, headers });
	}

	static async addParticipantType() {
		return getParticipantType();
		// return checkBadKey({ endpoint, headers });
	}

	static async claimUpdate( claim_id, loss_date, loss_cause, loss_result, locus_of_event, lossDescription, status_code) {
		/**
		 * Liable Party seems to be mandatory in contradiction with the api documentation
		 * Liable Parties of note:
		 *
		 * Contributory
		 * Economic
		 * Insured
		 * Not-Applicable
		 * Third Party
		 * Undecided
		 * Withdrawn-Insured
		 */

		const payload = {
			claim_id,
			loss_date,
			loss_cause,
			loss_result,
			locus_of_event,
			is_authorized: true,
			liable_party: "Not-Applicable",
			description: lossDescription,
			status_code
		};

		return updateClaim(payload);

		// return checkBadKey({ endpoint, payload, headers });
	}

	static async sendAcknowledgementEmail(claim_number, customer, date, email) {
		const endpoint = `/email/claim/acknowledgement`;

		const payload = {
			claim_number,
			customer,
			date,
			email,
		};

		try {
			const claimResponse = await checkExpToken({endpoint, payload, 
				timeout: 15000,
			});
			if (!claimResponse) throw new Error("No data found");
			return { result: claimResponse, success: true };
		} catch (e) {
			return { success: false };
		}
	}

	/**
	 * Sends an email to the claims department when the customer chooses OTHER as their windscreen claim service provider
	 * @param {String} claim_number
	 * @param {String} customer
	 */
	static async lateClaim(claim_number, customer) {
		const endpoint = `/email/late-claim`;

		const payload = {
			claim_number,
			customer,
		};

		try {
			const claimResponse = await nodeApi.post(endpoint, payload, {
				timeout: 15000,
			});
			if (!claimResponse) throw new Error("No data found");
			return { result: claimResponse, success: true };
		} catch (e) {
			return { success: false };
		}
	}
	/**
	 * Sends an email to the claims department when the customer chooses OTHER as their windscreen claim service provider
	 * @param {String} name
	 * @param {String} email
	 * @param {String} provider
	 * @param {Array} files
	 */
	static async sendServiceProviderApprovalEmail(name, email, provider, files) {
		const endpoint = `/email/claim/auth`;

		try {
			const claimResponse = await checkExpToken({
				endpoint,
				payload:{
					email,
					files,
					name,
					provider,
				},
				
					timeout: 15000,
				}
			);

			if (!claimResponse) throw new Error("No data found");
			return { result: claimResponse, success: true };
		} catch (e) {
			return { success: false };
		}
	}
	/**
	 * Sends an email to the claims department when the customer chooses OTHER as their windscreen claim service provider
	 * @param {Object} props
	 * @param {String} props.name
	 * @param {String|Number} props.claim_number
	 * @param {String} props.date_of_loss
	 */
	static async sendAlternativeServiceProviderEmail({ name, claim_number, date_of_loss }) {
		const endpoint = `/email/claim/other`;

		const payload = {
			name,
			claim_number,
			date_of_loss,
		};

		try {
			const statementResponse = await checkExpToken({endpoint, payload, 
				timeout: 15000,
			});
			if (!statementResponse) throw new Error("No data found");
			return { result: statementResponse, success: true };
		} catch (e) {
			return { success: false };
		}
	}

	/**
	 * Sends an email to the claims department when the customer reports additional damage to a vehicle for a windscreen claim
	 * @param {Object} props
	 * @param {String} props.name
	 * @param {String|Number} props.claim_number
	 */
	static async sendAdditionalDamageEmail({ name, claim_number }) {
		const endpoint = `/email/claim/extra-damage`;

		const payload = {
			name,
			claim_number,
		};

		try {
			const statementResponse = await checkExpToken({endpoint, payload, 
				timeout: 15000,
			});
			if (!statementResponse) throw new Error("No data found");
			return { result: statementResponse, success: true };
		} catch (e) {
			return { success: false };
		}
	}

	/**
	 *
	 * @param {Object} props
	 * @param {String} props.token
	 */
	static async getAllSourceTypes({ token }) {
		const payload = {};

		return getSourceTypes(payload);
	}

	static compressImage(image) {
		return new Promise((resolve, reject) => {
			new Compressor(image, {
				quality: 0.4,
				success: compressedResult => {
					// compressedResult has the compressed file.
					// Use the compressed file to upload the images to your server.
					resolve(compressedResult);
				},
				error(err) {
					reject(err);
				},
			});
		});
	}

	static async compressFile(file, blob) {
		let temp = await handleCompressPDF(file, blob);
		return temp;
	}

	static async linkRegistryFile(registry_id, fileName, fileType, fileData, description, doc_type, callback) {
		const payload = {
			registry_id,
			file_id: "",
			file_name: fileName,
			file_type: fileType,
			file_data: fileData?.replace(new RegExp("^data:.*base64,", "i"), ""),
			description: description,
			doc_type: doc_type,
		};
		return linkFile(payload, callback);
		// return checkBadKey({ endpoint, payload, headers });
	}

	static async getAllRegistryTypes(relatingTo) {
		const payload = {
			params: {
				relatingTo,
			},
		};
		return getRegistryTypes(payload);
	}

	static formatClaimStatus =(claimStatusList, status) =>{
		
		let claimStatus = claimStatusList.find((item) => item.claim_status === status)?.step;
		let formatedClaimStatus = claimStatus?.slice(7);
		return formatedClaimStatus;
	}

	static sumProperty(arr, propName) {
		let sum = 0;
		for (let obj of arr) {
			if (obj.hasOwnProperty(propName)) {
				sum += obj[propName];
			}
		}
		return sum;
	}

	static getClaimStatus =(claimStatusList, status) =>{
		
		let claimStatus = claimStatusList.find((item) => item.claim_status === status);
		
		return claimStatus;
	}

	static truncateText = (text, characters)=>{
		let truncatedText = text.length > characters? text?.substring(0, characters) + "...": text;
		return truncatedText;
	}
	
}
