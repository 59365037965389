import React, { useState } from "react";
import { setLoading, setLoadingMessage } from "globals/overlays";
import { getConfirmationData } from "globals/confirmation";
import { setEmailSent, wasEmailSent } from "globals/claims";
import { useSelector, useDispatch } from "react-redux";
import Obfuscate from "react-obfuscate";
import { toast } from "react-toastify";
// import axios from "axios";

import "./PaymentConfirmation.scss";
import { nodeApi } from "api/Middleware";
import { useEffect } from "react";
import { getTransactionType, getUser, getMissingDocuments } from "globals/auth";
import { createLog } from "helpers/log";
import { checkExpToken } from "helpers/auth";

function PaymentConfirmation() {
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const transactionType = useSelector(getTransactionType);
	const [attempts, setAttempts] = useState(0);
	const shouldEmail = useSelector(wasEmailSent) !== true;
	const confirmationData = useSelector(getConfirmationData);
	const [error, setError] = useState("");
	const missingDocuments = useSelector(getMissingDocuments);

	const { receiptNum, paymentDetails, billingDetails, receiptDetails, responseAfterUpdate, isInFlow, files } =
		confirmationData;

	/**
	 * Wraps fileReader in a promise for more async manipulation
	 * @param {Blob} inputFile - If it's a string, it resolves with that value instead
	 */
	const readUploadedFileAsText = inputFile => {
		return new Promise((resolve, reject) => {
			if (typeof inputFile === typeof "") resolve(inputFile);
			const temporaryFileReader = new FileReader();

			temporaryFileReader.onerror = () => {
				temporaryFileReader.abort();
				reject(new DOMException("Problem parsing input file."));
			};

			temporaryFileReader.onloadend = () => {
				resolve(temporaryFileReader.result.toString().split(",")[1]);
			};

			temporaryFileReader.readAsDataURL(inputFile);
		});
	};

	const downloadfiles = async () => {
		const tempLink = document.createElement("a");
		tempLink.style.display = "none";
		document.body.appendChild(tempLink);

		for (let i = 0; i < files.length; i++) {
			const b64 = `${files[i].fileContent}`;
			tempLink.innerHTML = "Download PDF file";
			tempLink.download = files[i].filename;
			tempLink.href = "data:application/pdf;base64," + b64;
			tempLink.click();
		}

		document.body.removeChild(tempLink);
	};

	const emailClient = async () => {
		dispatch(setLoading(true));
		dispatch(setLoadingMessage("Sending your email"));

		let emailResult;
		try {
			let promises = [];
			const email = billingDetails?.email;

			for (let i = 0; i < files.length; i++) {
				promises.push(readUploadedFileAsText(files[i].fileContent));
			}

			let result = await Promise.all(promises);
			const isRenewal = (paymentDetails?.paymentPurpose === "Renewal" || paymentDetails?.paymentPurpose === "New Policy") && isInFlow === true;
			const isQuickPay = !isInFlow;

			emailResult = await checkExpToken({endpoint:`/email/file`, payload:{
				email,
				files,
				customer: `${billingDetails?.firstName} ${billingDetails?.lastName}`,
				isRenewal,
				isQuickPay,
				amount: `${paymentDetails?.paymentAmount.replace(/[, ]+/g, "").trim()}`,
				receipt_number: `${confirmationData?.receiptNum}`,
				name: `${billingDetails?.firstName} ${billingDetails?.lastName}`,
				phone: billingDetails?.phoneNumber,
				policy_number: paymentDetails?.policyNumber,
				type: paymentDetails?.paymentPurpose,
				currency: paymentDetails?.currencyType,
				missing_documents: isRenewal ? missingDocuments : [],
				isHomePolicy: paymentDetails?.isHomePolicy
			}});

			console.log("missing documents", missingDocuments);

			if (emailResult.success) {
				toast.success("Your documents have been sent!");
				setAttempts(attempts + 1);
				dispatch(setEmailSent(true));
			} else {
				emailResult = {
					data: { success: false, message: "Oops! Your documents failed to send! Please try again shortly." },
				};
				setError("Oops! Your documents failed to send! Please try again shortly.");
				toast.error("Oops! Your documents failed to send! Please try again shortly.");
			}
		} catch (e) {
			emailResult = { data: { success: false, message: "Error encountered: " + e.message } };
			setError("Error encountered: " + e.message);
			toast.error("Error encountered: " + e.message);
		} finally {
			dispatch(setLoading(false));
			createLog({
				trn: confirmationData.paymentDetails.nationalId,
				type: `${transactionType}-PaymentConfirmation`,
				data: { emailResult, attempts },
				errors: { message: error || !emailResult?.success ? emailResult : "" },
			});
		}
	};

	useEffect(() => {
		if (paymentDetails?.paymentPurpose === "Pay Balance") {
		}
		if (shouldEmail) emailClient();
	}, []);

	/** Has to be done after all useEffects have run */
	if (
		confirmationData.billingDetails === null ||
		confirmationData.paymentDetails === null ||
		Object.keys(confirmationData.billingDetails).length === 0 ||
		Object.keys(confirmationData.paymentDetails).length === 0
	)
		return <div></div>;

	return (
		<section className="payment-confirmation constrain-medium ">
			<div className="checkmark" />
			<h1 className="title">
				{(paymentDetails.paymentPurpose === "Renewal" || paymentDetails?.paymentPurpose === "New Policy") && isInFlow ? "Renewal " : "Payment "}
				Successful
			</h1>
			<p>
				<strong>Transaction receipt Number: {receiptNum}</strong>
			</p>
			<p>
				{(paymentDetails.paymentPurpose === "Renewal" || paymentDetails?.paymentPurpose === "New Policy") && isInFlow ? (
					<>
						We appreciate your business! Your documents have been emailed, but you may also download them by clicking
						the button below. If you need further assistance, please contact us at{" "}
						<Obfuscate email="info@advantagegeneral.com" /> or call our Telebranch at <Obfuscate tel="888-687-2442" />
					</>
				) : paymentDetails.paymentPurpose === "Pay Balance" && isInFlow ? (
					<>
						A copy of this payment information has been sent to email : {billingDetails.email}. If you need further
						assistance, please contact us at <Obfuscate email="info@advantagegeneral.com" /> or call our Telebranch at{" "}
						<Obfuscate tel="888-687-2442" />
					</>
				) : (
					`Please find the information regarding your payment below. Contact the customer service if you need any more information.
				Please retain a copy of this payment information by clicking the Download Receipt button.'`
				)}
			</p>
			<div className={`controls${isInFlow ? " wrap-early" : ""}`}>
				<button className="button alt" style={{ width: "100%" }} onClick={downloadfiles} type="button">
					{"Download "}
					{isInFlow !== null && files?.length > 1 ? "Policy Documents" : "Receipt"}
				</button>
			</div>
		</section>
	);
}

export default PaymentConfirmation;
