export const TRANSACTION_TYPES =
[
"Adding a driver", 

"Adding a vehicle", 

"Additional Premium", 

"Claims Experience Letter",  

"Renewal", 

"Policy Reinstatement", 

"Pay Balance", 

"New Policy",

"No Claim Discount Letter", 

"Service Charge",

];

