const { useState, useEffect } = require("react");

const SMALL_MOBILE_MAX_WIDTH = 400,
	MEDIUM_MOBILE_MAX_WIDTH = 530,
	MOBILE_MAX_WIDTH = 767.98,
	TABLET_MAX_WIDTH = 991.98,
	DESKTOP_MAX_WIDTH = 1200;

const SCREEN_SMALL_MOBILE = "small-mobile",
	SCREEN_MEDIUM_MOBILE = "medium-mobile",
	SCREEN_MOBILE = "mobile",
	SCREEN_TABLET = "tablet",
	SCREEN_DESKTOP = "desktop",
	SCREEN_LARGE_DESKTOP = "large-desktop";

/**
 * Hook to specify the type of screen being viewd from by using the viewport width
 */
function useScreenType() {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	const getScreenType = width =>
			width < SMALL_MOBILE_MAX_WIDTH
			? SCREEN_SMALL_MOBILE
			: width < MEDIUM_MOBILE_MAX_WIDTH
			? SCREEN_MEDIUM_MOBILE
			: width < MOBILE_MAX_WIDTH
			? SCREEN_MOBILE
			: width < TABLET_MAX_WIDTH
			? SCREEN_TABLET
			: width < DESKTOP_MAX_WIDTH
			? SCREEN_DESKTOP
			: SCREEN_LARGE_DESKTOP;

	useEffect(() => {
		// add a resize callback to window resize event and when it updates
		const checkIfNewState = e => {
			let currentState = getScreenType(screenWidth);

			// find the new screen size based on the resize
			let newState = getScreenType(window.innerWidth);

			if (currentState != newState) {
				setScreenWidth(window.innerWidth);
			}
		};

		// add listener
		window.addEventListener("resize", checkIfNewState);
		return () => {
			// remove listeners
			window.removeEventListener("resize", checkIfNewState);
		};
	}, [screenWidth]);

	//Storing current screen type in variable
	const currentScreenType = getScreenType(screenWidth);
	return {
		width: screenWidth,
		screenType: currentScreenType,
		isSmallMobile: currentScreenType === SCREEN_SMALL_MOBILE,
		isMediumMobile: currentScreenType === SCREEN_MEDIUM_MOBILE,
		isMobile: currentScreenType === SCREEN_MOBILE,
		isTablet: currentScreenType === SCREEN_TABLET,
		isDesktop: currentScreenType === SCREEN_DESKTOP,
		isLargeDesktop: currentScreenType === SCREEN_LARGE_DESKTOP,
	};
}

export { useScreenType, SCREEN_TABLET, SCREEN_MOBILE, SCREEN_DESKTOP, SCREEN_LARGE_DESKTOP, SCREEN_MEDIUM_MOBILE, SCREEN_SMALL_MOBILE };
