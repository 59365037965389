import { createSlice } from "@reduxjs/toolkit";
import { fullLogout } from "globals/misc";

const defaultState = {
	mainAddress: "",
	fullname: "",
	email: "",
	selectedPolicyEditorTab: { mainFlowIndex: null, subFlowIndex: null },
	progressStep: 0,
	registrationNumbers: [],
	certificates: [],
	highlightedRiskId: null, 
	clickedNotificationInfoPocket: {
		UniqueInfoID: "",
		focusFieldID: "",
	}, 
	isPocaCompliant: true, 
	subtitle: "",
	progressBarStatus: false,
	policyFormInfo: {
		streetNumber: "",
		streetName: "",
		streetType: "",
		generalArea: "",
		internationalArea: "",
		parish: "",
		town: "",
		newEmail: "",
		phoneNumber: "",
		otherPhoneNumber: "",
		buildingNumber: "",
		buildingLabel: "",
		complexName: "",
		unitNumber: "",
		country: "",
		zipCode: "",
		proofOfAddressType: "",
		proofOfAddress: [],
		newRegistrationNumbers: [],
		newMarketValues: [],
	},
	newPremium: 0,
	gct: 0,
	changeOnPremium: 0,
	streetTypes: [],
	parishsTowns: [],
	generalAreas: [],
	editedAddressFields: [],
	isAlternativeNumberEdited: false,
	isPolicyPastDue: false,
	policyFormInfoChanged: false,
};

let policyManagerSlice = createSlice({
	name: "policymanager",
	initialState: defaultState,
	reducers: {
		setPolicyManager: (state, action) => {
			const { fullname, email, certificates, registrationNumbers } = action.payload;
			state.fullname = fullname;
			state.email = email;
			state.registrationNumbers = registrationNumbers;
			state.certificates = certificates;
		}, 
		setSubtitle: (state, action) => {
			state.subtitle = action.payload;
		}, 
		setIsPocaCompliant: (state, action) => {
			state.isPocaCompliant = action.payload;
		}, 
		setProgressBarStatus: (state, action) => {
			state.progressBarStatus = action.payload;
		},
		setClickedNotificationInfoPocket: (state, action) => {
			state.clickedNotificationInfoPocket = action.payload;
		},
		setSelectedPolicyEditorTab: (state, action) => {
			state.selectedPolicyEditorTab = action.payload;
		},
		setProgressStep: (state, action) => {
			state.progressStep = action.payload;
		},
		setFullName: (state, action) => {
			state.fullname = action.payload;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setNewPremium: (state, action) => {
			state.newPremium = action.payload;
		},
		setChangeOnPremium: (state, action) => {
			state.changeOnPremium = action.payload;
		},
		setGCT: (state, action) => {
			state.gct = action.payload;
		},
		/**
		 * @typedef {Object} policyFormInfo
		 * @property {Object} policyFormInfo.payload
		 * @property {String} policyFormInfo.payload.streetNumber
		 * @property {String} policyFormInfo.payload.streetName
		 * @property {String} policyFormInfo.payload.streetType
		 * @property {String} policyFormInfo.payload.generalArea
		 * @property {String} policyFormInfo.payload.internationalArea
		 * @property {String} policyFormInfo.payload.parish
		 * @property {String} policyFormInfo.payload.town
		 * @property {String} policyFormInfo.payload.newEmail
		 * @property {String} policyFormInfo.payload.phoneNumber
		 * @property {String} policyFormInfo.payload.otherPhoneNumber
		 * @property {String} policyFormInfo.payload.buildingNumber
		 * @property {String} policyFormInfo.payload.buildingLabel
		 * @property {String} policyFormInfo.payload.complexName
		 * @property {String} policyFormInfo.payload.unitNumber
		 * @property {String} policyFormInfo.payload.country
		 * @property {String} policyFormInfo.payload.zipCode
		 * @property {String} policyFormInfo.payload.proofOfAddressType
		 * @property {Array} policyFormInfo.payload.proofOfAddress
		 * @property {Array} policyFormInfo.payload.newRegistrationNumbers
		 * @property {Array} policyFormInfo.payload.newMarketValues
		 * @param {policyFormInfo} action
		 */
		setPolicyFormInfo: (state, action) => {
			state.policyFormInfo = { ...state.policyFormInfo, ...action.payload };
		},
		setStreetTypes: (state, action) => {
			state.streetTypes = action.payload;
		},
		setParishsTowns: (state, action) => {
			state.parishsTowns = action.payload;
		},
		setMainAddress: (state, action) => {
			state.mainAddress = action.payload;
		},
		setGeneralAreas: (state, action) => {
			state.generalAreas = action.payload;
		},
		setEditedAddressFields: (state, action) => {
			state.editedAddressFields = action.payload;
		},
		setIsAlternativeNumberEdited: (state, action) => {
			state.isAlternativeNumberEdited = action.payload;
		},
		setIsPolicyPastDue: (state, action) => {
			state.isPolicyPastDue = action.payload;
		},
		setHighlightedRiskId: (state, action) => {
			state.highlightedRiskId = action.payload;
		},
		setPolicyFormInfoChanged: (state, action) => {
			state.policyFormInfoChanged = action.payload;
		},
		clearPolicyManagerFormInfo: state => {
			state.editedAddressFields = [];
			state.policyFormInfo = {
				streetNumber: "",
				streetName: "",
				streetType: "",
				generalArea: "",
				internationalArea: "",
				parish: "",
				town: "",
				newEmail: "",
				phoneNumber: "",
				otherPhoneNumber: "",
				buildingNumber: "",
				buildingLabel: "",
				complexName: "",
				unitNumber: "",
				country: "",
				zipCode: "",
				proofOfAddressType: "",
				proofOfAddress: [],
				newRegistrationNumbers: [],
				newMarketValues: [],
			};
			state.newPremium = 0;
			state.gct = 0;
			state.changeOnPremium = 0;
			state.isAlternativeNumberEdited = false;
			state.policyFormInfoChanged = false;
		},
		clearPolicyManager: () => defaultState,
	},
	extraReducers: builder => {
		builder.addCase(fullLogout, (state, action) => defaultState);
		//builder.addCase(changePolicy, (state, action) => defaultState);
	},
});

/** @param {{policymanager: defaultState}} state */
export const getPolicyManager = ({ policymanager: state }) => {
	return {
		fullname: state?.fullname,
		email: state?.email,
		certificates: state?.certificates,
		registrationNumbers: state?.registrationNumbers,
	};
};
export const getSelectedPolicyEditorTab = ({ policymanager: state }) => state.selectedPolicyEditorTab;
/** @param {{policymanager : defaultState}} state */
export const getPolicyFormInfo = ({ policymanager: state }) => state.policyFormInfo;
export const getStreetTypes = ({ policymanager: state }) => state.streetTypes;
export const getParishsTowns = ({ policymanager: state }) => state.parishsTowns;
export const getGeneralAreas = ({ policymanager: state }) => state.generalAreas;
export const getMainAddress = ({ policymanager: state }) => state.mainAddress;
export const getEditedAddressFields = ({ policymanager: state }) => state.editedAddressFields;
export const getIsAlternativeNumberEdited = ({ policymanager: state }) => state.isAlternativeNumberEdited;
export const getProgressStep = ({ policymanager: state }) => state.progressStep;
export const getNewPremium = ({ policymanager: state }) => state.newPremium;
export const getChangeOnPremium = ({ policymanager: state }) => state.changeOnPremium;
export const getGCT = ({ policymanager: state }) => state.gct;
export const getHighlightedRiskId = ({ policymanager: state }) => state.highlightedRiskId; 
export const getSubtitle = ({ policymanager: state }) => state.subtitle;
export const getProgressBarStatus = ({ policymanager: state }) => state.progressBarStatus;
export const getClickedNotificationInfoPocket = ({ policymanager: state }) => state.clickedNotificationInfoPocket;
export const getIsPolicyPastDue = ({ policymanager: state }) => state.isPolicyPastDue;
export const getPolicyFormInfoChanged = ({ policymanager: state }) => state.policyFormInfoChanged;
export const getIsPocaCompliant = ({ policymanager: state }) => state.isPocaCompliant;
export const {
	setPolicyManager,
	setEmail,
	setPolicyFormInfo, 
	setSubtitle,
	setProgressBarStatus,
	setMainAddress,
	clearPolicyManager,
	clearPolicyManagerFormInfo,
	setStreetTypes,
	setParishsTowns,
	setGeneralAreas,
	setEditedAddressFields,
	setIsAlternativeNumberEdited,
	setSelectedPolicyEditorTab,
	setProgressStep,
	setNewPremium,
	setChangeOnPremium,
	setGCT, 
	setClickedNotificationInfoPocket,
	setHighlightedRiskId,
	setIsPolicyPastDue,
	setPolicyFormInfoChanged,
	setIsPocaCompliant,
} = policyManagerSlice.actions;
export default policyManagerSlice.reducer;
