import React, { useRef, useEffect } from "react";
import CurrencyInput from "react-currency-masked-input";
import { createNumberMask } from "text-mask-addons";
import { Formik, Form, Field } from "formik";
import MaskedInput from "react-text-mask";
import { useHistory } from "react-router";
import { InfoIcon } from "components";
import { object, string } from "yup";
import { shallowEqual, useSelector } from "react-redux";
import { getToken, getTransactionType } from "globals/auth";
import { Row, Col } from "reactstrap";
import { TRNMask } from "components/ClaimsProcess/AccidentDetailsThirdParty/constants";
import { formatMoney } from "components/RenewalProcess/AdditionalBenefits/functions";
import { TRANSACTION_TYPES } from "pages/Payments/constants";
import { QUICK_PAY } from "pages/RenewPolicy/costants";

const numberMaskObject = {
	prefix: "",
	includeThousandsSeparator: false,
	integerLimit: 7,
};

const numberMask = createNumberMask(numberMaskObject);

/**
 * Schema using the Yup library for more readable field object validation
 */
const paymentFormSchema = object().shape({
	nationalId: string()
		.required("Please enter a TRN")
		.matches(/^[\d]{3}-[\d]{3}-[\d]{3}$/, "Please enter a valid TRN"),
	policyNumber: string()
		.required("Please enter a valid policy number. Digits only.")
		.matches(/^\d+$/g, "Only numbers are accepted")
		.min(1, `Policy number must be between one (1) and seven (${numberMaskObject.integerLimit}) digits`)
		.max(
			numberMaskObject.integerLimit,
			`Policy number must be between one (1) and seven (${numberMaskObject.integerLimit}) digits`
		),

	paymentAmount: string()
		.required("Please enter a valid payment amount. Decimal digits are allowed.")
		.matches(/^(\d+,?)+(\.\d{2})?$/g, "Payment amount format is invalid")
		.test("not default", "Please enter a valid payment amount", value => value !== "0.00"),
	currencyType: string().required("Please select a currency type"),
	paymentPurpose: string().required("Please choose a valid transaction type").min(1),
	paymentPurposeDetailed: string().when("paymentPurpose", {
		is: paymentPurpose => paymentPurpose === "Other",
		then: string().required("Please provide a detailed transaction type"),
	}),
});

/**
 * Handles the payment confirmation process
 * @param {Object} props
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.confirmPayment
 * @param {Boolean} props.paymentConfirmed
 * @param {React.Dispatch<defaultFormValues>} props.setPaymentDetails
 * @param {Object} props.transaction
 * @param {Function} props.validateTrnPolicy2
 * @param {Function} props.showError
 * @param {React.MutableRefObject<Boolean>} props.persist
 */
function 
PaymentDetails({
	transaction,
	confirmPayment,
	paymentConfirmed,
	setPaymentDetails,
	validateTrnPolicy2,
	showValidationError,
	persist,
}) {
	const history = useHistory();
	const confirmRef = useRef(null);
	const token = useSelector(getToken, shallowEqual);
	const transactionType = useSelector(getTransactionType, shallowEqual);
	/** Initializers for form state */
	const defaultFormValues = {
		...transaction,
		currencyType: "JMD",
		paymentPurposeDetailed: "",
		fromRenewalFlow: transaction.policyNumber === null ? false : true,
		nationalId: transaction.nationalId === null ? "" : transaction.nationalId,
		policyNumber: transaction.policyNumber === null ? "" : transaction.policyNumber,
		paymentAmount: transaction.paymentAmount === null ? "0.00" : transaction.paymentAmount,
		paymentPurpose: transaction.paymentPurpose === null ? "" : transaction.paymentPurpose,
	};

	useEffect(() => {
		if (transaction.policyNumber) setPaymentDetails(defaultFormValues);
		//eslint-disable-next-line
	}, [transaction.policyNumber]);

	/**
	 * Submission handler for form
	 * @param {defaultFormValues} values
	 * @param {Object} props
	 * @param {Function} props.setSubmitting
	 */
	async function handleFormSubmit(values, { setSubmitting }) {
		let validationResult = await validateTrnPolicy2( transactionType, values?.nationalId, values?.policyNumber);
		if (transactionType==QUICK_PAY && (validationResult === 1 || validationResult === 2 || validationResult === 3)) {
			if (validationResult === 1) {
				// failed validation for trn and policy
				showValidationError(
					"This policy is either not active or the TRN and policy number are invalid. Please confirm the details entered or contact our customer support."
				);
			} else {
				// service failed
				showValidationError("Service unavailable");
			}
		} else {
		 	if (paymentConfirmed && !values.fromRenewalFlow) {
				confirmPayment(false);
			} else if (!paymentConfirmed) {
				confirmPayment(true);
				setPaymentDetails({ ...values, paymentAmount: values.paymentAmount.replaceAll(",", "") });
			} else {
				persist.current = true;
				history.goBack();
			}
		}

		setSubmitting(false);
	}

	/** A helper function to check whether to disable specific fields */
	function propsDisabled(name) {
		return transaction[name] !== null && defaultFormValues[name] !== "";
	}

	/** Checks whether the submit button should be disabled */
	function shouldDisableSubmit(values, errors) {
		let hasEmptyValues = () => {
			if (
				values.nationalId === "" ||
				values.policyNumber === "" ||
				values.paymentAmount === "0.00" ||
				values.paymentPurpose === ""
			)
				return true;
			return false;
		};

		if (paymentConfirmed) return undefined;

		if (!hasEmptyValues()) return false;
		else if (Object.entries(errors).length === 0 && !hasEmptyValues()) return false;

		return true;
	}

	return (
		<Formik
			initialValues={defaultFormValues}
			validationSchema={paymentFormSchema}
			onSubmit={handleFormSubmit}
			initialTouched={{ currencyType: true }}
		>
			{({ setFieldValue, values, isSubmitting, submitCount, errors, handleBlur, touched }) => (
				<Form className="form">
					<Row>
						<Col md={6} lg={3} className="mt-3">
							{" "}
							<label
								className={
									"form-label" +
									`${values.nationalId !== "" ? " hasValue" : ""}` +
									`${touched.nationalId && errors.nationalId ? " error hasError" : ""}`
								}
							>
								<div style={{ whiteSpace: "nowrap" }}>
									<span className="field-title">Policyholder's TRN</span>
									<span className="required"> * </span>
									<InfoIcon label={`TRN (Not more than 9 digits)`} />
								</div>
								<Field name="nationalId" className="field">
									{({ field }) => (
										<MaskedInput
											{...field}
											disabled={propsDisabled("nationalId") || paymentConfirmed}
											mask={TRNMask}
											//maxLength={numberMaskObject.integerLimit}
											type="text"
										/>
									)}
								</Field>
								{touched.nationalId && errors.nationalId && (
									<div style={{ fontWeight: "lighter" }}>{errors.nationalId}</div>
								)}
							</label>
						</Col>
						<Col md={6} lg={3} className="mt-3">
							{" "}
							<label
								className={
									"form-label" +
									`${values.policyNumber !== "" ? " hasValue" : ""}` +
									`${touched.policyNumber && errors.policyNumber ? " error hasError" : ""}`
								}
							>
								<div>
									<span className="field-title">Policy Number</span>
									<span className="required"> * </span>
									<InfoIcon label={`Policy number (Not more than ${numberMaskObject.integerLimit} digits)`} />
								</div>
								<Field name="policyNumber" className="field">
									{({ field }) => (
										<MaskedInput
											{...field}
											disabled={propsDisabled("policyNumber") || paymentConfirmed}
											mask={numberMask}
											maxLength={numberMaskObject.integerLimit}
											type="text"
										/>
									)}
								</Field>
								{touched.policyNumber && errors.policyNumber && (
									<div style={{ fontWeight: "lighter" }}>{errors.policyNumber}</div>
								)}
							</label>
						</Col>
						<Col md={6} lg={2} className="mt-3">
							<label
								className={
									"currency form-label" +
									`${values.paymentAmount !== "0.00" ? " hasValue" : ""}` +
									`${touched.paymentAmount && errors.paymentAmount ? " error hasError" : ""}`
								}
							>
								<div style={{ whiteSpace: "nowrap" }}>
									<span className="field-title">Payment Amount</span>
									<span className="required"> * </span>
								</div>
								<Field name="paymentAmount" className={`field ${values.currencyType}`}>
									{({ field }) => (
										<CurrencyInput
											{...field}
											id="paymentAmount"
											type="text"
											value={formatMoney(values.paymentAmount, 2, ".", ",", false, false)}
											style={{ paddingLeft: "2.5rem" }}
											onChange={(_, e) => {
												setFieldValue("paymentAmount", e);
											}}
											onBlur={handleBlur}
											disabled={paymentConfirmed || propsDisabled("paymentPurpose")}
											pattern={undefined}
										/>
									)}
								</Field>
								{touched.paymentAmount && errors.paymentAmount && (
									<div style={{ fontWeight: "lighter", alignSelf: "flex-start" }}>{errors.paymentAmount}</div>
								)}
							</label>
						</Col>

						<Col md={6} lg={2} className="mt-3">
							<label
								className={
									`currency-fieldset form-label` +
									`${touched.currencyType && errors.currencyType ? " error hasError" : ""}` +
									`${touched.currencyType ? " hasValue" : ""}`
								}
							>
								<div style={{ display: "relative" }}>
									<span className="field-title">Currency</span>
									<span className="required"> * </span>
								</div>
								<div className="toggle">
									<Field
										type="radio"
										id="currencyJMD"
										name="currencyType"
										value="JMD"
										disabled={propsDisabled("currency") || paymentConfirmed}
										checked={values.currencyType === "JMD"}
										onChange={() => setFieldValue("currencyType", "JMD")}
									/>
									<label htmlFor="currencyJMD">JMD</label>
									<Field
										type="radio"
										id="currencyUSD"
										name="currencyType"
										value="USD"
										disabled={true}
										//disabled={paymentConfirmed || propsDisabled("currency")} use this when USD is officially allowed
										checked={values.currencyType === "USD"}
										onChange={() => setFieldValue("currencyType", "USD")}
									/>
									<label htmlFor="currencyUSD">USD</label>
								</div>
								{touched.currencyType && errors.currencyType && (
									<div style={{ gridColumnStart: 3 }}>{errors.currencyType}</div>
								)}
							</label>
						</Col>
						<Col lg={2} className="mt-3">
							<label
								className={
									"form-label" +
									`${values.paymentPurpose !== "" ? " hasValue" : ""}` +
									`${touched.paymentPurpose && errors.paymentPurpose ? " error hasError" : ""}`
								}
							>
								<div style={{ whiteSpace: "nowrap" }}>
									<span className="field-title">Transaction Type</span> <span className="required">*</span>
								</div>
								<Field as="select" name="paymentPurpose" disabled={paymentConfirmed || propsDisabled("paymentPurpose")}>
									<option defaultChecked disabled value=""></option>
									<>
										{TRANSACTION_TYPES.sort().map((val, index) => {
											return (
												<option key={`${val}-${index}`} value={val}>
													{val}
												</option>
											);
										})}
										<option value="Other">Other</option>
									</>
								</Field>
								{touched.paymentPurpose && errors.paymentPurpose && (
									<div style={{ fontWeight: "lighter" }}>{errors.paymentPurpose}</div>
								)}
							</label>
						</Col>
					</Row>
					{values.paymentPurpose === "Other" && (
						<label
							className={
								"form-label mt-3" +
								`${touched.paymentPurposeDetailed && errors.paymentPurposeDetailed ? " error hasError" : ""}` +
								`${values.paymentPurposeDetailed !== "" ? " hasValue" : ""}`
							}
						>
							<div>
								<span className="field-title">Transaction Details </span>
							</div>
							<Field name="paymentPurposeDetailed" className="field" type="text" disabled={paymentConfirmed} />
							{touched.paymentPurposeDetailed && errors.paymentPurposeDetailed && (
								<div style={{ fontWeight: "lighter" }}>{errors.paymentPurposeDetailed}</div>
							)}
						</label>
					)}

					{(transactionType===QUICK_PAY ) && (
						<div className="payment-button-container">
							<button
								className={
									`button primary-theme-btn` +
									`${
										submitCount === 0 &&
										(values.policyNumber === "" || values.paymentAmount === "" || values.paymentPurpose === "")
											? " initialHidden"
											: ""
									}` +
									`${paymentConfirmed ? " alt" : ""}` +
									`${values.fromRenewalFlow ? "" : " end"}`
								}
								type="submit"
								disabled={shouldDisableSubmit(values, errors) || isSubmitting || undefined}
								ref={confirmRef}
								tabIndex={0}
								style={{ marginTop: 15 }}
							>
								{values.fromRenewalFlow ? "Back" : paymentConfirmed ? "Change" : "Confirm"}
							</button>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);
}

export default PaymentDetails;
