import React from "react";
import { useTransition, animated } from "react-spring";
import { FaBars, FaTimes } from "react-icons/fa";

import "./ToggleButton.scss";

const AnimatedBars = animated(FaBars);
const AnimatedTimes = animated(FaTimes);

function ToggleButton({ toggleMenu, menuIsOpen }) {
	const transitions = useTransition(menuIsOpen, null, {
		from: { position: "absolute", opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return (
		<div className="toggle-button" onClick={toggleMenu}>
			<div className="wrapper">
				{transitions.map(({ item, key, props }) =>
					item ? (
						<AnimatedTimes className="active alt" size="1x" key={key} style={props} />
					) : (
						<AnimatedBars className="active" size="1x" key={key} style={props} />
					)
				)}
			</div>
		</div>
	);
}

export default ToggleButton;
