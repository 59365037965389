import React, { Fragment } from "react";
import { useEffect } from "react";
import ReactModal from "react-modal";
import { ConfirmModal, ErrorModal, InfoModal, InputModal } from "../SweetModal";
import { CONFIRM, ERROR, INPUT, } from "./constants";

import "./Modal.scss";

ReactModal.setAppElement("#root");

/**
 * Make a modal appear in the application.
 *
 * @param {Object} props
 * @param {String|JSX.Element=} props.content
 * @param {String|JSX.Element=} props.subcontent
 * @param {Boolean} props.open
 * @param {String=} props.title
 * @param {Object[]=} props.list
 * @param {React.CSSProperties=} props.customStyle,
 * @param {String|JSX.Element=} props.subText
 * @param {String|JSX.Element=} props.subContent
 * @param {String=} props.primaryLabel
 * @param {()=>void=} props.primaryCallback
 * @param {Boolean=} props.enablePrimaryCallback
 * @param {String=} props.modalType
 * @param {String=} props.secondaryLabel
 * @param {()=>void=} props.secondaryCallback
 * @param {()=>void=} props.afterClose
 * @param {String=}props.inputType
 * @param {String|JSX.Element=} props.secondaryContent
 * @param {Boolean=} props.shouldDelete
 * @param {Boolean=} props.showCloseButton
 * @param {Boolean=} props.isDismissed
 * @param {Boolean=} props.successIcon
 * @param {()=>void=} props.dismissProcedure
 * @param {Boolean} props.alignButtonsVertically
 */

export default function Modal({
	content,
	subcontent,
	open,
	title,
	list,
	subText,
	subContent,
	customStyle,
	modalType,
	primaryLabel,
	primaryCallback,
	enablePrimaryCallback,
	inputType,
	secondaryLabel,
	secondaryCallback,
	afterClose,
	secondaryContent,
	successIcon = false,
	shouldDelete = true,
	showCloseButton = true,
	isDismissed = false,
	dismissProcedure,
	alignButtonsVertically,
}) {
	useEffect(() => {
		if (open) {
			modalType === ERROR
				? ErrorModal({ title, primaryCallback, primaryLabel, secondaryLabel, secondaryCallback, content, alignButtonsVertically })
				: modalType === CONFIRM
				? ConfirmModal({
						title,
						primaryCallback,
						primaryLabel,
						secondaryLabel,
						secondaryCallback,
						content,
						secondaryContent,
						shouldDelete,
						showCloseButton,
						alignButtonsVertically,
				  })
				: modalType === INPUT
				? InputModal({
						title,
						primaryLabel,
						secondaryLabel,
						primaryCallback,
						secondaryCallback,
						content,
						inputType,
						alignButtonsVertically,

				  })
				: InfoModal({ title, primaryCallback, primaryLabel, secondaryLabel, successIcon, secondaryCallback, content, list, isDismissed, dismissProcedure, showCloseButton, alignButtonsVertically });
		}
	}, [
		open,
		secondaryContent,
		secondaryCallback,
		title,
		content,
		list,
		modalType,
		primaryCallback,
		primaryLabel,
		secondaryLabel,
		showCloseButton,
		alignButtonsVertically
	]);
	return <Fragment />;
}
