import React from "react";
import "./CustomCheckbox.scss";

/**
 *
 * @param {Object} props
 * @param {Boolean}props.checked
 * @param {()=>void=} props.onSelected
 * @param {()=>void=} props.onClick
 * @param {Boolean=} props.disabled
 * @param {String=}  props.className
 
 * @returns
 */
export default function CustomCheckbox({ checked, onClick, onSelected, disabled = false, className = "" }) {
	return (
		<label className={`custom-selection-wrapper-alt ${className}`}>
			<input
				onClick={() => {
					if (onClick) onClick();
					if (onSelected) onSelected();
				}}
				type="checkbox"
				checked={checked}
				disabled={disabled}
			/>
			<span className={`${disabled ? "checkmark-disabled" : "checkmark"} checkmark-customize`} />
		</label>
	);
}
