const branchData = [
	{
		title: "New Kingston",
		street: "70-72 Lady Musgrave Road",
		town: "Kingston 5",
		registryAddress: "New kingston",
		code: "RAGICSSNK",
		parish: " Kingston",
		telephone: "876-978-3690",
		ext: "-9",
		latitude: 18.00768,
		longitude: -76.78078,
	},
	{
		title: "May Pen",
		street: "48-50 Main Street",
		town: "May Pen",
		code: "RAGICSSMP",
		parish: " Clarendon",
		telephone: "876-786-3617",
		ext: "",
		latitude: 17.969386,
		longitude: -77.242971,
	},
	{
		title: "Port Antonio",
		street: "2A Harpers Lane",
		town: "Port Antonio",
		code: "RAGICSSPA",
		parish: " Portland",
		telephone: "876-993-2626",
		ext: "",
		latitude: 18.175716,
		longitude: -76.444816,
	},
	{
		title: "Black River",
		street: "CGB Plaza, Shop 2, 30-32 North Street",
		town: "Black River",
		code: "RAGICSSBR",
		parish: "St. Elizabeth",
		telephone: "876-965-2755",
		ext: "",
		latitude: 18.033572,
		longitude: -77.856732,
	},
	{
		title: "Montego Bay",
		street: "Shop#15B, Fairview Commercial Centre",
		town: "Montego Bay",
		code: "RAGICSSMB",
		parish: "St. James",
		telephone: "876-952-3293",
		ext: "",
		latitude: 18.453608,
		longitude: -77.924451,
	},
	{
		title: "Portmore",
		street: "31 East Trade Way",
		town: "Portmore",
		code: "RAGICSSPM",
		parish: "St. Catherine",
		telephone: "876-998-6157",
		ext: "",
		latitude: 17.95458,
		longitude: -76.881989,
	},
	{
		title: "Christiana",
		street: "Shop 1, Super Plus Centre, Main Street",
		town: "Christiana",
		code: "RAGICSSCA",
		parish: "Manchester",
		telephone: "876-964-7111",
		ext: "",
		latitude: 18.172989,
		longitude: -77.490294,
	},
	{
		title: "Mandeville",
		street: "Sagicor Life Building, 59 Main Street ",
		town: " Mandeville",
		code: "RAGICSSMV",
		parish: "Manchester",
		telephone: "876-962-3315",
		ext: "",
		latitude: 18.036951,
		longitude: -77.510944,
	},
	{
		title: "Savanna-La-Mar",
		street: "Shop 9A, Beckford Plaza, 33-35A Beckford Street",
		town: "Savanna La Mar",
		code: "RAGICSSSM",
		parish: "Westmoreland",
		telephone: "876-918-0264",
		ext: "-6",
		latitude: 18.224748,
		longitude: -78.133304,
	},
	{
		title: "Ocho Rios",
		street: " Shops #36 & 37, Soni’s Plaza, 50 Main Street,",
		town: "Ocho Rios",
		code: "RAGICSSOR",
		parish: "St. Ann",
		telephone: "876-974-4085",
		ext: "-7",
		latitude: 18.410443,
		longitude: -77.101182,
	},
];

export default branchData;
