import { checkKey } from "helpers/auth";

export const getRegistryReportMethods = async () => {
	let data = {};

	const endpoint = `/getRegistryReportMethods`;
	const payload = {
		param: {
		},
	};
	const reqType = `get`;

	data = checkKey({ endpoint, payload,  reqType });

	return data;
};

export const getRegistrySourceTypes = async () => {
	let data = {};

	const endpoint = `/getRegistrySourceTypes`;
	const payload = {
		param: {
		},
	};
	const reqType = `get`;

	data = checkKey({ endpoint, payload,  reqType });

	return data;
};

export const getRegistrySources = async ( source_type, branch) => {
	let data = {};

	const endpoint = `/getRegistrySources`;
	const payload = {
		params: {
			source_type: source_type,
			branch: branch,
		},
	};
	const reqType = `get`;

	data = checkKey({ endpoint, payload, reqType });

	return data;
};

export const getBranches = async () => {
	let data = {};

	const endpoint = `​/getBranches`;
	const payload = {
		params: {
		},
	};
	const reqType = `get`;

	data = checkKey({ endpoint, payload, reqType });

	return data;
};

export const registrySubmit = async ( payload) => {
	let data = {};

	const endpoint = `/registrySubmit`;

	data = await checkKey({ endpoint, payload });

	return data;
};
