export function capitalizeFirstLetter(string) {
	let capitalizedString;

	if (string.length > 1) {
		capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
	} else if (string.length === 1) {
		capitalizedString = string.toUpperCase();
	} else {
		capitalizedString = string;
	}

	return capitalizedString;
}

export function isNotEmpty(temp) {
	return !(temp === null || temp === undefined || temp === "");
}