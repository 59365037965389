import { compressPDF } from "helpers/pdf";

export const handleCompressPDF = async (file, blob) => {
    let response = null;
	let endpoint = `/misc/compressFile`;
    let payload = {
        "fileName": file.name,
        "base64Content": blob.substring(28)
    }
    
    console.log("handleCompressPDF payload", payload);
	response = await compressPDF({ endpoint, payload });
    console.log("handleCompressPDF response", response);
    return response;
};