import { nodeApi } from "api/Middleware";

async function getServerTime() {
	// const axios = require("axios").default;
	const response = await nodeApi.post(`/time/get`,
		{},
		{
			headers: {
				Accept: "application/json;*/*",
				"Content-Type": "application/json",
			},
		}
	);

	return response.data;
}

export { getServerTime };
