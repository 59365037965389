import React from "react";

import "./PersonInfo.scss";

/**
 * Displays a persons's info card with toggleable dropdown
 * @param {Object} props
 */
export default function PersonInfo({ image, imageWebp, name, branch, description, position }) {
	/**
	 *
	 * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e
	 */
	function toggleDescription(e) {
		const findRootNode = node => {
			if (!node) {
				return null;
			}
			return node?.classList?.contains("person-container") ? node : findRootNode(node.parentNode);
		};

		const person_container = findRootNode(e.currentTarget);

		if (!person_container) return;

		// ensure that the description has a height { can't transition to auto}
		if (description) {
			const description = person_container.querySelector(".person-description");
			let height = description.scrollHeight;
			if (!person_container.classList.contains("focused")) {
				// expand description
				description.style.height = height + "px";
				description.addEventListener("transitionend", function fdd(e) {
					description.removeEventListener("transitionend", fdd);
					description.style.height = null;
				});
			} else {
				// collapse description
				let transitions = description.style.transition;
				description.style.height = height + "px";
				description.style.transition = "";

				requestAnimationFrame(() => {
					description.style.transition = transitions;
					requestAnimationFrame(() => {
						description.style.height = "0px";
					});
				});
			}
		}

		/** Remove class from all who possess it*/
		const clearFocused = () => {
			Array.from(document.getElementsByClassName("focused")).map(e => e.classList.toggle("focused"));
		};

		if (person_container.classList.contains("focused")) {
			clearFocused();
		} else {
			clearFocused();
			person_container.classList.toggle("focused");
		}
	}

	return (
		<div id={name} className="person-container" onClick={toggleDescription}>
			<div className="person-info">
				<div className={`image-container`}>
					{image && (
						<picture>
							<source srcSet={imageWebp} type="image/webp" />
							<source srcSet={image} type="image/png" style={{height:"255px"}}/>
							<img src={image} className="person-img" alt={name} loading="lazy"/>
						</picture>
					)}
					<div className="info">
						<p className="name">{name}</p>
						<p className="position">{position}</p>
						{branch && <p className="branch">{branch}</p>}
					</div>
				</div>
			</div>
			{description && (
				<>
					<div className={`person-description`}>
						<p>{description}</p>
					</div>
				</>
			)}
		</div>
	);
}
