import React, { useState } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import { AuthNetworkLayer, PolicyHelper } from "helpers";
import { useSelector, useDispatch } from "react-redux";
import { setLoading, setLoadingMessage } from "globals/overlays";
import Timer from "react-compound-timer";
import Modal from "components/misc/Modal";
import jwtDecoder from "jwt-decode";
import {
	getUser,
	login,
	getToken,
	setRenewablePolicies,
	getUserFormData,
	getUserId,
	setCurrentUser,
	setPolicies,
	setExtendablePolicies,
	getTransactionType,
	setCoverNotePolicies,
	setActivePolicies,
	setHasMotorPolicies,
	setHasHomePolicies,
	setHasLiabilitiesPolicies,
	setToken,


} from "globals/auth";
import { PAY_BALANCE, RENEWAL } from "pages/RenewPolicy/costants";
import { getExtendablePoliciesPaymentPlan } from "helpers/auth";
import { ERROR } from "../Modal/constants";
import { createLog } from "helpers/log";
import { clearPolicyManager } from "globals/policymanager";
import { getAllClaimStatus, getClaims } from "api/dNet/Claims";
import { setClaimsForTracking, setClaimsStatuses } from "globals/claims";

export default function OTPVerification({
	isClaims = false,
	isTrackAClaim = false,
	isPayBalance = false,
	isPolicyManager = false,
	location,
}) {
	const token = useSelector(getToken);
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const userId = useSelector(getUserId);
	const userFormData = useSelector(getUserFormData);
	const transactionType = useSelector(getTransactionType);
	const [error, setError] = useState("");
	const [OTPToken, setOTPToken] = useState("");
	const [isModalVisible, showModal] = useState(false);

	/**
	 * Similar to the function that is used to generate
	 * the OTP in the previous page.*/
	const resendToken = async () => {
		try {
			dispatch(setLoadingMessage('Resending token'));
			dispatch(setLoading(true));
			let method = location.state;
			// use the values passed from OTP
			let response = await AuthNetworkLayer.sendOTP(
				`${user.first_name || user.last_name || user.company_name}`,
				{ [method.method]: method.contact },
				userId,
				userFormData.national_id,

			);
			if (!response.success) throw new Error(response.message);
		} catch (e) {
			setError(e.message);
			showModal(true);
		} finally {
			dispatch(setLoading(false));
		}
	};

	const checkPoliciesForDepartmentClass = (policies, departmentClass) => {
		return policies.some(policy => policy.department_class === departmentClass);
	}

	const getClaimStatus =async () =>{
		try
		{
		const claimsStatuses = await getAllClaimStatus();
		dispatch(setClaimsStatuses(claimsStatuses.response))
		}
		catch(e)
		{
			console.log(e);
		}

	} 




	const fetchClaims = async (policies) => {
		let i;
		const newClaimsArray = [];
		for (i = 0; i < policies?.length; i++) {
			dispatch(setLoadingMessage(`Loading policy details: ${policies?.length - (i+1)} policies remaining`));
			const payload = {
				policy_id: policies[i]?.policy_id,
			};


			const retrievedClaims = await getClaims(payload);

			if (!retrievedClaims?.success)
				continue;

			newClaimsArray.push(...retrievedClaims.claims);
		}
		return newClaimsArray;
	}
	
	/**
	 * Verifies an entered user token
	 * @param {*} otp
	 * @param {*} userid
	 * @param {*} national_id
	 
	 */
	const verifyToken = async (otp, userid, national_id) => {
		let response,
			data = {};

		let hasMotorPolicies = false;
		let hasHomePolicies = false;
		let hasLiabilityPolicies = false;
		try {
			dispatch(setLoadingMessage('Authenticating User'));
			dispatch(setLoading(true));
			response = await AuthNetworkLayer.verifyOTP(otp, userid, national_id, transactionType);
			data = jwtDecoder(response.data);
			if (!response?.success) {
				throw new Error(data?.message);
			}

			let fetchedClaims = [];
			if (isTrackAClaim)
			{
				let statuses = await getClaimStatus();
				fetchedClaims = [... await fetchClaims(data?.policies)];
			}

			if (fetchedClaims?.length)
			dispatch(setClaimsForTracking(fetchedClaims));

			dispatch(login());
			// check for jwt
			const jwt_auth = process.env.REACT_APP_JWT_AUTHENTICATION;
			if (jwt_auth) {
				dispatch(setToken(data.token));
			}
			dispatch(setCurrentUser(data.user));

			console.log("Policies 1: ", data?.policies);

			hasMotorPolicies = checkPoliciesForDepartmentClass(data?.policies, 'Motor');
			hasHomePolicies = checkPoliciesForDepartmentClass(data?.policies, 'Fire');
			hasLiabilityPolicies = checkPoliciesForDepartmentClass(data?.policies, 'Liability');

			dispatch(setHasMotorPolicies(hasMotorPolicies));
			dispatch(setHasHomePolicies(hasHomePolicies));
			dispatch(setHasLiabilitiesPolicies(hasLiabilityPolicies));

			const filteredPolicies = data.policies.filter(policy=> policy.department_class !== 'Liability');

			if (isClaims) {
				const filteredPoliciesForClaims = filteredPolicies.filter(policy=> policy.department_class !== 'Fire');
				dispatch(setPolicies(PolicyHelper.filterForClaims(filteredPoliciesForClaims)));
				isTrackAClaim ? history.push("/track-my-claim/claim-listing") : history.push("/claims/contact-info");
			} else if (transactionType === PAY_BALANCE) {
				let extendablePolices = await getExtendablePoliciesPaymentPlan(filteredPolicies);
				dispatch(setExtendablePolicies(PolicyHelper.filterForExtension(extendablePolices)));
				history.push(`/${PAY_BALANCE}/contact-info`);
			} else if (isPolicyManager) {
				//Updating of Home policies are not being facilitated right now.

				const hasHome = filteredPolicies.some(policy=> policy.department_class !== 'Fire');

				dispatch(setHasHomePolicies(hasHome));

				const filteredPoliciesForPolicyManager = filteredPolicies.filter(policy=> policy.department_class !== 'Fire');
				dispatch(setActivePolicies(PolicyHelper.findAllActiveAndRenewablePolicies(filteredPoliciesForPolicyManager)));
				
				
				dispatch(clearPolicyManager());
				history.push("/update-your-policy/policy-listing");
			} else {
				dispatch(setRenewablePolicies(PolicyHelper.filterForRenewal(filteredPolicies)));
				history.push(`/${RENEWAL}/contact-info`);
			}
		} catch (e) {
			setError(e.message);
			dispatch(setLoading(false));
			showModal(true);
		} finally {
			dispatch(setLoading(false));
			createLog({
				trn: national_id,
				type: `${transactionType}-OTP`,
				data: { ...data },
				errors: { message: error || !response.success ? response.message : "" },
			});
		}
		if (token === "") return <Redirect to="/renewal/login" />;
	};

	return (
		<section className="otp-style constrain-medium">
			<Modal
				modalType={ERROR}
				title="An Error Occurred"
				content={error}
				open={isModalVisible}
				primaryLabel="OK"
				primaryCallback={() => showModal(false)}
				afterClose={() => setError("")}
			/>

			<Timer initialTime={300000} direction="backward" timeToUpdate={950}>
				{({ reset, start }) => (
					<div className="view otp-verification w-75 mx-auto">
						<p>Please enter your One Time Password (OTP) to verify your identity</p>
						<div className="field">
							<input type="text" value={OTPToken} onChange={e => setOTPToken(e.target.value)} maxLength={6} />
							<>
								<Timer.Minutes />:
								<Timer.Seconds formatValue={value => (value < 10 ? `0${value}` : `${value}`)} />
							</>
						</div>

						<Link
							style={{ marginTop: "1em", justifySelf: "flex-start" }}
							to={
								isTrackAClaim
									? "/track-my-claim/otp"
									: isClaims
										? "/claims/otp"
										: isPolicyManager
											? "/update-your-policy/otp"
											: (transactionType === PAY_BALANCE)
												? "/paybalance/otp"
												: "/renewal/otp"
							}
						>
							Change verification method?
						</Link>

						<div className="button-wrapper">
							<button
								onClick={() =>
									resendToken().then(() => {
										reset();
										start();
									})
								}
								className="button secondary-theme-btn"
							>
								Resend
							</button>
							<button
								className="button primary-theme-btn"
								onClick={() => verifyToken(OTPToken, userId, userFormData.national_id)}
								disabled={OTPToken.length === 0}
							>
								Continue
							</button>
						</div>
					</div>
				)}
			</Timer>
		</section>
	);
}
