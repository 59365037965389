import { checkKey } from "../auth";

/**
     * @param {Object} data
 *  */

export function CreateDocumentAPI(data) {
    const endpoint = `Documents/createDocument`;

    let payload = {
        "user_defined_form_id": data.formId,
        "generate_pdf": data.shouldGeneratePdf,
        "claim_id": data.claimId,
        "policy_id": data.policyId,
        "risk_item_id": data.riskItemId,
        "transaction_id": data.transactionId,
    };
    const reqType = "post";

    return checkKey({ endpoint, payload, reqType });
}
