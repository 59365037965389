import React from "react";

const { Field } = require("formik");
const { CountryDropdown, RegionDropdown } = require("react-country-region-selector");


export default function  CustomSelect  ({
	classAddons = "",
	label,
	value = "",
	name,
	options = [],
    touched,
	country = "",
	error = null,
	...otherProps
}) {
	return (
	<label
	className={"form-label" +
	`${touched && error ? " error" : ""}` +
	`${value !== "" ? " hasValue" : ""}` +
	`${touched && error ? " hasError" : ""}`}>
	
    {name === "country"?( <div>
	<span className="field-title">{label}</span>
	<span className="required"> * </span>
	</div>
    ):(
    <div>
	<span className="field-title">{country === "Jamaica" ? "Parish" : "Province"}</span>
	{country === "Jamaica" && <span className="required"> * </span>}
	</div>) 
    }
   
			<>
				<Field
					className="field"
                    tabIndex="0"
					name={name}
					value={value}
					as={
						name === "country"
							? CountryDropdown
							: name === "region"
							? RegionDropdown
							: "select"
					}
					country={country}
					priorityOptions={["JM", "US", "CA", "GB"]}
					{...otherProps}
				/>

                {touched && error && <div className="error-message">{error}</div>}
								
          
            </>
		
	  </label>
	);
}
