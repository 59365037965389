import { createSlice } from "@reduxjs/toolkit";
import { fullLogout } from "globals/misc";

const defaultState = {
	paymentResponse: {
		session: null,
		sourceOfFunds: null,
	}, 
	paymentStatus:"",
	paymentOverDue:false, 
	paymentFormData: {
		firstName: null,
		lastName: null,
		paymentType: {},
		paymentAmount: "0.00",
		nationalId: null,
		policyNumber: null,
		currency: null,
		paymentPurpose: null,
		codes: null,
		premiumPA_UM: null,
		extensions: null,
		paymentMethod: null,
		receiveReceiptOption: null,
		receiveReceiptAtEmail: null,
		GCT: null,
		premium: null,
		totalPayments: null,
		riskId: null,
		newMarketValue: null,
		transactionId: null,
	},
	paymentSchedule: null,
	paymentScheduleData: null,
};

let paymentSlice = createSlice({
	name: "payment",
	initialState: defaultState,
	reducers: { 
		setPaymentStatus: (state, action) => {
			state.paymentStatus = action.payload;
		},
		setPaymentResponse: (state, action) => {
			state.paymentResponse = action.payload;
		},
		setPaymentOverDue: (state, action) => {
			state.paymentOverDue = action.payload;
		},  
		/**
		 * @param {Object} action
		 * @param {Object} action.payload
		 * @param {Object} action.payload.firstName
		 * @param {Object} action.payload.lastName
		 * @param {Object} action.payload.paymentType
		 * @param {Object} action.payload.codes
		 * @param {String} action.payload.currency
		 * @param {String} action.payload.paymentAmount
		 * @param {String} action.payload.paymentPurpose
		 * @param {String} action.payload.nationalId
		 * @param {String} action.payload.policyNumber
		 * @param {Object} action.payload.premiumPA_UM
		 * @param {Object} action.payload.extensions
		 * @param {String=} action.payload.paymentMethod
		 * @param {String=} action.payload.receiveReceiptOption
		 * @param {String=} action.payload.receiveReceiptAtEmail
		 * @param {String=} action.payload.GCT
		 * @param {String=} action.payload.premium
		 * @param {Number=} action.payload.totalPayments
		 * @param {String=} action.payload.riskId
		 * @param {Number=} action.payload.newMarketValue
		 * @param {String=} action.payload.transactionId
		 */
		setPaymentFormData: (state, action) => {
			state.paymentFormData = {
				paymentMethod: null,
				receiveReceiptAtEmail: null,
				receiveReceiptOption: null,
				GCT: null,
				premium: null,
				totalPayments: null,
				riskId: null,
				newMarketValue: null,
				transactionId: null,
				...action.payload,
			};
		},
		setPaymentSchedule: (state, action) => {
			state.paymentSchedule = action.payload;
		},
		setPaymentScheduleData: (state, action) => {
			state.paymentScheduleData = action.payload;
		},

		clearPaymentForm: state => {
			state.paymentFormData = defaultState.paymentFormData;
		},

		clearPayments: () => defaultState,
	},
	extraReducers: builder => {
		builder.addCase(fullLogout, (state, action) => defaultState);
	},
});
/** @param {{payment: defaultState}} state */
export const getPaymentStatus = ({ payment: state }) => state.paymentStatus;
 
/** @param {{payment: defaultState}} state */
export const getPaymentOverDue = ({ payment: state }) => state.paymentOverDue;
 
/** @param {{payment: defaultState}} state */
export const getPaymentResponse = ({ payment: state }) => state.paymentResponse;

/** @param {{payment: defaultState}} state */
export const getPaymentSchedule = ({ payment: state }) => state.paymentSchedule;

/** @param {{payment: defaultState}} state */
export const getPaymentScheduleData = ({ payment: state }) => state.paymentScheduleData;

/** @param {{payment: defaultState}} state */
export const getPaymentFormData = ({ payment: state }) => state.paymentFormData;

export const {
	setPaymentStatus,
	setPaymentOverDue, 
	setPaymentResponse,
	setPaymentFormData,
	clearPaymentForm,
	clearPayments,
	setPaymentScheduleData,
	setPaymentSchedule,
} = paymentSlice.actions;

export default paymentSlice.reducer;
