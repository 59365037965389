import { createSlice } from "@reduxjs/toolkit";
import { fullLogout } from "globals/misc";

const defaultState = {
	policyForClaims: {},
	claimHandlerID: "",
	passengers: [],
	completedSteps: [],
	claimsQuestions: [],
	claimsNoteQuestions: [],
	claimsDamageQuestion: [],
	selectedAccidentType: "",
	selectedFile: [],
	questions: [],
	sourceType: "",
	currentBlob: null,
	answer: [],
	isClaimSearch: null,
	witnesses: [],
	registryTypes: [],
	registry_id: "",
	hasWitnesses: null,
	documentTypes: [],
	policeReceipt: [],
	emailSent: null,
	email: "",
	received_from: "",
	reportedToPolice: null,
	customerAtFault: null,
	claimsRepairEstimatesDocuments: [],
	claimsSubmitUploadDocuments: {},
	claimSuccessDocuments: [],
	claimToDamages: null,
	hasRepairEstimateFiles: null,
	note: "",
	currentPolicyNumber: "",
	descriptionNote: "",
	descriptionDocument: "",
	accidentTypeQuestion: "",
	riskId: null,
	currentClaimId: "",
	currentBlobModal: false,
	claimId: "",
	dateReported: "",
	lossDate: "",
	fullAddress: "",
	streetNumber: "",
	streetType: "",
	streetName: "",
	parish: "",
	town: "",
	general_area: "",
	lossResult: "",
	claimReview: {},
	authorizationDetails: {},
	allImages: [],
	accidentAffected: "",
	claimValue: "",
	isOpened: false,
	currentPolicyDate: "",
	indicatorUpdater: "",
	timeDisplayed: "",
	lossResults: [],
	timeMeridian: "",
	thirdPartyDetails: [],
	yourAccidentDetails: [],
	serviceProvider: {},
	otherServiceProviderName: "",
	estimateFile: [],
	thirdPartyCounter: 1,
	thirdPartyOccupantAnswer: "",
	claimDocumentsSent: false,
	insuredName: "",
	insuredVehicleDamagePanel: [],
	insuredDriverWasInjured: null,
	otherPartyDocs: [],
	driverInfoDocs: [],
	otherDetails: {},
	insuredDamageDocs: [],
	isLateClaim: null,
	outstandingDocuments: [],
	currentLossDate: "",
	claimSubmittedSuccessfully: false,
	registryCreatedSuccessfully: false,
	isSCDFlow: null,
	policyId: "",
	regNo: "",
	renewalSumMarketValueFile: [],
	claimsForTracking:[],
	activeClaimForTracking: {},
	claimsStatuses:[],
};

let claimSlice = createSlice({
	name: "claim",
	initialState: defaultState,
	reducers: {
		setPolicyForClaims: (state, action) => {
			state.policyForClaims = action.payload;
		},
		setOutstandingDocuments: (state, action) => {
			state.outstandingDocuments = action.payload;
		},
		setClaimHandlerID: (state, action) => {
			state.claimHandlerID = action.payload;
		},
		setRegNo: (state, action) => {
			state.regNo = action.payload;
		},
		setInsuredName: (state, action) => {
			state.insuredName = action.payload;
		},
		setClaimSubmittedSuccessfully: (state, action) => {
			state.claimSubmittedSuccessfully = action.payload;
		},
		setRegistryCreatedSuccessfully: (state, action) => {
			state.registryCreatedSuccessfully = action.payload;
		},
		setRegistryTypes: (state, action) => {
			state.registryTypes = action.payload;
		},
		setIsSCDFlow: (state, action) => {
			state.isSCDFlow = action.payload;
		},
		setRegistryId: (state, action) => {
			state.registry_id = action.payload;
		},
		setCurrentLossDate: (state, action) => {
			state.currentLossDate = action.payload;
		},
		setSpecificOutstandingDocument: (state, action) => {
			state.outstandingDocuments[action.payload.key][action.payload.index] = action.payload.data;
		},
		setInsuredDamageDocs: (state, action) => {
			state.insuredDamageDocs = action.payload;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setReceivedFrom: (state, action) => {
			state.received_from = action.payload;
		},
		setGlobalSourceType: (state, action) => {
			state.sourceType = action.payload;
		},

		setCurrentBlobModal: (state, action) => {
			state.currentBlobModal = action.payload;
		},
		setClaimSuccessDocuments: (state, action) => {
			state.claimSuccessDocuments = action.payload;
		},
		setCurrentBlob: (state, action) => {
			state.currentBlob = action.payload;
		},
		setcurrentClaimId: (state, action) => {
			state.currentClaimId = action.payload;
		},
		setOtherDetails: (state, action) => {
			state.otherDetails = action.payload;
		},
		setIsClaimSearch: (state, action) => {
			state.isClaimSearch = action.payload;
		},
		setDriverInfoDocs: (state, action) => {
			state.driverInfoDocs = action.payload;
		},
		setEmailSent: (state, action) => {
			state.emailSent = action.payload;
		},

		setCurrentPolicyNumber: (state, action) => {
			state.currentPolicyNumber = action.payload;
		},
		setPassengerDetails: (state, action) => {
			state.passengers = action.payload;
		},
		setIsLateClaim: (state, action) => {
			state.isLateClaim = action.payload;
		},
		setOtherPartyDocs: (state, action) => {
			state.otherPartyDocs = action.payload;
		},
		setinsuredDriverWasInjured: (state, action) => {
			state.insuredDriverWasInjured = action.payload;
		},
		setThirdPartyOccupantAnswer: (state, action) => {
			state.thirdPartyOccupantAnswer = action.payload;
		},
		setThirdPartyCounter: (state, action) => {
			state.thirdPartyCounter = action.payload;
		},
		setThirdPartyDetails: (state, action) => {
			state.thirdPartyDetails = action.payload;
		},
		setAllImages: (state, action) => {
			state.allImages = action.payload;
		},
		setServiceProvider: (state, action) => {
			state.serviceProvider = action.payload;
		},
		setOtherServiceProviderName: (state, action) => {
			state.otherServiceProviderName = action.payload;
		},
		setTimeDisplayed: (state, action) => {
			state.timeDisplayed = action.payload;
		},
		setTimeMeridian: (state, action) => {
			state.timeMeridian = action.payload;
		},
		setIndicatorUpdater: (state, action) => {
			state.indicatorUpdater = action.payload;
		},

		setEstimateFile: (state, action) => {
			state.estimateFile = action.payload;
		},

		setCurrentPolicyDate: (state, action) => {
			state.currentPolicyDate = action.payload;
		},
		setIsOpened: (state, action) => {
			state.isOpened = action.payload;
		},
		setCompletedSteps: (state, action) => {
			let processNumber = action.payload;
			let oldSteps = state.completedSteps;
			oldSteps.push(processNumber);
			state.completedSteps = [...new Set(oldSteps)];
		},
		setFullAddress: (state, action) => {
			state.fullAddress = action.payload;
		},
		setStreetNumber: (state, action) => {
			state.streetNumber = action.payload;
		},
		setStreetType: (state, action) => {
			state.streetType = action.payload;
		},
		setStreetName: (state, action) => {
			state.streetName = action.payload;
		},
		setParish: (state, action) => {
			state.parish = action.payload;
		},
		setDocumentTypes: (state, action) => {
			state.documentTypes = action.payload;
		},
		setTown: (state, action) => {
			state.town = action.payload;
		},
		setLossResult: (state, action) => {
			state.lossResult = action.payload;
		},
		setLossResults: (state, action) => {
			state.lossResults = action.payload;
		},

		setRiskId: (state, action) => {
			state.riskId = action.payload;
		},
		setClaimId: (state, action) => {
			state.claimId = action.payload;
		},
		setClaimsForTracking: (state, action)=>{
			state.claimsForTracking = action.payload;
		},
		setActiveClaimForTracking: (state, action)=>{
			state.activeClaimForTracking = action.payload;
		},
		setPolicyId: (state, action) => {
			state.policyId = action.payload;
		},
		setDateReported: (state, action) => {
			state.dateReported = action.payload;
		},
		setLossDate: (state, action) => {
			state.lossDate = action.payload;
		},
		setNote: (state, action) => {
			state.note = action.payload;
		},
		setDescriptionNote: (state, action) => {
			state.descriptionNote = action.payload;
		},
		setDescriptionDocument: (state, action) => {
			state.descriptionDocument = action.payload;
		},

		setAccidentTypeQuestion: (state, action) => {
			state.accidentTypeQuestion = action.payload;
		},

		setClaimsQuestions: (state, action) => {
			state.claimsQuestions = action.payload;
		},

		setClaimsNoteQuestions: (state, action) => {
			state.claimsNoteQuestions = action.payload;
		},

		setclaimsDamageQuestion: (state, action) => {
			state.claimsDamageQuestion = action.payload;
		},

		setAccidentType: (state, action) => {
			state.selectedAccidentType = action.payload;
		},
		setSelectedFile: (state, action) => {
			state.selectedFile = action.payload;
		},
		addAnswer: (state, action) => {
			state.answer = action.payload;
		},
		setQuestion: (state, action) => {
			state.questions = action.payload;
		},
		setGeneralArea: (state, action) => {
			state.general_area = action.payload;
		},
		setClaimReview: (state, action) => {
			state.claimReview = action.payload;
		},
		setAuthorizationDetails: (state, action) => {
			state.authorizationDetails = action.payload;
		},
		setAccidentAffected: (state, action) => {
			state.accidentAffected = action.payload;
		},
		setYourAccidentDetails: (state, action) => {
			state.yourAccidentDetails = action.payload;
		},
		setClaimValue: (state, action) => {
			state.claimValue = action.payload;
		},
		setClaimDocumentsSent: (state, action) => {
			state.claimDocumentsSent = action.payload;
		},

		setInsuredVehicleDamagePanel: (state, action) => {
			state.insuredVehicleDamagePanel = action.payload;
		},
		setWitnesses: (state, action) => {
			state.witnesses = action.payload;
		},
		setHasWitness: (state, action) => {
			state.hasWitnesses = action.payload;
		},
		setPoliceReceipt: (state, action) => {
			state.policeReceipt = action.payload;
		},
		setWasReportedToPolice: (state, action) => {
			state.reportedToPolice = action.payload;
		},
		setCustomerIsAtFault: (state, action) => {
			state.customerAtFault = action.payload;
		},
		setClaimRepairsEstimateFiles: (state, action) => {
			state.claimsRepairEstimatesDocuments = action.payload;
		},
		setClaimSubmitUploadFiles: (state, action) => {
			state.claimsSubmitUploadDocuments = action.payload;
		},
		setClaimToDamages: (state, action) => {
			state.claimToDamages = action.payload;
		},
		setClaimsStatuses: (state, action) => {
			state.claimsStatuses= action.payload;
		},
		setHasRepairsEstimate: (state, action) => {
			state.hasRepairEstimateFiles = action.payload;
		},
		setRenewalSumMarketValueFile: (state, action) => {
			state.renewalSumMarketValueFile = Array.isArray(action.payload) ? action.payload : [action.payload];
		},
		setDefault: () => defaultState,
	},
	extraReducers: builder => {
		builder.addCase(fullLogout, (state, action) => defaultState);
	},
});

export const getPolicyForClaims = ({ claim: state }) => state.policyForClaims;
export const getInsuredDamageDocs = ({ claim: state }) => state.insuredDamageDocs;
export const getOtherDetails = ({ claim: state }) => state.otherDetails;
export const getClaimHandlerID = ({ claim: state }) => state.claimHandlerID;
export const getRegNo = ({ claim: state }) => state.regNo;
export const getPassengerDetails = ({ claim: state }) => state.passengers;
export const getSourceType = ({ claim: state }) => state.sourceType;
export const getCurrentLossDate = ({ claim: state }) => state.currentLossDate;
export const getDriverInfoDocs = ({ claim: state }) => state.driverInfoDocs;
export const getIsClaimSearch = ({ claim: state }) => state.isClaimSearch;
export const getOtherPartyDocs = ({ claim: state }) => state.otherPartyDocs;
export const getInsuredVehicleDamagePanel = ({ claim: state }) => state.insuredVehicleDamagePanel;
export const getThirdPartOccupantAnswer = ({ claim: state }) => state.thirdPartyOccupantAnswer;
export const getThirdPartyCounter = ({ claim: state }) => state.thirdPartyCounter;
export const getThirdPartyDetails = ({ claim: state }) => state.thirdPartyDetails;
export const getInusredName = ({ claim: state }) => state.insuredName;
export const getTimeDisplayed = ({ claim: state }) => state.timeDisplayed;
export const getTimeMeridian = ({ claim: state }) => state.timeMeridian;
export const getIsOpened = ({ claim: state }) => state.isOpened;
export const getIsClose = ({ claim: state }) => state.isClose;
export const getCompletedSteps = ({ claim: state }) => state.completedSteps;
export const getIsSCDFlow = ({ claim: state }) => state.isSCDFlow;
export const getClaimValue = ({ claim: state }) => state.claimValue;
export const getRiskId = ({ claim: state }) => state.riskId;
export const getIsLateClaim = ({ claim: state }) => state.isLateClaim;
export const getEmail = ({ claim: state }) => state.email;
export const getClaimSubmittedSuccessfully = ({ claim: state }) => state.claimSubmittedSuccessfully;
export const getRegistryCreatedSuccessfully = ({ claim: state }) => state.registryCreatedSuccessfully;
export const getReceivedFrom = ({ claim: state }) => state.received_from;
export const getServiceProvider = ({ claim: state }) => state.serviceProvider;
export const getOtherServiceProviderName = ({ claim: state }) => state.otherServiceProviderName;
export const getAllImages = ({ claim: state }) => state.allImages;
export const getClaimId = ({ claim: state }) => state.claimId;
export const getLossDate = ({ claim: state }) => state.lossDate;
export const getRegistryId = ({ claim: state }) => state.registry_id;
export const getDateReported = ({ claim: state }) => state.dateReported;
export const getcurrentClaimId = ({ claim: state }) => state.currentClaimId;
export const getRegistryTypes = ({ claim: state }) => state.registryTypes;
export const getDocumentTypes = ({ claim: state }) => state.documentTypes;
export const getClaimsQuestions = ({ claim: state }) => state.claimsQuestions;
export const getClaimsNoteQuestions = ({ claim: state }) => state.claimsNoteQuestions;
export const getclaimsDamageQuestion = ({ claim: state }) => state.claimsDamageQuestion;
export const getAccidentType = ({ claim: state }) => state.selectedAccidentType;
export const getAccidentTypeQuestion = ({ claim: state }) => state.accidentTypeQuestion;
export const getOutstandingDocuments = ({ claim: state }) => state.outstandingDocuments;
export const getSelectedFile = ({ claim: state }) => state.selectedFile;
export const getQuestion = ({ claim: state }) => state.questions;
export const getAnswer = ({ claim: state }) => state.answer;
export const getCurrentBlob = ({ claim: state }) => state.currentBlob;
export const getNote = ({ claim: state }) => state.note;
export const getCurrentBlobModal = ({ claim: state }) => state.currentBlobModal;
export const getLossResults = ({ claim: state }) => state.lossResults;
export const getInsuredDriverWasInjured = ({ claim: state }) => state.insuredDriverWasInjured;
export const getEstimateFile = ({ claim: state }) => state.estimateFile;
export const getDescriptionNote = ({ claim: state }) => state.descriptionNote;
export const getDescriptionDocument = ({ claim: state }) => state.descriptionDocument;
export const getGeneralArea = ({ claim: state }) => state.general_area;
export const getLossResult = ({ claim: state }) => state.lossResult;
export const getFullAddress = ({ claim: state }) => state.fullAddress;
export const getPolicyId = ({ claim: state }) => state.policyId;
export const getStreetNumber = ({ claim: state }) => state.streetNumber;
export const getStreetName = ({ claim: state }) => state.streetName;
export const getCurrentPolicyNumber = ({ claim: state }) => state.currentPolicyNumber;
export const getStreetType = ({ claim: state }) => state.streetType;
export const getParish = ({ claim: state }) => state.parish;
export const getTown = ({ claim: state }) => state.town;
export const getClaimReview = ({ claim: state }) => state.claimReview;
export const getAuthorizationDetails = ({ claim: state }) => state.authorizationDetails;
export const getAccidentAffected = ({ claim: state }) => state.accidentAffected;
export const getCurrentPolicyDate = ({ claim: state }) => state.currentPolicyDate;
export const getIndicatorUpdater = ({ claim: state }) => state.indicatorUpdater;
export const getYourAccidentDetails = ({ claim: state }) => state.yourAccidentDetails;
export const getClaimDocumentsSent = ({ claim: state }) => state.claimDocumentsSent;
export const getClaimWitnesse = ({ claim: state }) => state.witnesses;
export const getHasWitnesses = ({ claim: state }) => state.hasWitnesses;
export const wasEmailSent = ({ claim: state }) => state.emailSent;
export const getCustomerIsAtFault = ({ claim: state }) => state.customerAtFault;
export const getWasReportedToPolice = ({ claim: state }) => state.reportedToPolice;
export const getPoliceReceipt = ({ claim: state }) => state.policeReceipt;
export const getClaimToDamages = ({ claim: state }) => state.claimToDamages;
export const getHasRepairEstimates = ({ claim: state }) => state.hasRepairEstimateFiles;
export const getClaimsRepairsEstimateFiles = ({ claim: state }) => state.claimsRepairEstimatesDocuments;
export const getClaimsSuccessDocuments = ({ claim: state }) => state.claimSuccessDocuments;
export const getClaimsSubmitUploadFiles = ({ claim: state }) => state.claimsSubmitUploadDocuments;
export const getRenewalSumMarketValueFile = ({ claim: state }) => state.renewalSumMarketValueFile;
export const getClaimsForTracking = ({claim: state})=> state.claimsForTracking;
export const getClaimsStatuses = ({claim: state})=>state.claimsStatuses;
export const getActiveClaimForTracking = ({claim: state}) => state.activeClaimForTracking;

export const {
	setPolicyForClaims,
	setClaimHandlerID,
	setPassengerDetails,
	setWitnesses,
	setRegistryId,
	setHasWitness,
	setCompletedSteps,
	setCurrentLossDate,
	setCurrentBlob,
	setGlobalSourceType,
	setClaimsQuestions,
	setClaimsNoteQuestions,
	setRegNo,
	setClaimToDamages,
	setclaimsDamageQuestion,
	setRegistryTypes,
	setClaimRepairsEstimateFiles,
	setClaimSubmitUploadFiles,
	setcurrentClaimId,
	setInsuredName,
	setHasRepairsEstimate,
	setQuestion,
	setIsClaimSearch,
	setAccidentType,
	setPolicyId,
	addAnswer,
	setIsSCDFlow,
	setCurrentPolicyNumber,
	setSelectedFile,
	setinsuredDriverWasInjured,
	setClaimSuccessDocuments,
	setDefault,
	setEmailSent,
	setNote,
	setClaimId,
	setClaimsForTracking,
	setActiveClaimForTracking,
	setServiceProvider,
	setOtherServiceProviderName,
	setAllImages,
	setAccidentTypeQuestion,
	setRiskId,
	setEmail,
	setReceivedFrom,
	setDateReported,
	setDocumentTypes,
	setLossDate,
	setDescriptionNote,
	setDescriptionDocument,
	setGeneralArea,
	setEstimateFile,
	setLossResult,
	setFullAddress,
	setParish,
	setStreetName,
	setStreetNumber,
	setStreetType,
	setTown,
	setClaimReview,
	setClaimSubmittedSuccessfully,
	setRegistryCreatedSuccessfully,
	setAuthorizationDetails,
	setAccidentAffected,
	setIsLateClaim,
	setClaimValue,
	setCurrentBlobModal,
	setIsOpened,
	setCurrentPolicyDate,
	setIndicatorUpdater,
	setTimeDisplayed,
	setLossResults,
	setTimeMeridian,
	setYourAccidentDetails,
	setThirdPartyDetails,
	setThirdPartyCounter,
	setThirdPartyOccupantAnswer,
	setClaimDocumentsSent,
	setInsuredVehicleDamagePanel,
	setCustomerIsAtFault,
	setPoliceReceipt,
	setWasReportedToPolice,
	setOtherPartyDocs,
	setDriverInfoDocs,
	setOtherDetails,
	setInsuredDamageDocs,
	setSpecificOutstandingDocument,
	setOutstandingDocuments,
	setRenewalSumMarketValueFile,
	setClaimsStatuses
} = claimSlice.actions;
export default claimSlice.reducer;
