import { array, object, string } from "yup";

/**
 *
 * @param {Object} otherDetailsParty
 *
 * @param {*} otherDetailsParty.driverIsOwner
 * @param {*} otherDetailsParty.driverAtFault
 * @param {*} otherDetailsParty.hasInfo
 * @param {Object} otherDetailsParty.vehicle
 * @param {*} otherDetailsParty.vehicle.regNo
 * @param {*} otherDetailsParty.vehicle.insuranceCo
 * @param {*} otherDetailsParty.vehicle.policyNo
 * @param {*} otherDetailsParty.vehicle.insuranceCompany
 *  @param {*} otherDetailsParty.vehicle.vehicleMake
 * @param {*} otherDetailsParty.vehicle.vehicleYear
 * @param {*} otherDetailsParty.vehicle.vehicleModel
 * @param {Object} otherDetailsParty.owner
 * @param {*} otherDetailsParty.owner.ownerFname
 * @param {*} otherDetailsParty.owner.ownerLname
 * @param {*} otherDetailsParty.owner.ownerTrn
 * @param {*} otherDetailsParty.owner.ownerPhoneNumber
 * @param {*} otherDetailsParty.owner.ownerEmail
 * @param {*} otherDetailsParty.owner.ownerInjury
 * @param {*} otherDetailsParty.owner.ownerInjuryDetails
 * @param {*} otherDetailsParty.owner.ownerWasDriver
 * @param {*} otherDetailsParty.propertyOwnername
 * @param {*} otherDetailsParty.propertyOwnerPhoneNumber
 * @param {Object} otherDetailsParty.driver
 * @param {*} otherDetailsParty.driver.driverFname
 * @param {*} otherDetailsParty.driver.driverLname
 * @param {*} otherDetailsParty.driver.driverTrn
 * @param {*} otherDetailsParty.driver.driverPhoneNumber
 * @param {*} otherDetailsParty.driver.driverEmail
 * @param {*} otherDetailsParty.driver.driverInjury
 * @param {*} otherDetailsParty.driver.driverInjuryDetails
 * @param {[]} otherDetailsParty.passengers
 * @param {*} otherDetailsParty.panelDetails
 *
 * @returns {Object}
 */
export const defaultFormValues = otherDetailsParty => ({
	driverIsOwner: otherDetailsParty?.driverIsOwner || "",
	driverAtFault: otherDetailsParty?.driverAtFault || "",
	hasInfo: otherDetailsParty?.hasInfo || "",
	regNo: otherDetailsParty?.vehicle?.regNo || "",
	insuranceCo: otherDetailsParty?.vehicle?.insuranceCompany || "",
	policyNo: otherDetailsParty?.vehicle?.policyNo || "",
	vehicleMake: otherDetailsParty?.vehicle?.vehicleMake || "",
	vehicleModel: otherDetailsParty?.vehicle?.vehicleModel || "",
	vehicleYear: otherDetailsParty?.vehicle?.vehicleYear || "",
	ownerFname: otherDetailsParty?.owner?.ownerFname || "",
	ownerLname: otherDetailsParty?.owner?.ownerLname || "",
	ownerTrn: "",
	ownerPhoneNumber: otherDetailsParty?.owner?.ownerPhoneNumber || "",
	ownerEmail: "",
	ownerInjury: "",
	ownerInjuryDetails: "",
	ownerWasDriver: "",
	propertyOwnername: "",
	propertyOwnerPhoneNumber: "",
	driverFname: otherDetailsParty?.driver?.driverFname || "",
	driverLname: otherDetailsParty?.driver?.driverLname || "",
	driverTrn: otherDetailsParty?.driver?.driverTrn || "",
	driverPhoneNumber: otherDetailsParty?.driver?.driverPhoneNumber || "",
	driverEmail: otherDetailsParty?.driver?.driverEmail || "",
	driverInjury: otherDetailsParty?.driver?.driverInjury || "",
	driverInjuryDetails: otherDetailsParty?.driver?.driverInjuryDetails || "",
	passengers: otherDetailsParty?.passengers || [],
	panelDetails: otherDetailsParty?.panelDetails || [],
});
export const TRNMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
export const phoneNo = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

export const schema = object().shape({
	driverIsOwner: string(),
	regNo: string().required("The Vehicle Registration is Required"),
	insuranceCo: string(),
	vehicleMake: string().required("The Make of the vehicle is Required"),
	vehicleModel: string(),
	vehicleYear: string(),
	driverAtFault: string().required("An answer to this question is required."),
	policyNo: string(),
	ownerFname: string().when("driverIsOwner", {
		is: "Yes",
		otherwise: s => s.required("Owner's First Name required"),
	}),
	ownerLname: string().when("driverIsOwner", {
		is: "Yes",
		otherwise: s => s.required("Owner's Last Name required"),
	}),
	ownerTrn: string().matches(/^[\d]{3}-[\d]{3}-[\d]{3}$/, "Please enter a valid TRN"),
	ownerPhoneNumber: string().matches(/^[\d]{3}-[\d]{3}-[\d]{4}$/, "Enter a valid Phone Number"),
	ownerEmail: string()
		.trim("")
		.matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email address"),
	ownerInjury: string(),
	ownerInjuryDetails: string(),
	driverFname: string().required("Driver First Name is Required"),
	driverLname: string().required("Driver Last Name is Required"),
	driverTrn: string()
		.matches(/^[\d]{3}-[\d]{3}-[\d]{3}$/, "Please enter a valid TRN")
		.required("Driver TRN is Required"),
	driverPhoneNumber: string().matches(/^[\d]{3}-[\d]{3}-[\d]{4}$/, "Enter a valid Phone Number"),
	driverEmail: string()
		.trim("")
		.matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Please enter a valid Email Address"),
	driverInjury: string().required("Select if the Drive is Injured"),
	driverInjuryDetails: string(),
	passengers: array().of(
		object().shape({
			firstName: string().required("Passenger's First Name is Required"),
			lastName: string().required("Passenger's Last Name is Required"),
			trn: string().matches(/^[\d]{3}-[\d]{3}-[\d]{3}$/, "Please enter a valid TRN"),
			email: string()
				.trim("")
				.matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please enter a valid Email Address"),
			telephone: string().matches(/^[\d]{3}-[\d]{3}-[\d]{4}$/, "Please enter a valid Phone Number"),
			injured: string().required("Passenger's Injury status needs to be selected"),
			injuryStatus: string(),
		})
	),
	panelDetails: array(),
});
