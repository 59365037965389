import { useEffect, useState } from "react";
import calendar_icon from "assets/img/claims_icons/calendar.svg";
import "./Calendar.scss";

export default function Calendar({ getSelectedDate }) {
	const [selectedDate, setSelectedDate] = useState("");
	const today = new Date();
	let currentDay = today.getDay();
	let currentMonth = today.getMonth() + 1;
	const currentYear = today.getFullYear();
	currentDay = currentDay < 10 ? "0" + currentDay.toString() : currentDay.toString();
	currentMonth = currentMonth < 10 ? "0" + currentMonth.toString() : currentMonth.toString();
	const maxDate = new Date().toLocaleDateString("en-ca");

	useEffect(() => {
		if (selectedDate !== undefined && selectedDate !== "") getSelectedDate(selectedDate);
	}, [selectedDate]);

	return (
		<div className="calendar-container">
			<input
				className="date-input"
				type="date"
				onChange={e => setSelectedDate(e.target.value)}
				value={selectedDate}
				max={maxDate}
			/> 
		</div>
	);
}
