import { DUE_NOW, DUE_SOON, PAID, PAST_DUE } from "components/PayBalanceProcess/PaymentSchedule/constants";
import { differenceInDays, parse } from "date-fns";
import { arrayOfAcceptedPages, RIGHT } from "globals/globalConstants";
export function truncate(str, length = 100) {
	if (str) {
		return `${str?.substr(0, length)}${str?.length > length ? "" : ""}`;
	}

	return str;
}
export const greaterThan40 = (value, sum_insured = 0) => {
	return parseFloat(value) < sum_insured - sum_insured * 0.4;
};
export const lessThan5 = (value, sum_insured = 0) => {
	return parseFloat(value) > sum_insured + sum_insured * 0.05;
};

export function getStatus({ effective_date, transaction_id }) {
	let status = "-";
	let style = {};

	if (transaction_id) {
		status = PAID;
		style = { color: "#84bd00" };
	} else {
		const now = new Date();
		let due = parse(effective_date, "MM/dd/yyyy", now);
		let diff = differenceInDays(now, due);

		// is more than 1 day late?
		if (diff > 5) {
			status = PAST_DUE;
			style = "past-due";
		}
		// is less than 3 days early
		else if (diff > -3) {
			status = DUE_NOW;
			style = "due-now";
		}
		// due within 3 - 7 days
		else if (diff >= -7) {
			status = DUE_SOON;
			style = "due-soon";
		}
		// otherwise ignore
	}
	return {
		status,
		style,
	};
}
//Validate Email entered
export const validateEmail = email => {
	return email !== "" && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
};

/**
 * ?This function is a utility function to control the scroll
 * ? of a container horizontally given the direction and scroll value
 * @param {Object} scrollRef
 * @param {String} dir
 */
export const scrollFunc = (scrollRef, dir) => {
	if (dir === RIGHT) {
		scrollRef.current.scrollTo({
			left: scrollRef?.current?.scrollLeft + scrollRef?.current?.clientWidth,
			behavior: "smooth",
		});
	} else {
		scrollRef.current.scrollTo({
			left: scrollRef?.current?.scrollLeft - scrollRef?.current?.clientWidth,
			behavior: "smooth",
		});
	}
};

// Boolean to check if scroll is fully scrolled to the right
export const isScrollRightAtLimit = scrollRef => {
	return scrollRef?.current?.scrollLeft === scrollRef?.current?.scrollWidth - scrollRef?.current?.clientWidth;
};

// Boolean to check if scroll is fully scrolled to the left
export const isScrollLeftAtLimit = scrollRef => {
	return scrollRef?.current?.scrollLeft === 0;
};

export const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 2,
});

//Function is used to determine the name to display based on whether the user is a company or individual
export const determineUserTypeForNameDisplay = user => {
	return user?.is_a_company ? user?.company_name : `${user.first_name} ${user.last_name}`;
};

export const bannerShouldPersist = page => {
	//Checking if the page matchs any of the following url
	return arrayOfAcceptedPages.includes(page);
};

/**
 * Wraps fileReader in a promise for more async manipulation
 * @param {Blob} inputFile - If it's a string, it resolves with that value instead
 */
export const readUploadedFileAsText = inputFile => {
	return new Promise((resolve, reject) => {
		if (typeof inputFile === typeof "") resolve(inputFile);
		const temporaryFileReader = new FileReader();

		temporaryFileReader.onerror = () => {
			temporaryFileReader.abort();
			reject(new DOMException("Problem parsing input file."));
		};

		temporaryFileReader.onloadend = () => {
			resolve(temporaryFileReader.result.toString().split(",")[1]);
		};

		temporaryFileReader.readAsDataURL(inputFile);
	});
};
