import CustomCheckbox from "components/CustomCheckbox";
import { differenceInDays, differenceInHours, differenceInMinutes, format, isWithinInterval, parse, subDays } from "date-fns";
import { PolicyHelper } from "helpers";
import { formatMoney } from "helpers/policy";
import { PAID, PAST_DUE, DUE_NOW, DUE_SOON } from "./constants";
import { useScreenType } from "globals/screenTypeHook";
import checker from "assets/img/additionalbenefits_icons/cart_checker2.svg";
import disabledCartChecker from "assets/img/additionalbenefits_icons/disabled-cart_checker.svg";
import emptySelection from "assets/img/additionalbenefits_icons/empty_selection.svg";

/** Component to specify the status of the modal */
export function getStatus(flow, { effective_date, transaction_id }) {
	let status = "-";
	let style = {}; 

	if (transaction_id) {
		status = PAID;
		style = { color: "#12ff00" };
	} else {
		const now = new Date();
		let due = parse(effective_date, "MM/dd/yyyy", now);
		let diff = flow === "update your policy" ? differenceInMinutes(now, due) : differenceInDays(now, due);
		
		if(flow === "update your policy"){
			if (diff > 1) {
				status = PAST_DUE;
				style = "past-due";
			} 
		} else {
			// is more than 1 day late?
			if (diff > 5) {
				status = PAST_DUE;
				style = "past-due"; 
			}
			// is less than 3 days early
			else if (diff > -3) {
				status = DUE_NOW;
				style = "due-now";
			}
			// due within 3 - 7 days
			else if (diff >= -7) {
				status = DUE_SOON;
				style = "due-soon";
			}
			// otherwise ignore
		}
		
	}
	return { 
		status,
		style,
	};
}

/** Calculate the total based on `selected ` terms accounting for late fees */
export function calcExtensionTotal(terms, tax = 1) {
	return terms?.reduce((total, term) => {
		return (
			total +
			(term?.status !== PAID && term?.selected === true ? term?.payment_term_premium_with_tax + term?.late_fee : 0)
		);
	}, 0);
}

export function calcExtensionTotalAlt(terms, tax = 1) {
	return terms?.reduce((total, term) => {
		return total + (term?.status !== PAID ? term?.payment_term_premium_with_tax + term?.late_fee : 0);
	}, 0);
}

/**
 * calculates the proper payent plans normalized for AGIC
 *
 * @returns {Array} payment_terms
 * @param {Object} paymentPlan - the plan on policy
 * @param {Array} risks
 * @param {Number} taxPercent -
 */
export function recalculatePaymentPlans(flow, paymentPlan, risks, taxPercent, policy_start) {
	const is_older =
		new Date(process.env.REACT_APP_MODE === "development" ? "11/14/2021" : "12/21/2021") > new Date(policy_start);
	const fullRiderPremium = PolicyHelper.removeAllRiderPremiums(risks);
	/* if policy_start date is older then they will be charged in part else nothing*/
	const totalDeductions = is_older ? fullRiderPremium / paymentPlan?.payment_terms.length : 0;
	const totalDeductionsWithTax = totalDeductions * taxPercent;

	return paymentPlan?.payment_terms.map(term => {
		const { status, style } = getStatus(flow, term);
		const lateFee = status === PAST_DUE ? term.penalty_fee_amount * taxPercent : 0; 
		return {
			...term,
			status,
			style,
			selected: [PAID, PAST_DUE, DUE_NOW].includes(status),
			payment_term_premium_with_tax: term.payment_term_premium * taxPercent - totalDeductionsWithTax,
			payment_term_premium: term.payment_term_premium - totalDeductions,
			late_fee: lateFee,
		};
	});
}

export const checkCertPeriod = (validCert, policyStart, policyEnd, certEnd) => {
	if (validCert) {
		let start = parse(policyStart, "MM/dd/yyyy HH:mm:ss", new Date());
		let end = parse(policyEnd, "MM/dd/yyyy HH:mm:ss", new Date());
		certEnd = parse(certEnd, "MM/dd/yyyy HH:mm:ss", new Date());

		return isWithinInterval(certEnd, { start: start, end: end });
	}

	return false;
};

export const checkIfExtendable = ( missingDocuments) => {
	// const { risks } = policy;
	// let certExpiry = risks[0].veh_cert_expiry_date;
	// let createdCert = certExpiry ? true : false;
	// const validCertPeriod = checkCertPeriod(createdCert, policy.start_date, policy.end_date, certExpiry);  

	return missingDocuments?.[0]?.documents?.length === undefined;
};

/**
 * 1. Sends a payment agreement note if the user select any PART payment
 * 2. Stores the necessary data in redux to prefill certain fields on payments
 * 3. Navigates to payment plan breakdown page for PART payments
 */

export const toggleSelection = (index, status, localPlanTerms) => {
	if (status === PAST_DUE || status === PAID) {
		// not able to deselect
		return null;
	} else {
		let temp = [...localPlanTerms];
		temp[index].selected = !temp[index].selected;
		// recursively select all other plans beneath to true
		if (temp[index].selected === true) {
			for (let a = index - 1; a > 0; a--) {
				if (temp[a].status === PAID) break;
				temp[a].selected = true;
			}
		} else if (temp[index].selected === false) {
			// all policies above should be set to false
			for (let a = index + 1; a < temp.length; a++) {
				temp[a].selected = false;
			}
		}

		return temp;
	}
};
/**
 *
 * @param {Array} localPlanTerms
 * @param {Object} policy
 * @param {(a,b,c)=>void} onToggle
 */
export function planTermsMapping(localPlanTerms, policy, onToggle) {
 
	return localPlanTerms?.map((term, index) => {
		const paid = term?.transaction_id ? true : false;
		const lastTerm = index + 1 === policy?.paymentPlan?.payment_terms?.length;
		const { isMobile, isTablet, isDesktop, isLargeDesktop} = useScreenType();
		//let state = status(term)
		//const currrentDue = false; // TODO find attribut to identify these
		//const selected = paid || [DUE_NOW, PAST_DUE].includes(term.status);

		return (
			<>
			{
				isTablet || isDesktop || isLargeDesktop ? <div
					className={lastTerm ? "row-content" : index === 0 ? "row-content-first" : "row-content-alt"}
					key={`${index}pln`}
				>
					<p>{index + 1}</p>
					<p>{format(subDays(parse(term.effective_date, "MM/dd/yyyy", new Date()), 1), "d-MMM-yyyy")?.toUpperCase()}</p>

					<p>{ `JMD ${formatMoney(term.payment_term_premium_with_tax + (term.late_fee || 0))}`}</p>

					<p
						style={{
							color:
								term.status === PAST_DUE || term.status === DUE_NOW
									? "#ff0000"
									: term.status === DUE_SOON
									? "blue"
									: "#84bd00",
						}}
					>
						{term.status}
					</p>
					<div className="local-check">
						<CustomCheckbox
							checked={term.selected}
							onClick={term.status === PAST_DUE ? () => null : () => onToggle(index, term.status, localPlanTerms)}
							disabled={paid}
						/>
					</div>
				</div> : <div>
					<label className={`product-card ${term.selected || term.status === "Paid" ? "selected" : ""} ${term.status === "Paid" && "paid" }`} style={{ border: term.status === "Paid" ? "4px solid #929292" : "", backgroundColor: term.status === "Paid" ?  "#929292" : "rgb(0 168 224)" }}>
						<div className="card-container"> 
							<span className="payment-schedule-num">
								<span className="span-icon">{index + 1}</span>
							</span> 
							
							{(term.selected) ? (
								<img className="check-container" src={checker} />
							):(
								<img className="check-container" src={emptySelection} />
							)}

							{ 
								<div className="terms">
									<div className="status">
										<div>
											<strong>Status:</strong>{" "}
										</div>
										<div>
											<span style={{color: term.status === PAST_DUE || term.status === DUE_NOW
														? "#ff0000"
														: term.status === DUE_SOON
														? "blue"
														: term.style.color
											}}>
												{term.status}
											</span>
										</div>
									</div>
									<div className="am-due">
										<div> <strong>{term.status === "Paid" ? "Amount Paid" : "Amount Due"}:</strong> </div>
										<div> <span>{formatMoney(term.payment_term_premium_with_tax + (term.late_fee || 0))} JMD</span>{" "} </div>
									</div>
									<div className="due-date">
										<div><strong>Due Date: </strong>{" "}</div>
										<div><span >{format(subDays(parse(term.effective_date, "MM/dd/yyyy", new Date()), 1), "d-MMM-yyyy")?.toUpperCase()}</span></div>
									</div>
								</div>
							}
							<div className="trigger">
								<input disabled={paid} onChange={term.status === PAST_DUE ? () => null : () => onToggle(index, term.status, localPlanTerms)} checked={term.selected} type="checkbox" name="verification-method" />
							</div>
						</div>
					</label>
				</div>
				}
			</> 
		);
	});
}
