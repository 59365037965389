import { checkExpToken, checkKey, checkWeirdBadKey } from "helpers/auth";

export const getPolicy = async (policyId, isRenewal) => {
	let policy = {};
	const endpoint = `getPolicy`;
	const payload = {
		params: {
			policyID: policyId,
			isRenewal: isRenewal,
		},
	};
	const reqType = "get";

	policy = await checkKey({ endpoint, payload, reqType });

	return policy;
};

export const getPolicyRisks = async ( policyId, isRenewal) => {
	let risks = {};

	const endpoint = `/getPolicyRisks`;
	const reqType = "get";
	const payload = {
		params: {
			policyID: policyId,
			isRenewal: isRenewal,
		},
	};

	risks = await checkKey({ endpoint, payload, reqType });

	return risks;
};

export const getPolicyExtensions = async ( policyId) => {
	let extensions = {};
	const endpoint = `/getPolicyExtensions`;
	const payload = {
		params: {
			policyID: policyId,
		},
	};
	const reqType = "get";

	extensions = await checkKey({ endpoint, payload, reqType });
	// let policy_id = encodeURIComponent(policyId)
	// await dNet.get(`/getPolicyExtensions?token=${token}&policyID=${policy_id}`).then(res => {
	//     extensions = res.data
	// })
	return extensions;
};

export const getPolicyProfExt = async ( policyPrefix) => {
	let extensions = {};

	const endpoint = `/getPolicyProfExt`;
	const payload = {
		params: {
			policyPrefix: policyPrefix,
		},
	};
	const reqType = "get";

	extensions = checkKey({ endpoint, payload, reqType });

	return extensions;
};

export const checkPromoCode = async ( code) => {
	const endpoint = `/checkPromoCode`;
	const payload = {
		params: {
			ratingCode: code,
		},
	};
	const reqType = "post";

	let ratingCode = checkKey({ endpoint, payload: null, reqType }, payload);

	return ratingCode;
};

export const getPolicyByParams = async ( params) => {
	const endpoint = `/getPolicyByParams`;
	const reqType = "get";
	let policy = {};
	let payload = {
		params: {
			policyNo: params.policy_number,
			regNo: params.regNo,
		},
	};

	policy = checkKey({ endpoint, payload, reqType });
	return policy;
};

export const getPolicyProfRatingCodes = async ( policyPrefix) => {
	let extensions = {};

	const endpoint = `/getRatingCodes`;
	const payload = {
		params: {
			policyPrefix: policyPrefix,
		},
	};
	const reqType = "get";

	extensions = checkKey({ endpoint, payload, reqType });

	return extensions;
};

export const getExchangeRate = async ( from, to, effective_date, country) => {
	let rate = {};

	const endpoint = `/getExchangeRate`;
	const payload = {
		params: {
			from: from,
			to: to,
			date: effective_date,
			country: country,
		},
	};

	const reqType = `get`;

	rate = checkKey({ endpoint, payload, reqType });

	return rate;
};

export const getPaymentPlans = async ( currency, policyPrefix) => {
	let paymentPlans = {};
	const endpoint = `/getPaymentPlans`;

	const payload = {
		params: {
			currency: currency,
			prefix: policyPrefix,
		},
	};

	const reqType = "get";

	paymentPlans = await checkKey({ endpoint, payload, reqType });

	return paymentPlans;
};

export const getTransactionSchedule = async ( transactionId) => {
	let schedule = {};
	let endpoint = `/getTransactionSchedule`;
	let payload = {
		transaction_id: transactionId,
	};

	schedule = await checkKey({ endpoint, payload });

	return schedule;
};

export const getPolicyTransactions = async ( policyId) => {
	let transactions = {};
	let endpoint = `/getPolicyTransactions`;
	const payload = {
		policy_id: policyId,
	};
	transactions = await checkKey({ endpoint, payload });
	return transactions;
};

/**
 
 * @param {{ auto_authorize: boolean; branch: string; receipt_date: string; policy_id: any; amount: number; payment_type: string; currency: string; is_tax_exempt: boolean; }[]} receiptArray
 */
export const drawReceiptBatch = async ( receiptArray) => {
	let payload = {
		receiptArray,
	};

	let endpoint = `/drawReceiptBatch`;

	return await checkWeirdBadKey({ endpoint, payload });
};

/**
 
 * @param {{ auto_authorize: boolean; branch: string; receipt_date: string; policy_id: any; client_global_name_id: string; amount: number; payment_type: string; manual_receipt_number: string; currency: string; is_tax_exempt: boolean; }[]} receiptArray
 */
export const drawReceipt = async ( receipt) => {
	let payload = {
		...receipt,
	};

	let endpoint = `/drawReceipt`;

	return await checkKey({ endpoint, payload });
};

export const getPolicyPaymentPlan = async ( policyId) => {
	let pPaymentPlan = {};

	const endpoint = `/getPolicyPaymentPlan`;
	const payload = {
		params: {
			policy_id: policyId,
		},
	};
	const reqType = `get`;

	pPaymentPlan = checkKey({ endpoint, payload, reqType });

	return pPaymentPlan;
};

export const getStreetTypes = async () => {
	let response = {};

	const endpoint = "/getStreetTypes";
	const payload = {
		params: {
		},
	};

	const reqType = `get`;
	response = await checkKey({ endpoint, payload, reqType });
	return response;
};

export const getParishTowns = async ( param) => {
	let response = {};
	const endpoint = "/getParishTowns";
	const payload = {
		params: {
			param: param,
		},
	};
	const reqType = `get`;
	response = await checkKey({ endpoint, payload, reqType });
	return response;
};

export const getGeneralAreas = async ( param) => {
	let response = {};
	const endpoint = "/getGeneralAreas";
	const payload = {
		params: {
			param: param,
		},
	};
	const reqType = `get`;
	response = await checkKey({ endpoint, payload, reqType });
	return response;
};

export const genPolicyModification = async ( payload) => {
	let response = {};
	const endpoint = `/policyModification`;

	response = await checkKey({ endpoint, payload });
	return response;
};

export const policyModification = async (
	
	policyId,
	effective_date,
	end_date,
	risk_increase_sum_insured,
	risk_id
) => {
	let modifications = {};

	const endpoint = `/policyModification`;
	const payload = {
		params: {
			policy_id: policyId,
			effective_date: effective_date,
			end_date: end_date,
			risk_increase_sum_insured: risk_increase_sum_insured,
			risk_id: risk_id,
		},
	};
	modifications = checkKey({ endpoint, payload });

	return modifications;
};

/**
 *
 * @param {Object} args
  * @param {string} args.policyId
 * @param {string} args.effective_date
 * @param {string} args.end_date
 * @param {string} args.action
 * @param {string} args.trans_wording
 * @param {string} args.risk_id
 * @param {string} args.sum_insured
 * @param {boolean} args.is_do_not_prorate
 * @returns
 */
export const updatRiskSumInsured = async ({
	
	policyId,
	effective_date,
	end_date,
	action,
	trans_wording,
	risk_id,
	sum_insured,
	is_do_not_prorate
}) => {
	let modifications = {};

	const endpoint = `/policyModification`;
	const payload = {
		authorize_transaction: true,
		policy_id: policyId,
		effective_date: effective_date,
		end_date: end_date,
		is_do_not_prorate,
		action: action,
		trans_wordings: {
			trans_wording: trans_wording,
		},
		transaction_premium: 0,
		parameters: {
			risk_id: risk_id,
			sum_insured: sum_insured,
		},
		external_reference_number: "",
		is_tax_exempt: false,
		service_charge_type: "",
		service_charge_value: 0,
	};
	modifications = checkKey({ endpoint, payload });

	return modifications;
};

export const getNewCalculatedPremium = async ({
	
	policyId,
	effective_date,
	end_date,
	action,
	trans_wording,
	risk_id,
	sum_insured,
}) => {
	let modifications = {};
	const endpoint = `/policyModification`;
	const payload = {
		authorize_transaction: true,
		policy_id: policyId,
		effective_date: effective_date,
		end_date: end_date,
		is_do_not_prorate: false,
		action: action,
		trans_wordings: {
			trans_wording: trans_wording,
		},
		transaction_premium: 0,
		parameters: {
			risk_id: risk_id,
			sum_insured: sum_insured,
		},
		external_reference_number: "",
		is_tax_exempt: false,
		service_charge_type: "",
		service_charge_value: 0,
		only_calculate_premium: true,
	};
	modifications = checkKey({ endpoint, payload });

	return modifications;
};

export const createPolicy = async ( payload) => {
	let response = {};
	const endpoint = `createPolicy`;

	response = await checkKey({ endpoint, payload });
	return response;
};

/**
 *
 * @param {Object} args
 * @param {string} args.policy_id
 * @param {string=} args.narratives_to_add
 * @param {string=} args.narratives_to_remove
 * @returns
 */
export const updatePolicyNarratives = async ({  policy_id, narratives_to_add, narratives_to_remove }) => {
	let endpoint = "/updatePolicyNarratives";

	let response = await checkKey({
		endpoint,
		
		payload: {
			policy_id,
			narratives_to_add,
			narratives_to_remove,
		},
	});

	return response;
};

/**
 *
 * @param {Object} args
 
 * @param {string} args.policy_id
 * @param {string} args.policy_start_date
 * @param {string} args.policy_end_date
 * @param {string} args.risk_id
 * @param {string} args.registration_number
 * @param {boolean} args.is_do_not_prorate
 * @returns
 */
export const notingRegistrationNumber = async ({
	
	policy_id,
	policy_start_date,
	policy_end_date,
	risk_id,
	registration_number,
	is_do_not_prorate,
}) => {
	let endpoint = "/policyModification";

	let response = await checkKey({
		endpoint,
		
		payload: {
			authorize_transaction: true,
			policy_id,
			effective_date: policy_start_date,
			end_date: policy_end_date,
			is_do_not_prorate: is_do_not_prorate == null ? false : is_do_not_prorate,
			action: "noting_registration_number",
			trans_wordings: {
				trans_wording: "Noting Registration No.  U.",
			},
			transaction_premium: 0,
			parameters: {
				risk_id,
				registration_number,
			},
			external_reference_number: "",
			is_tax_exempt: false,
			service_charge_type: "",
			service_charge_value: 0,
		},
	});

	return response;
};
/**
 *
 * @param {object} args
 
 * @param {string} args.national_id
 * @param {string} args.national_id_type
 * @returns
 */
export const getPoliciesByInsuredID = async ({  national_id, national_id_type }) => {
	let endpoint = "/getPoliciesByInsuredID";
	const reqType = "get";
	let response = await checkKey({
		endpoint,
		payload: {
			params: { national_id, national_id_type, active_only: true },
		},
		
		reqType,
	});
	return response;
};

export const setToCancel = async ({  policy_id, effective_date, is_manual_premium, reason, transaction_premium }) => {
	let endpoint = "/setToCancel";

	let response = await checkKey({
		endpoint,
		
		payload: {
			policy_id,
			effective_date,
			is_manual_premium,
			reason,
			transaction_premium
		},
	});

	return response;
};

export const cancelPolicy = async ({  policy_id, effective_date, authorize_transaction, trans_wording,
	is_manual_premium, reason, external_reference_number, transaction_premium }) => {
	let endpoint = "/cancelPolicy";

	let response = await checkKey({
		endpoint,
		
		payload: {
			policy_id,
			effective_date,
			is_manual_premium,
			reason,
			transaction_premium,
			authorize_transaction,
			trans_wording,
			external_reference_number,
		},
	});

	return response;
};

export const validateRegNo = async (registration_number, chassisNo) => {
	let payload ={registration_number, chassisNo}
	let response = {};
	const endpoint = `/misc/validate/regno`;

	response = await checkExpToken({ endpoint, payload});
	return response;
};
