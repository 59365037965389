import { format } from "date-fns";
import { dNet, nodeApi } from "api/Middleware";
import { mwGlobalNameCompanySearch, mwGlobalNameSearch } from "api/dNet/GlobalName";
import { checkKey } from ".";

export default class AuthNetworkLayer {
	static global_name_search = "global-name-search";

	/**
	 * Get a token from the server. Continuous token refresh option
	 */
	static async login() {
		let token = "";
		let success = false;

		await dNet.get("/auth").then(res => {
			token = res?.data[0]?.Response[0]?.key;
			success = res?.data[0]?.Response[0]?.Login;
		});

		return { token, success };
	}

	/**
	 *
	 * Get the UserData from the server
	 *
	 * @param {{dob,gender,national_id,national_id_type}} payload
	 * @param {string} global_name_id
	 */
	static async getUserData(payload, global_name_id) {
		// const axios = require("axios").default;
		const response = await nodeApi.post(
			`/auth/contact/methods`,
			{ payload, global_name_id },
			{
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			}
		);

		return response.data;
	}

	/**
	 * @param {any} token
	 * @param {any} globalNameId
	 */
	static async retrieveReceipts(token, globalNameId) {
		// const startDate ="05/05/2019";
		// const endDate = "11/02/2023";

		const endDate = new Date();
		const startDate = new Date(endDate);
		startDate.setFullYear(endDate.getFullYear() - 5);

		const endpoint = `/getReceipts`;
		const reqType = "get";
		let response = {};
		const payload = {
			params: {
				globalNameId: globalNameId,
				startDate: startDate.toLocaleDateString().toString(),
				endDate: endDate.toLocaleDateString().toString(),
			},
		};

		response = await checkKey({ endpoint, payload, reqType });

		if (response.success && response.receipts.length) {
			let filteredReceipts = response.receipts;
			// This currently only allows receipts that match the exact receipt number typed. It should match all the valid receipts for that day
			// for (let i = 0; i < response.data.receipts.length; i++) {
			// 	const receipt = response.data.receipts[i];

			// 	if (receipt.receipt_number.includes(receiptNumber) /* || receipt.policy_number === policyNumber*/)
			// 		filteredReceipts = response.data.receipts;
			// }
			return filteredReceipts;
		} else return null;
	}

	/** Get the global name id of this person or company
	 * @param {{dob: String, gender: String, national_id: String, national_id_type: String, is_a_company: Boolean, company_name: String}} props
	 */
	static async getGlobalNameId({ dob, gender, national_id, national_id_type, is_a_company, company_name }) {
		const payload = {
			national_id_type,
			national_id,
			gender,
			dob,
			is_a_company,
			company_name,
		};

		return mwGlobalNameSearch(payload);
	}

	/** Get the global name id of this person
	 
	 * @param {{national_id: String, national_id_type: String, is_a_company: Boolean, company_name: String}} props
	 */

	static async getGlobalNameCompanyId(token, { national_id, national_id_type, is_a_company, company_name }) {
		const payload = {
			national_id_type,
			national_id,
			is_a_company,
			company_name,
		};

		return mwGlobalNameCompanySearch(token, payload);
	}

	/**
	 * Retrieve session for a user's token
	 
	 * @param {string} trn -- trn of user
	 * @returns {Promise}
	 */

	static async retrieveSession(trn) {
		const endpoint = "/session/retrieve";

		const headers = {
			"customer-id": trn,
			"content-type": "application/json",
		};

		const data = trn;

		const response = await nodeApi.post(endpoint, data, { headers });
		return response.data;
	}

	static async sendOTP(customer, method, userid, national_id, token) {
		// const axios = require("axios").default;
		let data = { customer, userid, national_id, token };
		method.phone ? (data.phone = method.phone) : (data.email = method.email);
		const response = await nodeApi.post(`/token/get`, data, {
			headers: {
				Accept: "application/json;*/*",
				"Content-Type": "application/json",
			},
		});

		return response.data;
	}

	static async sendPaymentConfirmation(userid, national_id, phone, amount, currency, policyNumber) {
		const response = await nodeApi.post(`/token/payment-confirmation-sms`, {
			userid,
			national_id,
			phone,
			amount,
			currency,
			policyNumber,
		});
		return response.data;
	}

	static async verifyOTP(otptoken, userid, national_id, transaction_type) {
		// const axios = require("axios").default;
		const response = await nodeApi.post(
			`/token/verify`,
			{ otp: otptoken, userid: userid, national_id, transaction: transaction_type },
			{
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			}
		);

		return response.data;
	}
	/**
	 *
	 * @param {boolean} is_claims
	 * @param {boolean} is_policy_manager
	 * @param {String} key
	 * @param {String} plate_num
	 * @param {String} policy_num
	 * @param {String} chassis_num
	 * @param {String} national_id
	 * @param {String} mother_maiden_name
	 * @param {String} transaction_type
	 * @param {boolean} is_a_company
	 * @returns
	 */
	static async alternateVerification(
		is_claims,
		is_policy_manager = false,
		key,
		plate_num = "",
		policy_num,
		chassis_num = "",
		national_id,
		mother_maiden_name = "",
		transaction_type,
		is_a_company,
		isTrackAClaim = false
	) {
		const endpoint =
			is_claims && is_a_company ? "" : is_claims && mother_maiden_name === "" ? "" : is_claims ? "/c" : "";

		// const axios = require("axios").default;
		const response = await nodeApi.post(
			`${process.env.REACT_APP_API_URL}/auth/other${endpoint}`,
			{
				key,
				plate_num,
				policy_num,
				is_policy_manager,
				is_claims,
				chassis_num,
				national_id,
				mother_maiden_name,
				transaction: transaction_type,
				is_a_company,
				isTrackAClaim,
			},
			{
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			}
		);

		return response.data;
	}

	/**
	 *
	 * @param {string} national_id
	 * @param {string} policy_number
	 * @returns
	 */
	static async getTrnPolicyToken(national_id, policy_number) {
		const response = await nodeApi.post(
			"/token",
			{ national_id, policy_number },
			{ headers: { Accept: "application/json;*/*", "Content-Type": "application/json" } }
		);
		return response.data;
	}
}
