import React, { useEffect, useRef, useState } from "react";
import "./InfoIcon.scss";

function InfoIcon({ label }) {
	const [active, setActive] = useState(true);
	const popupRef = useRef(null);

	function toggleActive() {
		setActive(!active);
	}

	useEffect(()=>{   
		repositionPopup();
		window.addEventListener("resize", repositionPopup);
		return () => window.removeEventListener("resize", () => {});
	},[active]);

	function repositionPopup(){
		if(popupRef.current) {
			popupRef.current.style.top = `-${ (popupRef.current.offsetHeight / 2) - 5 }px`;
		}
	}
	 
	
	return (
		<span className={`info-icon ${active ? " active" : ""}`} onClick={toggleActive}>
			<span className="mini"></span>
			<span ref={popupRef} className="popup">{label}</span>
		</span>
	);
}

export default InfoIcon;
