import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage/session";
import localForage from "localforage";
import confirmationReducer from "./confirmation";
import paymentReducer from "./payments";
import overlayReducer from "./overlays";
import claimsReducer from "./claims";
import authReducer from "./auth";
import navBarReducer from "./navbar";
// import cartReducer from "./cart";
import additionalBenefitsReducer from "./additionalbenefits";
import policyManagerReducer from "./policymanager";
import renewalReducer from "./renewal";

/** @type {import("redux-persist").PersistConfig} */
let config = {
	key: "root",
	storage: localForage,
	blacklist: ["overlay"],
};

if (process.env.NODE_ENV !== "development") config.blacklist.push("auth", "payment");

let rootReducer = combineReducers({
	additionalbenefit: additionalBenefitsReducer,
	auth: authReducer,
	payment: paymentReducer,
	overlay: overlayReducer,
	confirmation: confirmationReducer,
	claim: claimsReducer,
	navbar: navBarReducer,
	// cart: cartReducer,
	policymanager: policyManagerReducer,
	renewal: renewalReducer,
});

let persistedReducer = persistReducer(config, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
		immutableCheck: false,
	}),
	//Disables dev tools in live server environments
	devTools: process.env.NODE_ENV === "development",
});

const persistor = persistStore(store);

export { store, persistor };
export default store;
