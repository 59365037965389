import { ClaimsNetworkLayer } from "helpers";
import { toast } from "react-toastify";

export const MissingDocUploadToRegistry = async (document, registry_id) => {

	let successArray = []; 
		for (let x = 0; x < document?.length; x++) {
			// don't reupload a document

			let fileName = document[x].name;
			let fileType = document[x].name.split(".").pop().toLowerCase();
			let fileData = document[x].blob;
			let description = document[x].description;

			if (!fileData) {
				successArray[x] = false; 
				toast.error(`File "${fileName}" could not be uploaded but it can be uploaded at a later date.`);
				continue;
			}
			
			let doc_type = "Images";
			if (fileType === "pdf" || fileType === "doc") doc_type = "File";
			else if (fileType === "jpg" || fileType === "jpeg" || fileType === "png") doc_type = "Images";

			const filesLinked = await ClaimsNetworkLayer.linkRegistryFile(
				registry_id,
				fileName,
				fileType,
				fileData,
				description,
				doc_type,
				() => {} 
			);
			// TODO: replace alert with modal popup that prevents navigation until user can confirm the message
			if (!filesLinked.success) {
				successArray[x] = false; 
			} else {
				successArray[x] = true; 
			}
		}
	return successArray;
};
 