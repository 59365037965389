import { checkKey } from "helpers/auth";

export const submitNote = async ( payload) => {
	const endpoint = `/mwSubmitNote`;

	return await checkKey({ endpoint, payload });
};

export const renewalPremiumCalculator = async payload => {
	let response = {};
	//let token = payload.token;
	const endpoint = `/renewalPremiumCalculator`;
	payload = payload.requestBody;
	response = await checkKey({ endpoint, payload });

	return response;
};

export const updateRenewPolicy = async ( payload) => {
	let response = {};
	const endpoint = `/policyUpdateRenew`;

	response = await checkKey({ endpoint, payload });
	return response;
};
