import { addPaymentReminder, removePaymentReminder } from "api/dNet/PaymentReminder";
import { removeRatingCodesFromRisks } from "helpers/policy";
import { drawReceipt } from "api/dNet/Policies";
import SagicorPayment from "api/SagicorPayment";
import Modal from "components/misc/Modal";
import { ERROR, INFO } from "components/misc/Modal/constants";
import { format, isFuture } from "date-fns";
import { getCart } from "globals/additionalbenefits";
import {
	getActivePolicy,
	getActiveRisk,
	getPromoCodesApplied,
	getToken,
	getTransactionType,
	getUser,
	logout,
	setCurrentUser,
	login,
	getActivePolicies,
	setActivePolicies,
	getPolicyPaymentPlan,
	setMissingDocuments,
	setTransactionType,
	setToken,
	setPolicyHolders,
	setSelectedSession,
	getSelectedSession,
	setActivePolicy,
	getUserId,
} from "globals/auth";
import { setDefault } from "globals/claims";
import { setConfirmationData } from "globals/confirmation";
import { fullLogout } from "globals/misc";
import { setLoading, setLoadingMessage } from "globals/overlays";
import { clearPaymentForm, getPaymentResponse, getPaymentScheduleData } from "globals/payments";
import { checkExpToken, getNewGlobalToken } from "helpers/auth";
import { AuthNetworkLayer, PolicyHelper, PolicyNetworkLayer } from "helpers";
import { createLog } from "helpers/log";
import {
	assignReceipt,
	extendPolicy,
	formatMoney,
	formatPolicyForRenewal,
	generateExtensionDocuments,
	getPaymentScheduleInfo,
} from "helpers/policy";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router";
import BillingDetails from "./components/BillingDetails";
import PaymentContent from "./components/PaymentContent";
import PaymentDetails from "./components/PaymentDetails";
import ReceiptErrorModal from "./components/ReceiptErrorModal";
import jwtDecoder from "jwt-decode";
import { getChangeOnPremium } from "globals/policymanager";
import "./PaymentForm.scss";
import { PAY_BALANCE, POLICY_MANAGER, QUICK_PAY } from "pages/RenewPolicy/costants";
import { clearPolicyManager, getNewPremium, getPolicyFormInfo, setPolicyManager } from "globals/policymanager";
import changeMarketValue from "components/misc/PolicyManager/helpers";
import { nodeApi } from "api/Middleware";
import { toast } from "react-toastify";
import { isNotEmpty } from "helpers/misc";
import { FILESNEEDED } from "pages/PolicyManager/constants";
import { getBrokerPolicyCreated, getSourceType } from "globals/renewal";
import * as TransactionHelper from "components/PaymentForm/helpers/index.js";
import axios from "axios";
import { noticeNarratives } from "globals/globalConstants";
import { CreateDocumentAPI } from "helpers/policy/documents";
const uuid = require("uuid").v4;
/**
 * @typedef {Object} paymentFormValues
 * @property {String=} paymentFormValues.nationalId
 * @property {String=} paymentFormValues.policyNumber
 * @property {String=} paymentFormValues.paymentAmount
 * @property {String=} paymentFormValues.paymentPurpose
 * @property {String=} paymentFormValues.paymentPurposeDetailed
 * @property {String=} paymentFormValues.currencyType
 * @property {Boolean=} paymentFormValues.fromRenewalFlow
 * @property {Object=} paymentFormValues.codes
 * @property {Object=} paymentFormValues.premiumPA_UM
 * @property {Object=} paymentFormValues.extensions
 */

/**
 * @typedef {Object} billingFormValues
 * @property {String=} billingFormValues.firstName
 * @property {String=} billingFormValues.lastName
 * @property {String=} billingFormValues.email
 * @property {String=} billingFormValues.phoneNumber
 * @property {String=} billingFormValues.billingAddress
 * @property {String=} billingFormValues.country
 * @property {String=} billingFormValues.region
 * @property {String=} billingFormValues.zip_code
 * @property {String=} billingFormValues.cardNumber
 * @property {String=} billingFormValues.cardName
 * @property {String=} billingFormValues.cardExpiryMonth
 * @property {String=} billingFormValues.cardExpiryYear
 * @property {String=} billingFormValues.cardCVC
 */

/**
 * Contains the logic for the Billing portion of the payment process
 * Requires Payment Details to be confirmed before revealing the billing portion
 * @param {Object} props
 * @param {Object} props.transaction
 * @param {React.MutableRefObject<Boolean>} props.persist
 */
function PaymentForm({ transaction, persist }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const _transaction = useRef({ ...transaction });
	const cart = useSelector(getCart, shallowEqual);
	const promoCodes = useSelector(getPromoCodesApplied, shallowEqual);
	const user = useSelector(getUser, shallowEqual);
	const userId = useSelector(getUserId);
	let policy = useSelector(getActivePolicy, shallowEqual);
	const [policyState, setPolicyState] = useState(policy);
	const policies = useSelector(getActivePolicies, shallowEqual);
	const paymentResponse = useSelector(getPaymentResponse, shallowEqual);
	const paymentScheduleData = useSelector(getPaymentScheduleData, shallowEqual);
	const activeRisk = useSelector(getActiveRisk, shallowEqual);
	const policyFormInfo = useSelector(getPolicyFormInfo, shallowEqual);
	const policyPaymentPlans = useSelector(getPolicyPaymentPlan, shallowEqual);
	const token = useSelector(getToken, shallowEqual);
	const newPremium = useSelector(getNewPremium, shallowEqual);
	const [paymentConfirmed, confirmPayment] = useState(transaction.paymentAmount === "0.00" ? false : true);
	const [keepBilling, persistBilling] = useState(false);
	const [attempts, setAttempts] = useState(0);
	const changeOnPremium = useSelector(getChangeOnPremium, shallowEqual);
	const sourceType = useSelector(getSourceType, shallowEqual);
	const brokerPolicyCreated = useSelector(getBrokerPolicyCreated, shallowEqual);

	/** Disable once a successful transaction is processed */
	const completedSteps = useRef({
		billing: null,
		receipt: null,
		renewal: null,
		extension: [],
		additionalPremium: null,
		paymentSchedule: null,
	});

	if (sourceType === "Broker") completedSteps.current.renewal = brokerPolicyCreated;

	const receiptErrorModalRef = useRef();

	const toggleReceiptErrorModalState = {
		toggleModal: useCallback(() => {
			if (receiptErrorModalRef.current) {
				receiptErrorModalRef.current.toggleModal();
			}
		}, [receiptErrorModalRef]),
		openModal: useCallback(() => {
			if (receiptErrorModalRef.current) {
				receiptErrorModalRef.current.openModal();
			}
		}, [receiptErrorModalRef]),
		closeModal: useCallback(() => {
			if (receiptErrorModalRef.current) {
				receiptErrorModalRef.current.closeModal();
			}
		}, [receiptErrorModalRef]),
	};

	/** @type {[paymentFormValues, React.Dispatch<React.SetStateAction<paymentFormValues>>]} */
	const [paymentDetails, setPaymentDetails] = useState({});

	/** @type {[billingFormValues, React.Dispatch<React.SetStateAction<billingFormValues>>]} */
	const [billingDetails, setBillingDetails] = useState({});

	const [isPaymentModalOpen, openPaymentModal] = useState(false);
	const [isErrorModalOpen, openErrorModal] = useState(false);
	const [isErrorModalOpen2, openErrorModal2] = useState(false);
	const [isValidationErrorModalOpen, openValidationErrorModal] = useState(false);
	const [error, setError] = useState("");
	const [errorTitle, setErrorTitle] = useState(null);
	const transactionType = useSelector(getTransactionType, shallowEqual);
	const sessionData = useSelector(getSelectedSession, shallowEqual);

	useEffect(() => {
		if (location.pathname === "/payments") dispatch(setTransactionType(QUICK_PAY));
	}, [location.pathname, transactionType, dispatch]);

	useEffect(() => {
		if (paymentConfirmed) persistBilling(true);
	}, [paymentConfirmed]);

	async function validateTrnPolicy(transactionType, nationalId, policyNumber) {
		dispatch(setLoading(true));
		dispatch(setLoadingMessage("Just a moment"));
		try {
			if (transactionType === QUICK_PAY && nationalId && policyNumber) {
				const response = await AuthNetworkLayer.getTrnPolicyToken(nationalId.replace(/-/g, ""), policyNumber);
				if (response.success) {
					/** @type {Object} */
					const data = jwtDecoder(response.data);
					dispatch(setActivePolicies(data.policies));
					// set token for loggedIn user
					dispatch(setCurrentUser(data.users[0].global_name));
					dispatch(setPolicyHolders(data?.users));
					dispatch(login());
					// check for jwt
					const jwt_auth = process.env.REACT_APP_JWT_AUTHENTICATION;
					if (jwt_auth) {
						dispatch(setToken(data.token));
					}
					return data.token;
				}
			}
			return false;
		} catch (e) {
			return false;
		} finally {
			dispatch(setLoading(false));
		}
	}

	async function sendPaymentConfirmationSms({ amount, currency, policyNumber }) {
		const userPhoneNumbers = user.phone_numbers;
		if (userPhoneNumbers && userPhoneNumbers.length > 0) {
			const smsPhoneNumber = userPhoneNumbers.find(phone => phone.use_for_sms === true);
			if (smsPhoneNumber) {
				try {
					const confirmationResponse = await AuthNetworkLayer.sendPaymentConfirmation(
						userId,
						user.national_id,
						smsPhoneNumber.phone_number,
						amount,
						currency,
						policyNumber
					);
					if (!confirmationResponse.success) {
						console.error("Failed to send payment confirmation SMS:", confirmationResponse.message);
					}
				} catch (error) {
					console.error("Error sending payment confirmation SMS:", error.message);
				}
			} else {
				console.error("No phone number available for sending SMS.");
			}
		} else {
			console.error("User has no phone numbers.");
		}
	}

	async function validateTrnPolicy2(transactionType, nationalId, policyNumber) {
		dispatch(setLoading(true));
		dispatch(setLoadingMessage("Just a moment"));
		try {
			if (transactionType === QUICK_PAY && nationalId && policyNumber) {
				// get token
				const response = await AuthNetworkLayer.getTrnPolicyToken(nationalId.replace(/-/g, ""), policyNumber);
				if (response.success) {
					/** @type {Object} */
					const data = jwtDecoder(response.data);
					dispatch(setActivePolicies(data.policies));
					console.log("Policies: ", data?.policies);
					// set token for loggedIn user
					setPolicyState(data.policies?.find(p => p.policy.policy_number == policyNumber)?.policy);
					dispatch(setActivePolicy(data.policies?.find(p => p.policy.policy_number == policyNumber)?.policy)); //dispatch(setActivePolicy(policies?.find(p => p.policy.policy_number == policyNumber)?.policy));
					dispatch(setCurrentUser(data.users[0].global_name));
					dispatch(setPolicyHolders(data?.users));
					dispatch(login());
					// check for jwt
					const jwt_auth = process.env.REACT_APP_JWT_AUTHENTICATION;
					if (jwt_auth) {
						dispatch(setToken(data.token));
					}
					return data.token;
				} else {
					if (response.message === "Unable to validate trn and policy number.") {
						return 1;
					}
				}
			}
			return 2;
		} catch (e) {
			return 3;
		} finally {
			dispatch(setLoading(false));
		}
	}

	console.log("policyStae", policyState);

	async function makePayment() {
		openPaymentModal(false);
		dispatch(setLoading(true));
		let isInFlow;

		const amount =
			_transaction.current.paymentAmount === null || _transaction.current.paymentAmount === "0.00"
				? paymentDetails.paymentAmount
				: _transaction.current.paymentAmount;
		const policyNumber =
			_transaction.current.policyNumber === null ? paymentDetails.policyNumber : _transaction.current.policyNumber;
		const currency =
			_transaction.current.currency === null ? paymentDetails.currencyType : _transaction.current.currency;

		const reference =
			new Date().getDay() +
			"" +
			new Date().getUTCMonth() +
			"" +
			new Date().getFullYear() +
			new Date().getMilliseconds() +
			"-" +
			policyNumber;

		let policyManagerData = { fullname: "", email: "", registrationNumbers: [], certificates: [] };

		// active policies should always be updated=
		//if (transactionType === QUICK_PAY) setPolicyState(olicies?.find(p => p.policy.policy_number == policyNumber)?.policy));

		const paymentData = {
			apiOperation: "PAY",
			order: {
				amount: `${amount.replace(/[, ]+/g, "").trim()}`,
				currency: `${currency}`,
				reference: `${reference}`,
			},
			customer: {
				firstName: billingDetails.firstName,
				lastName: billingDetails.lastName,
			},
			session: { id: `${paymentResponse?.session?.id}` },
			sourceOfFunds: { type: "CARD" },
			transaction: {},
			other: {
				policyPrefix: policyState.policy_prefix,
				policyNumber: paymentDetails.policyNumber,
				billingAddress: billingDetails.billingAddress,
				purpose: paymentDetails.paymentPurpose,
			},
		};
		let files = [];
		let filesNeeded = [];
		let renewalResult = {};

		let insuranceTransactionResponse;
		let transactionStatus;
		let transactionReason;
		let isPaymentSuccessful = completedSteps.current.billing ? true : false;
		let responseAfterUpdate = null;
		let receiptResponse;

		try {
			if (!completedSteps.current.billing) {
				dispatch(setLoadingMessage("Processing payment"));
				let transactionResponse = await checkExpToken({
					endpoint: `/payments`,
					payload: paymentData,
					reqType: "put",
				});
				if (!transactionResponse?.success || transactionResponse?.result === "ERROR") {
					if (transactionResponse?.messageTitle) setErrorTitle(transactionResponse?.messageTitle);

					throw new Error(`${transactionResponse?.message}.`);
				}

				isPaymentSuccessful = transactionResponse?.success;

				if (isPaymentSuccessful) {
					toast.success(`Payment of ${currency} ${amount} was successfully charged to your card.`, {
						position: "bottom-left",
						autoClose: 15000,
					});
					sendPaymentConfirmationSms({ amount, currency, policyNumber: paymentDetails.policyNumber });
				} else if (!isPaymentSuccessful) {
					toast.error(
						`Oh no, your payment failed. Please double check your payment information or contact your bank. `,
						{
							position: "bottom-left",
							autoClose: 15000,
						}
					);
				}
				//Prevent repeat billing if subsequent actions fail
				completedSteps.current = { ...completedSteps?.current, billing: transactionResponse };
			}

			if (transactionType === "update-your-policy") {
				files.push({ file_name: "Receipt.pdf", data: completedSteps?.current?.billing?.receipt, type: "Receipt" });
			} else {
				files.push({ filename: "Receipt.pdf", fileContent: completedSteps?.current?.billing?.receipt });
			}

			//If policy number exists, transaction should not be empty
			//checks the transction type if it exist or not still run this because of quick payments
			if (transactionType !== QUICK_PAY) {
				isInFlow = true;

				//checks what Payment Type was selected
				if (
					paymentDetails.paymentPurpose.toLowerCase() === "renewal" ||
					paymentDetails?.paymentPurpose === "New Policy"
				) {
					const handleRenewalTransactionArgumentObject = {
						user,
						policy: policyState,
						formatPolicyForRenewal,
						PolicyHelper,
						_transaction,
						paymentDetails,
						completedSteps,
						dispatch,
						setLoadingMessage,

						cart,
						promoCodes,

						policyNumber,
						renewalResult,
						getPaymentScheduleInfo,
						addPaymentReminder,

						files,
						responseAfterUpdate,
						activeRisk,
						/* assignReceipt,
						drawReceipt,
						createLog,
						toggleReceiptErrorModalState,
						checkExpToken,
						transactionType,
						reference,
						billingDetails,
						error,
						currency,
						tmpToken,
						amount,
						toast,
						 */
					};

					insuranceTransactionResponse = await TransactionHelper.handleRenewalTransaction(
						handleRenewalTransactionArgumentObject
					);
				} else if (paymentDetails.paymentPurpose.toLowerCase() === "pay balance") {
					const handlePayBalanceTransactionArgumentObject = {
						dispatch,
						setLoadingMessage,
						completedSteps,
						policy: policyState,
						user,
						policyNumber,

						getPaymentScheduleInfo,
						_transaction,
						generateExtensionDocuments,
						extendPolicy,
						paymentScheduleData,
						isFuture,
						addPaymentReminder,
						removePaymentReminder,

						files,
						responseAfterUpdate,
						/* assignReceipt,
						paymentDetails,
						drawReceipt,
						toggleReceiptErrorModalState,
						createLog,
						transactionType,
						reference,
						error,
						amount,
						billingDetails,
						currency,
						tmpToken,
						checkExpToken,
						toast,
						 */
					};
					insuranceTransactionResponse = await TransactionHelper.handlePayBalanceTransaction(
						handlePayBalanceTransactionArgumentObject
					);
				} else if (paymentDetails.paymentPurpose.toLowerCase() === "additional premium") {
					const handleAdditionalPremiumTransactionArgumentObject = {
						policyFormInfo,
						activeRisk,
						changeMarketValue,

						policy: policyState,
						changeOnPremium,

						policyPaymentPlans,
						setError,
						openErrorModal2,
						user,
						PolicyNetworkLayer,
						completedSteps,
						/* assignReceipt,
						paymentDetails,
						,
						drawReceipt,
						toggleReceiptErrorModalState,
						createLog,
						transactionType,
						reference,
						error,
						checkExpToken,
						amount,
						billingDetails,
						policyNumber,
						currency,
						tmpToken, */
						dispatch,
						setLoadingMessage,
						toast,
						filesNeeded,
						FILESNEEDED,
						formatMoney,
						newPremium,
						policyManagerData,
						isNotEmpty,
						nodeApi,
						files,
					};

					insuranceTransactionResponse = await TransactionHelper.handleAdditionalPremiumTransaction(
						handleAdditionalPremiumTransactionArgumentObject
					);
				}
			}

			//Should not be an else
			if (completedSteps.current.billing) {
				// Create receipt in underwriter

				let receipt;
				let successful = completedSteps.current.receipt ? true : false;
				let count = 0;

				try {
					receipt = assignReceipt({
						policy: policyState,
						global_name_id: user.userId,
						total: paymentDetails.paymentAmount,
						premiumPA_UM: paymentDetails.premiumPA_UM,
					});

					if (!completedSteps.current.receipt) {
						while (count < 2 && !receiptResponse) {
							receiptResponse = await drawReceipt(receipt);
							count++;
						}
						if (!receiptResponse || !receiptResponse?.success) {
							completedSteps.current.receipt = receiptResponse;
						} else {
							completedSteps.current.receipt = receiptResponse;
							successful = true;
						}
					}
				} catch (e) {
					//	toggleReceiptErrorModalState.openModal();
				} finally {
					createLog({
						trn: user?.national_id,
						type: `${transactionType}-Paymentform-DrawReceipt`,
						data: {
							receiptNum: reference,
							receipt: receipt,
							filesGenerated: files?.map(f => f.filename),
							receiptResponse,
							Count: count,
						},
						errors: { message: error || !receiptResponse?.success ? receiptResponse?.error_message : "" },
					});
				}

				let confirmationResult;
				if (transactionType === QUICK_PAY || !successful)
					try {
						confirmationResult = await checkExpToken({
							endpoint: "/email/quickpay",
							payload: {
								amount: `${amount.replace(/[, ]+/g, "").trim()}`,
								receipt_number: `${reference}`,
								name: `${billingDetails.firstName} ${billingDetails.lastName}`,
								email: billingDetails.email,
								phone: billingDetails.phoneNumber,
								policy_number: policyNumber,
								type: paymentDetails.paymentPurpose,
								currency: currency,
								successful: successful,
								transaction_type: transactionType,
							},
						});
					} catch (e) {
						confirmationResult.message = e.message;
					} finally {
						createLog({
							trn: user?.national_id,
							type: `${transactionType}-Paymentform-Confirmation`,
							data: {
								confirmationResult,
							},
							errors: { message: error || !confirmationResult?.success ? confirmationResult?.message : "" },
						});
					}
			}

			const getHomePolicyDocuments = async () => {
				const userDefinedFormIds = {
					826: "Average Clause",

					638: "Fungus Mildew & Mold Exclusion",

					801: "Mortgagee Clause - Non Motor",
				};

				let riskId;
				const keys = Object.keys(userDefinedFormIds);

				for (const key of keys) {
					riskId = null;

					if (key == "801") {
						for (let i = 0; i < policyState?.risks?.length; i++) {
							if (!policyState?.risks[i]?.mortgagees?.length) continue;

							riskId = policyState?.risks[i].risk_id;
						}
					}

					const createDocumentAPIPayload = {
						formId: parseInt(key),
						shouldGeneratePdf: true,
						claimId: null,
						policyId: policyState.policy_id,
						riskItemId: riskId,
						transactionId: completedSteps.current.renewal?.transaction_id,
					};

					try {
						const homePolicyFileResponse = await CreateDocumentAPI(createDocumentAPIPayload);

						if (homePolicyFileResponse?.success) {
							const file = homePolicyFileResponse?.file;
							const fileName = file?.description;
							files.push({ filename: fileName + ".pdf", fileContent: file?.data });
						}
					} catch (e) {
						console.log("Error: ", e);
					}
				}
			};

			if (
				paymentDetails.paymentPurpose.toLowerCase() === "renewal" &&
				completedSteps.current.renewal &&
				policy.department_class.toLowerCase() === "fire"
			) {
				await getHomePolicyDocuments();
			}
		} catch (e) {
			setError(`${e.message}`);
			//renewalResult.success = false;
			//renewalResult.error_message = `An error occurred. ${e.message}`;
			setAttempts(attempts + 1);
			dispatch(setLoading(false));
			openErrorModal(true);
			return;
		} finally {
			if (isPaymentSuccessful) {
				const source = transactionType === QUICK_PAY ? "Quick Pay" : "Web";
				const sessionType =
					transactionType === QUICK_PAY
						? paymentDetails.paymentPurpose.toLowerCase()
						: paymentDetails.paymentPurpose.toLowerCase() === "additional premium" && paymentDetails.newMarketValue
						? paymentDetails.paymentPurpose.toLowerCase()
						: transactionType;

				//save session payload
				const saveSessionPayload = {
					user,
					policy: policyState,
					paymentForm: {
						...paymentData,
						paymentAmount: amount.replace(/[, ]+/g, "").trim(),
						receipt_no: completedSteps?.current?.receipt?.receipt_number,
					},
					session_type: sessionType,
					receipt_method: "EMAIL",
					was_broker_policy: sourceType === "Broker",
					branch: { title: "AGIC Online" },
					receiptFailed: !completedSteps?.current?.receipt,
					source: source,
				};

				//Paymentdetails payload
				const savePaymentDetailsPayload = {
					session_id: "",
					receipt_amount: amount.replace(/[, ]+/g, "").trim(),
					paymentmethod: "Credit card",
					currency: "JMD",
					receipt_no: completedSteps?.current?.receipt?.receipt_number,
					rate_of_exchange: /* p.currency === "JMD" ? */ "1",
				};

				let payBalanceResponse = {};

				if (transactionType === PAY_BALANCE && insuranceTransactionResponse)
					payBalanceResponse = insuranceTransactionResponse?.findLast(term => term);

				if (!payBalanceResponse) payBalanceResponse = completedSteps.current.extension?.findLast(term => term);

				transactionStatus =
					transactionType === QUICK_PAY
						? "Unknown"
						: payBalanceResponse?.success /*<- Paybalance check*/ || insuranceTransactionResponse?.success
						? "SUCCESS"
						: "FAILED";

				transactionReason =
					transactionType === QUICK_PAY
						? "Awaiting action from Telebranch"
						: payBalanceResponse?.success || insuranceTransactionResponse?.success
						? "Transaction was successful"
						: payBalanceResponse?.error_message /*<- Paybalance check*/ ??
						  completedSteps.current?.renewal?.error_message ??
						  completedSteps.current.additionalPremium;

				const sessionUpdatePayload = {
					session_id: "",
					fields_to_update: {
						receipt_no: completedSteps?.current?.receipt?.receipt_number,
						status: "COMPLETED",
						cashier_user_id: "MW",
						receipt_amount: amount.replace(/[, ]+/g, "").trim(),
						payment_method: "Credit Card",
						payment_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
						transaction_status: transactionStatus,
						transaction_reason: transactionReason,
						activePolicy: policyState,
					},
				};

				const payload = {
					saveSessionPayload,
					savePaymentDetailsPayload,
					sessionUpdatePayload,
					sessionTracking: {
						dispatch,
						sessionData,
						setSelectedSession,
					},
				};

				console.log("sessionData: ", sessionData);
				const savePaymentActivityResponse = await PolicyNetworkLayer.savePaymentActivity(payload);
				console.log("sessionData 2: ", sessionData);
			}

			dispatch(setLoading(false));
			createLog({
				trn: user?.national_id,
				type: `${transactionType}-PaymentForm`,
				data: { receiptNum: reference, filesGenerated: files.map(f => f.filename), renewalResult },
				errors: { message: error || !renewalResult?.success ? renewalResult?.error_message : "" },
			});
		}

			if (transactionType === POLICY_MANAGER) {
				dispatch(clearPolicyManager());
				dispatch(setPolicyManager(policyManagerData));

				dispatch(setMissingDocuments(policy.missing_documents));

			dispatch(
				setConfirmationData({
					receiptNum: reference,
					responseAfterUpdate,
					billingDetails,
					paymentDetails,
					isInFlow: isInFlow,
					files,
				})
			);

			history.replace("success-page");
		} else {
			// not a real logout - block from reentering the flow
			dispatch(fullLogout());
			// reset the wasEmailSent variable located in claimsStore ( consider relocating)
			dispatch(setDefault());

			let missingDocs = policyState?.missing_documents
				? policyState?.missing_documents.filter(narrative => {
						return !noticeNarratives.includes(narrative.code);
				  })
				: [];

			dispatch(setMissingDocuments(missingDocs));
			dispatch(
				setConfirmationData({
					receiptNum: reference,
					responseAfterUpdate,
					billingDetails,
					paymentDetails: { ...paymentDetails, isHomePolicy: policyState.department_class === "Fire" },
					isInFlow: isInFlow,
					files,
				})
			);

			if (completedSteps.current.receipt) history.push("/confirmation");
		}
	}

	/**
	 * Function to mask the middle 8 digits of a card number
	 * @param {String} cardnumber
	 */
	function maskCreditCard(cardnumber) {
		const first4 = cardnumber.substring(0, 4);
		const last5 = cardnumber.substring(cardnumber.length - 5);
		const mask = cardnumber.substring(4, cardnumber.length - 5).replace(/\d/g, "x");
		return first4 + mask + last5;
	}

	/**
	 * Shows a new payment error
	 * @param {String} message
	 */
	function showError(message) {
		setError(message);
		openErrorModal(true);
	}

	/**
	 * Shows a new TRN/Policy No. validation error
	 * @param {String} message
	 */
	function showValidationError(message) {
		setError(message);
		openValidationErrorModal(true);
	}

	/** Simple conditional check to redirect to home if session is to be ended */
	function handleModalClose() {}

	const closeModal = () => {
		openErrorModal(false);
		setErrorTitle(null);
	};

	const closeValidationModal = () => {
		openValidationErrorModal(false);
	};

	return (
		<div className="">
			<div className="wrap-form">
				<h2 className="subtitle">Please enter your details to make a payment</h2>
				<PaymentDetails
					transaction={_transaction.current}
					confirmPayment={confirmPayment}
					paymentConfirmed={paymentConfirmed}
					setPaymentDetails={setPaymentDetails}
					validateTrnPolicy2={validateTrnPolicy2}
					showValidationError={showValidationError}
					persist={persist}
				/>
			</div>
			{keepBilling && (
				<BillingDetails
					setBillingDetails={setBillingDetails}
					showPaymentModal={openPaymentModal}
					showError={showError}
					transaction={_transaction.current}
				/>
			)}

			<Modal
				modalType={INFO}
				open={isPaymentModalOpen}
				title="Review Payment"
				content={
					paymentDetails.policyNumber &&
					paymentResponse.sourceOfFunds && (
						<PaymentContent
							paymentDetails={paymentDetails}
							paymentResponse={paymentResponse}
							maskCreditCard={maskCreditCard}
							billingDetails={billingDetails}
						/>
					)
				}
				subText="Your details will not be stored on our website and are only displayed for verification purposes. By
				submitting this payment, you agree to our Terms of Service."
				primaryLabel="Accept"
				primaryCallback={makePayment}
				secondaryLabel="Cancel"
				secondaryCallback={() => openPaymentModal(false)}
			/>

			<Modal
				modalType={ERROR}
				title={errorTitle ?? "An Error Occurred"}
				content={error}
				open={isErrorModalOpen}
				primaryLabel="OK"
				primaryCallback={closeModal}
				afterClose={handleModalClose}
			/>
			<Modal
				modalType={ERROR}
				title="Unable to Verify"
				content={error}
				open={isValidationErrorModalOpen}
				primaryLabel="OK"
				primaryCallback={closeValidationModal}
				afterClose={handleModalClose}
			/>
			<Modal
				modalType={ERROR}
				title="Failed to Update Policy"
				content={
					"Your policy changes failed to update. But don’t worry, we can help. Contact us via live chat and a representative will happy to assist you with these changes."
				}
				open={isErrorModalOpen2}
				primaryLabel="Exit"
				primaryCallback={() => {
					dispatch(fullLogout());
					history.push("/payments-landing");
				}}
				afterClose={handleModalClose}
			/>
			<ReceiptErrorModal
				ref={receiptErrorModalRef}
				policyNumber={
					_transaction.current.policyNumber == null ? paymentDetails.policyNumber : _transaction.current.policyNumber
				}
				amount={
					_transaction.current.paymentAmount == null ? paymentDetails.paymentAmount : _transaction.current.paymentAmount
				}
				currency={_transaction.current.currency == null ? paymentDetails.currencyType : _transaction.current.currency}
				customer={{
					firstName: billingDetails.firstName,
					lastName: billingDetails.lastName,
					address: billingDetails.billingAddress,
				}}
				paymentType={paymentDetails.paymentPurpose}
			/>
		</div>
	);
}

export default PaymentForm;
