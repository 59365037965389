import { PolicyHelper } from "helpers";
import { calcExtensionTotalAlt, recalculatePaymentPlans } from "../PaymentSchedule/functions";

/**
 * Calculates the correct balance using the payment plan details as well as taxes and rider removal
 * @param {*} risks - Risks Object
 * @param {*} paymentTerm - The active payment term
 * @param {Number} numTerms - Number of payment terms for correct calculation
 * @param {Number} tax - A decimal value for the tax
 */
export function calculateBalance(risks, paymentTerm, numTerms, tax) {
	// Get the correct tax value for calculation
	const taxPercent = 1 + tax;
	// Remove riders from premiums first
	const fullRiderPremium = PolicyHelper.removeAllRiderPremiums(risks);
	// Divide premium by number of terms
	const totalDeductions = fullRiderPremium / numTerms;
	// Add the tax
	const totalDeductionsWithTax = totalDeductions * taxPercent;

	const balance = {
		renewal_premium: 0,
		total: 0,
		expiry_date: "",
	};

	// Calculate the amount due for the current payment

	balance.GCT = Math.abs(paymentTerm.payment_term_premium_with_tax - paymentTerm.payment_term_premium).toFixed(2);
	balance.renewal_premium = Math.max(paymentTerm.payment_term_premium - totalDeductions, 0);
	balance.total = Math.max(paymentTerm.payment_term_premium_with_tax - totalDeductionsWithTax, 0);
	balance.expiry_date = paymentTerm.expiry_date;

	return balance;
}




	export function totalBalanceCalculation(/* extendablePolicies, policy_id */ selectedPolicy) {

		/* const TAX_PERCENT = 1 + extendablePolicies[0]?.tax_percent / 100; */
		//Select specific policy
		/* let selectPolicy = extendablePolicies.filter(policy => policy.policy_id === policy_id)[0]; */

		const TAX_PERCENT = 1 + selectedPolicy?.tax_percent / 100;

		const arrayAfterRecalculation = recalculatePaymentPlans(
			"paybalance",
			selectedPolicy?.paymentPlan,
			selectedPolicy?.risks,
			TAX_PERCENT,
			selectedPolicy?.start_date
		);
		return calcExtensionTotalAlt(arrayAfterRecalculation, TAX_PERCENT);
	}
