import React, { useState } from "react";
import { AuthNetworkLayer, PolicyHelper } from "helpers";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setLoading, setLoadingMessage } from "globals/overlays";
import { Formik, Form, Field } from "formik";
import Modal from "components/misc/Modal";
import jwtDecoder from "jwt-decode";
import { Row, Col } from "reactstrap";
import "./otp.scss";

import {
	setRenewablePolicies,
	getUser,
	getToken,
	login,
	getUserFormData,
	getUserId,
	setCurrentUser,
	setPolicies,
	setExtendablePolicies,
	setCoverNotePolicies,
	getTransactionType,
	setActivePolicies,
	setHasMotorPolicies,
	setHasHomePolicies,
	setHasLiabilitiesPolicies,
	setToken,
} from "globals/auth";
import { getExtendablePoliciesPaymentPlan, getNewGlobalToken } from "helpers/auth";
import { PAY_BALANCE, RENEWAL } from "pages/RenewPolicy/costants";
import { ERROR } from "../Modal/constants";
import InfoIcon from "../InfoIcon";
import { useScreenType } from "globals/screenTypeHook";
import { clearPolicyManager } from "globals/policymanager";
import { setClaimsForTracking, setClaimsStatuses } from "globals/claims";
import { getAllClaimStatus, getClaims } from "api/dNet/Claims";

function validatePolicy(value) {
	let error;
	if (!value) {
		error = "Policy Number is required";
	} else if (!/^\d{1,7}$/g.test(value)) error = "Please enter a valid policy number";

	return error;
}

function validateLicense(value) {
	let error;
	if (!value) error = "License Number is required";
	else if (value.length < 5) error = "License Number is invalid";

	return error;
}

function validateChassisNumber(value) {
	let error;
	if (!value) error = "Chassis Number is required";
	else if (value.length < 5) error = "Chassis Number is invalid";

	return error;
}

const defaultFormValues = {
	policyNum: "",
	chassisNum: "",
	licenseNum: "",
	globalName: "",
};

function OTP({ isClaims = false, isTrackAClaim = false, isPayBalance = false, isPolicyManager = false }) {
	const transactionType = useSelector(getTransactionType);
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const userFormData = useSelector(getUserFormData);
	const userId = useSelector(getUserId);
	const token = useSelector(getToken);
	const [val, setVal] = useState("");
	const [open, openModal] = useState(false);
	const [modalError, setModalError] = useState("");
	const [currentMethod, setCurrentMethod] = useState("");
	const { isMobile } = useScreenType();

	const closeModal = () => {
		openModal(false);
	};

	const setValue = val => {
		setVal(val);
	};

	async function validateMaidenName(value) {
		let error;
		if (!value) return (error = "Mother's Maiden name is required");
		return error;
	}

	const checkPoliciesForDepartmentClass = (policies, departmentClass) => {
		return policies.some(policy => policy.department_class === departmentClass);
	};

	const getClaimStatus = async () => {
		try {
			const claimsStatuses = await getAllClaimStatus();
			dispatch(setClaimsStatuses(claimsStatuses.response));
		} catch (e) {
			console.log(e);
		}
	};

	const fetchClaims = async policies => {
		let i;
		const newClaimsArray = [];
		for (i = 0; i < policies?.length; i++) {
			dispatch(setLoadingMessage(`Loading policy details: ${policies?.length - (i + 1)} policies remaining`));
			const payload = {
				policy_id: policies[i]?.policy_id,
			};

			const retrievedClaims = await getClaims(payload);

			if (!retrievedClaims?.success) continue;

			newClaimsArray.push(...retrievedClaims.claims);
		}
		return newClaimsArray;
	};

	/**
	 * Handles the submission of the form
	 * @param {defaultFormValues} values
	 */
	const handleOtherSubmit = async values => {
		let hasMotorPolicies = false;
		let hasHomePolicies = false;
		let hasLiabilityPolicies = false;

		dispatch(setLoadingMessage("Authenticating User"));
		dispatch(setLoading(true));

		try {
			let response;
			let count = 2;
			let _token = token;

			while (count > 0) {
				response = await AuthNetworkLayer.alternateVerification(
					isClaims,
					isPolicyManager,
					userId, // change with the otp thing
					values?.licenseNum?.trim(),
					values?.policyNum?.trim(),
					values?.chassisNum?.trim(),
					userFormData?.national_id,
					values?.globalName?.trim(),
					transactionType,
					userFormData?.is_a_company,
					isTrackAClaim
				);

				if (!response.success && response.message?.includes("status code 401")) {
					_token = (await getNewGlobalToken(3)).token;
					--count;
				} else {
					count = -1;
				}
			}

			if (!response.success)
				throw new Error(
					"Oops! We do not have this information. Please contact our Telebranch at (888) OUR-AGIC (687-2442)"
				);

			/** @type {Object} */
			const data = jwtDecoder(response.data);

			let fetchedClaims = [];
			if (isTrackAClaim) {
				let statuses = await getClaimStatus();

				fetchedClaims = [...(await fetchClaims(data?.policies))];
			}

			if (fetchedClaims?.length) dispatch(setClaimsForTracking(fetchedClaims));

			// check for jwt
			const jwt_auth = process.env.REACT_APP_JWT_AUTHENTICATION;
			if (jwt_auth) {
				dispatch(setToken(data.token));
			}
			dispatch(setCurrentUser(data.user));

			hasMotorPolicies = checkPoliciesForDepartmentClass(data?.policies, "Motor");
			hasHomePolicies = checkPoliciesForDepartmentClass(data?.policies, "Fire");
			hasLiabilityPolicies = checkPoliciesForDepartmentClass(data?.policies, "Liability");

			dispatch(setHasMotorPolicies(hasMotorPolicies));
			dispatch(setHasHomePolicies(hasHomePolicies));
			dispatch(setHasLiabilitiesPolicies(hasLiabilityPolicies));

			const filteredPolicies = data.policies.filter(policy => policy.department_class !== "Liability");

			if (isClaims) {
				dispatch(setPolicies(PolicyHelper.filterForClaims(filteredPolicies)));
				isTrackAClaim ? history.push("/track-my-claim/claim-listing") : history.push("/claims/contact-info");
			} else if (transactionType === PAY_BALANCE) {
				dispatch(setPolicies(data.policies));
				let extendablePolices = await getExtendablePoliciesPaymentPlan(filteredPolicies, token);
				dispatch(setExtendablePolicies(PolicyHelper.filterForExtension(extendablePolices)));
				history.replace(`/${PAY_BALANCE}/contact-info` /* "/renewal/policy-listing" */);
			} else if (isPolicyManager) {
				//Updating of Home policies are not being facilitated right now.

				const hasHome = filteredPolicies.some(policy => policy.department_class !== "Fire");

				dispatch(setHasHomePolicies(hasHome));

				const filteredPoliciesForPolicyManager = filteredPolicies.filter(policy => policy.department_class !== "Fire");
				dispatch(setActivePolicies(PolicyHelper.findAllActiveAndRenewablePolicies(filteredPoliciesForPolicyManager)));

				dispatch(clearPolicyManager());
				history.push("/update-your-policy/policy-listing");
			} else {
				dispatch(setRenewablePolicies(PolicyHelper.filterForRenewal(filteredPolicies)));
				history.replace("/renewal/contact-info" /* "/renewal/policy-listing" */);
			}
			dispatch(login());
		} catch (e) {
			setModalError(e.message);
			openModal(true);
		} finally {
			dispatch(setLoading(false));
		}
	};

	const handleSubmit = async () => {
		dispatch(setLoading(true));

		try {
			if (currentMethod === "email" || currentMethod === "phone") {
				// generate token and send email
				// need it for here
				const choice = { method: currentMethod, contact: currentMethod === "phone" ? val : user.email_address };
				let response = await AuthNetworkLayer.sendOTP(
					`${user.first_name || user.last_name || userFormData.company_name}`,
					{ [currentMethod]: choice.contact },
					userId,
					userFormData.national_id,
					token
				);

				if (!response.success) throw new Error(response.message);
				if (isTrackAClaim) {
					history.push("/track-my-claim/otp-verification", choice);
				} else if (transactionType === RENEWAL) history.push("/renewal/otp-verification", choice);
				else if (transactionType === PAY_BALANCE) history.push("/paybalance/otp-verification", choice);
				else if (isPolicyManager) history.push("/update-your-policy/otp-verification", choice);
				else history.push("/claims/otp-verification", choice);
			}
		} catch (e) {
			setModalError(e.message);
			openModal(true);
		} finally {
			dispatch(setLoading(false));
		}
	};

	const setMethod = e => {
		setCurrentMethod(e.target.value);
	};

	return (
		<section className="otp-style m-auto constrain-medium">
			<div className="view">
				<Modal
					modalType={ERROR}
					title="Record Not Found"
					content={modalError}
					open={open ? true : false}
					primaryLabel="OK"
					primaryCallback={closeModal}
				/>

				<div className="opts">
					<p>For your own security, please verify your identify by using one of the options below.</p>
					{user && (
						<>
							{user.phone_numbers &&
								user.phone_numbers
									.filter(phone => phone.use_for_sms)
									.map((phone, i) => (
										<label
											style={{ display: "block", marginBottom: "1em", cursor: "no" }}
											className={`${currentMethod === " phone" ? "highlight " : ""}`}
											key={`${phone.value}${i}`}
										>
											<input
												value="phone"
												type="radio"
												onChange={e => {
													setMethod(e);
													setValue(phone.value);
												}}
												name="verification-method"
											/>
											{phone.carrier}: {phone.value}{" "}
											<InfoIcon label="A verification code will be sent to phone number." />
										</label>
									))}

							{user.email_address && user.email_address.length > 0 && (
								<label
									style={{ display: "block", marginBottom: "1em" }}
									className={currentMethod === "email" ? "highlight" : ""}
								>
									<input
										value="email"
										type="radio"
										onChange={e => {
											setMethod(e);
											setValue(e.target.value);
										}}
										name="verification-method"
									/>
									{user.email_address} <InfoIcon label="A verification code will be sent to email address." />
								</label>
							)}
							{isPolicyManager ? (
								<>
									<label
										style={{ display: "block", marginBottom: "1em" }}
										className={currentMethod === "other-reg-no" ? "highlight" : ""}
									>
										<input
											value="other-reg-no"
											onChange={e => {
												setMethod(e);
												setValue(e.target.value);
											}}
											type="radio"
											name="verification-method"
										/>
										Registration Plate{" "}
										<InfoIcon label="This option requires confirmation of your policy # and registration plate #." />
									</label>
									<label className={currentMethod === "other-chassis-no" ? "highlight" : ""}>
										<input
											value="other-chassis-no"
											onChange={e => {
												setMethod(e);
												setValue(e.target.value);
											}}
											type="radio"
											name="verification-method"
										/>
										Chassis Number{" "}
										<InfoIcon label="This option requires confirmation of your policy # and chassis #." />
									</label>
								</>
							) : !isTrackAClaim ? (
								<label className={currentMethod === "other" ? "highlight" : ""}>
									<input
										value="other"
										onChange={e => {
											setMethod(e);
											setValue(e.target.value);
										}}
										type="radio"
										name="verification-method"
									/>
									Other{" "}
									<span className="other">
										{!userFormData?.is_a_company && !isPolicyManager ? (
											<InfoIcon label="This option requires confirmation of your policy #, registration plate # and your mother's maiden name." />
										) : (
											<InfoIcon label="This option requires confirmation of your policy # and registration plate #." />
										)}{" "}
									</span>
								</label>
							) : (
								<></>
							)}
						</>
					)}
				</div>
				{currentMethod.includes("other") ? (
					<Formik initialValues={defaultFormValues} onSubmit={handleOtherSubmit}>
						{({ values, isSubmitting, submitCount, errors, touched, handleChange }) => {
							return (
								<Form className="form form__split form__indented form__margin">
									<Row>
										<Col md={6}>
											<label
												className={
													`otp-form-label form-label` +
													`${
														touched.policyNum && errors.policyNum
															? " error"
															: values.policyNum !== ""
															? " hasValue"
															: submitCount >= 1 && errors.policyNum
															? " hasError"
															: ""
													}`
												}
											>
												<div className="otp-title-container">
													<span className="field-title login-title">What is your Policy Number?</span>
													<span className="required"> * </span>
												</div>
												<Field
													name="policyNum"
													maxLength={7}
													type="text"
													onChange={e => {
														/^\d{1,7}$|^$/g.test(e.target.value)
															? handleChange("policyNum")(e)
															: console.log("letters input not accepted in this field");
													}}
													required
													validate={validatePolicy}
												/>
												{touched.policyNum && errors.policyNum && (
													<div className="error-message otp-message-styling">{errors.policyNum}</div>
												)}
											</label>
										</Col>
										<Col md={6}>
											{isPolicyManager ? (
												<>
													{val === "other-chassis-no" && (
														<label
															className={
																`otp-form-label form-label` +
																`${
																	touched.chassisNum && errors.chassisNum
																		? " error"
																		: values.chassisNum !== ""
																		? " hasValue"
																		: submitCount >= 1 && errors.chassisNum
																		? " hasError"
																		: ""
																}`
															}
														>
															<div className="otp-title-container">
																<span className="field-title login-title">What is your Chassis Number?</span>
																<span className="required"> * </span>
															</div>
															<Field
																name="chassisNum"
																required
																type="text"
																maxLength={20}
																validate={validateChassisNumber}
															/>
															{touched.chassisNum && errors.chassisNum && (
																<div className="error-message otp-message-styling">{errors.chassisNum}</div>
															)}
														</label>
													)}
													{val === "other-reg-no" && (
														<label
															className={
																`otp-form-label form-label` +
																`${
																	touched.licenseNum && errors.licenseNum
																		? " error"
																		: values.licenseNum !== ""
																		? " hasValue"
																		: submitCount >= 1 && errors.licenseNum
																		? " hasError"
																		: ""
																}`
															}
														>
															<div className="otp-title-container">
																<span className="field-title login-title">What is your License Plate Number?</span>
																<span className="required"> * </span>
															</div>
															<Field name="licenseNum" required type="text" maxLength={6} validate={validateLicense} />
															{touched.licenseNum && errors.licenseNum && (
																<div className="error-message otp-message-styling">{errors.licenseNum}</div>
															)}
														</label>
													)}
												</>
											) : (
												<label
													className={
														`otp-form-label form-label` +
														`${
															touched.licenseNum && errors.licenseNum
																? " error"
																: values.licenseNum !== ""
																? " hasValue"
																: submitCount >= 1 && errors.licenseNum
																? " hasError"
																: ""
														}`
													}
												>
													<div className="otp-title-container">
														<span className="field-title login-title">What is your License Plate Number?</span>
														<span className="required"> * </span>
													</div>
													<Field name="licenseNum" required type="text" maxLength={6} validate={validateLicense} />
													{touched.licenseNum && errors.licenseNum && (
														<div className="error-message otp-message-styling">{errors.licenseNum}</div>
													)}
												</label>
											)}
										</Col>
									</Row>
									<Row>
										{!userFormData?.is_a_company ? (
											<Col md={12}>
												{isClaims && user?.mother_maiden_name !== "" ? (
													<>
														<label
															className={
																`otp-form-label form-label` +
																`${
																	touched.globalName && errors.globalName
																		? " error"
																		: values.globalName !== ""
																		? " hasValue"
																		: submitCount >= 1 && errors.globalName
																		? " hasError"
																		: ""
																}`
															}
														>
															<div className="otp-title-container">
																<span className="field-title login-title">What is your Mother's Maiden Name?</span>
																<span className="required"> * </span>
															</div>
															<Field
																name="globalName"
																required
																type="text"
																maxLength={20}
																validate={validateMaidenName}
															/>
															{touched.globalName && errors.globalName && (
																<div className="error-message otp-message-styling">{errors.globalName}</div>
															)}
														</label>
														<div></div>
														<div></div>
													</>
												) : (
													<div></div>
												)}
											</Col>
										) : null}
									</Row>
									<Row>
										<Col md={12}>
											{
												/* Object.keys(errors).length === 0 &&
												values.licenseNum.length > 0 &&
												values.policyNum.length > 0 && */

												(isClaims && !userFormData?.is_a_company
													? values.policyNum.length >= 5 &&
													  values.licenseNum.length >= 5 &&
													  (user?.mother_maiden_name !== "" ? values.globalName.length !== 0 : true)
													: true) && (
													<div className={isMobile ? "text-center" : "text-right"}>
														<button
															className="button primary-theme-btn mt-4"
															type="submit"
															disabled={
																isSubmitting ||
																/* submitCount > 0 &&  */ Object.entries(errors).length > 0 ||
																values.policyNum.length < 5 ||
																isPolicyManager
																	? (val === "other-chassis-no" && values.chassisNum.length < 6) ||
																	  (val === "other-reg-no" && values.licenseNum.length < 5)
																	: values.licenseNum.length < 5
															}
															style={{ borderRadius: 7 }}
														>
															Submit
														</button>
													</div>
												)
											}
										</Col>
									</Row>
								</Form>
							);
						}}
					</Formik>
				) : currentMethod === "email" || currentMethod === "phone" ? (
					<div className="button-wrapper">
						<button
							className="button primary-theme-btn"
							onClick={handleSubmit}
							disabled={currentMethod.length === 0}
							style={{ borderRadius: 7 }}
						>
							Send
						</button>
					</div>
				) : null}
			</div>
		</section>
	);
}
export default OTP;
