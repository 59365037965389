import { checkExpToken } from "helpers/auth";
import { removeRatingCodesFromRisks } from "helpers/policy";

async function handleRenewalTransaction({
    user,
    policy,
    formatPolicyForRenewal,
    PolicyHelper,
    _transaction,
    paymentDetails,
    completedSteps,
    dispatch,
    setLoadingMessage,

    cart,
    promoCodes,
    policyNumber,
    renewalResult,
    getPaymentScheduleInfo,
    addPaymentReminder,
    files,
    responseAfterUpdate,
    activeRisk
    /* assignReceipt,
    drawReceipt,
    createLog,
    toggleReceiptErrorModalState,
    checkExpToken,
    transactionType,
    reference,
    billingDetails,
    error,
    currency,
    tmpToken,
    amount,
    toast,
    */
}) {
    dispatch(setLoadingMessage("Renewing And Updating Policy"));

    // get existing extensions
    let _extensions = policy.extensions;
    if (_transaction.current?.extensions !== undefined)
        _extensions.push(...(_transaction.current?.extensions || []));

    let { _cart, _manualExtensions, _policy } = formatPolicyForRenewal({
        policy,
        cart,
        extensions: _extensions,
        promoCodes,
    });

    const updatedPolicy = PolicyHelper.removeMortgagee({ ..._policy });



    /* 1. To be removed from this file and placed in payment Form file */
    // Create receipt in underwriter
    /* let receipt = assignReceipt(policy, _transaction.current.paymentAmount, paymentDetails.premiumPA_UM);
    let successful = false;
    if (!completedSteps.current.receipt) {
        let receiptResponse;
        let count = 0;

        try {
            while (count < 2 && !receiptResponse) {
                receiptResponse = await drawReceipt( receipt);
                count++;
            }
            if (!receiptResponse || !receiptResponse?.success) {
                toggleReceiptErrorModalState.openModal();
                successful = false;
            } else {
                completedSteps.current.receipt = receiptResponse;
                successful = true;
            }
        } catch (e) {
            toggleReceiptErrorModalState.openModal();
        } finally {
            createLog({
                trn: user?.national_id,
                type: `${transactionType}-Paymentform-DrawReceipt`,
                data: {
                    receiptNum: reference,
                    filesGenerated: files.map(f => f.filename),
                    receiptResponse,
                    Count: count,
                },
                errors: { message: error || !receiptResponse?.success ? receiptResponse?.error_message : "" },
            });
            try {
                if (!successful) {
                    await checkExpToken({
                        endpoint: "/email/quickpay",
                        payload: {
                            amount: `${amount.replace(/[, ]+/g, "").trim()}`,
                            receipt_number: `${reference}`,
                            name: `${billingDetails.firstName} ${billingDetails.lastName}`,
                            email: billingDetails.email,
                            phone: billingDetails.phoneNumber,
                            policy_number: policyNumber,
                            type: paymentDetails.paymentPurpose,
                            currency: currency,
                            successful: successful,
                        },
                     
                    });
                }
            } catch (e) {
                toast.error(e.message);
            }
        }
    } */
    /* 1. Ends here */

    if (!completedSteps.current.renewal?.success) {
        const newCodes = _transaction.current.codes;

        const vehicleOver5MillionMarketValueLimit = 5000000;

        const currentYear = new Date().getFullYear();

        const newSumInsured = activeRisk?.sum_insured;

        const ratingCodeToRemove = 702

        let _newRisks;
        let newlyUpdatedPolicy = {};
        if (newSumInsured < vehicleOver5MillionMarketValueLimit
            || currentYear - activeRisk?.year > 5) {
            const risks = [...[activeRisk]];

            _newRisks = removeRatingCodesFromRisks(risks, [ratingCodeToRemove]);

            if (_newRisks[0]?.rates)
                _newRisks[0] = [{ ..._newRisks[0], rates: _newRisks[0]?.rates.filter(rate => rate.code != ratingCodeToRemove) }];

            newlyUpdatedPolicy = { ...updatedPolicy, risks: _newRisks.flat() }
        }
        else {
            newlyUpdatedPolicy = updatedPolicy;
        }

        renewalResult = await PolicyHelper.renewAndUpdatePolicy(

            newlyUpdatedPolicy,
            user,
            newCodes,
            {
                total: _transaction.current.paymentAmount,
                paymentPlan: _transaction.current.paymentType,
                extensions: _transaction.current.extensions,
                premiumPA_UM: _transaction.current.premiumPA_UM,
            },
            completedSteps
        );

        completedSteps.current.renewal = renewalResult;
        if (!renewalResult?.success) throw new Error(`Failed to renew policy. ${renewalResult?.error_message}`);

    }

    dispatch(setLoadingMessage("Generating Policy Documents"));
    files.push(
        ...await PolicyHelper.generateDocuments(

            updatedPolicy,
            user,
            completedSteps.current.renewal,
            paymentDetails
        )
    );

    //@todo add specific addExtension function for additional benefits
    if (_transaction.current.paymentType?.payment_terms?.length > 1) {
        /** generate the payment schedule for renewal */
        let _terms = _transaction.current.paymentType?.payment_terms.map(term => ({ ...term, status: "-" }));
        _terms[0].status = "Paid";

        const scheduleResult = await getPaymentScheduleInfo(
            _terms,
            user,
            // @ts-ignore
            policy,


        );
        files.push({
            filename: "payment-schedule.pdf",
            fileContent: scheduleResult,
            // mime_type: "application/pdf",
        });

        let nextTerm = _transaction.current.paymentType?.payment_terms.find(term => !term.transaction_id);


        // if not full payment add reminder
        addPaymentReminder(
            {
                policynum: policyNumber,
                customer: `${user.first_name} ${user.last_name}`,
                email: `${user.email_address}`,
                phone: user.phone_numbers?.[0].phone_number,
                dueDate: nextTerm.effective_date,
                reminder_type: "paybalance",
            },

        );
    }
    responseAfterUpdate = { ...completedSteps.current.renewal };

    return renewalResult;
}






async function handlePayBalanceTransaction({
    dispatch,
    setLoadingMessage,
    completedSteps,
    policy,
    user,
    policyNumber,

    getPaymentScheduleInfo,
    _transaction,
    generateExtensionDocuments,
    extendPolicy,
    paymentScheduleData,
    isFuture,
    addPaymentReminder,
    removePaymentReminder,

    files,
    responseAfterUpdate,
    /* assignReceipt,
    paymentDetails,
    drawReceipt,
    toggleReceiptErrorModalState,
    createLog,
    transactionType,
    reference,
    error,
    amount,
    billingDetails,
    currency,
    tmpToken,
    checkExpToken,
    toast,
     */
}) {
    dispatch(setLoadingMessage("Extending Cover"));
    /*    let successful = false;
       if (!completedSteps.current.receipt || completedSteps.current.receipt.success === false) {
           let receipt = assignReceipt(policy, paymentDetails.paymentAmount);
           /** Generate receipt in underWriter /
   
           let receiptResponse;
           let count = 0;
   
           try {
               while (count < 2 && !receiptResponse) {
                   receiptResponse = await drawReceipt( receipt);
                   count++;
               }
               if (!receiptResponse || !receiptResponse?.success) {
                   toggleReceiptErrorModalState.openModal();
   
               } else {
                   completedSteps.current.receipt = receiptResponse;
                   successful = true;
               }
           } catch (e) {
               toggleReceiptErrorModalState.openModal();
           } finally {
               createLog({
                   trn: user?.national_id,
                   type: `${transactionType}-Paymentform-DrawReceipt`,
                   data: {
                       receiptNum: reference,
                       filesGenerated: files.map(f => f.filename),
                       receiptResponse,
                       Count: count,
                   },
                   errors: { message: error || !receiptResponse?.success ? receiptResponse?.error_message : "" },
               });
   
               try {
                   if (!successful) {
                       await checkExpToken({
                           endpoint: "/email/quickpay",
                           payload: {
                               amount: `${amount.replace(/[, ]+/g, "").trim()}`,
                               receipt_number: `${reference}`,
                               name: `${billingDetails.firstName} ${billingDetails.lastName}`,
                               email: billingDetails.email,
                               phone: billingDetails.phoneNumber,
                               policy_number: policyNumber,
                               type: paymentDetails.paymentPurpose,
                               currency: currency,
                               successful: successful,
                           },
                        
                       });
                   }
               } catch (e) {
                   toast.error(e.message);
               }
           }
       } */

    if (!completedSteps.current.paymentSchedule || completedSteps.current.paymentSchedule.success === false) {
        const scheduleResult = await getPaymentScheduleInfo(
            _transaction.current.paymentType?.payment_terms,
            user,
            // @ts-ignore
            policy,


        );
        if (!scheduleResult) {
            throw new Error(`Failed to generate Payment Schedule. ${scheduleResult?.message}`);
        }
        completedSteps.current.paymentSchedule = scheduleResult;
    }

    /** loop through extensions */
    for (let a = 0; a < _transaction.current.totalPayments; a++) {
        if (!completedSteps.current.extension?.[a] || completedSteps.current.extension?.[a]?.success === false) {
            let extensionResponse = await extendPolicy(

                policy,
                policy.paymentPlan,
                _transaction.current.paymentType?.payment_terms?.[1]?.payment_term_premium,
                user.national_id
            );

            completedSteps.current.extension[a] = extensionResponse;
            if (!extensionResponse?.success)
                throw new Error(`Could not extend policy. ${extensionResponse?.error_message}`);


            /** get documents */
            //console.warn( policy.policy_id, user.national_id, extensionResponse);
        }
        dispatch(setLoadingMessage("Generating Policy Documents"));
        files.push(
            ...await generateExtensionDocuments(

                policy,
                user.national_id,
                completedSteps.current.extension[a]?.transaction_id || ""
            )
        );
    }

    files.push({
        filename: "payment-schedule.pdf",
        fileContent: completedSteps.current.paymentSchedule,
        // mime_type: "application/pdf",
    });

    // set a payment reminder
    let nextTerm = paymentScheduleData.filter(terms => {
        let due = new Date(terms.date);
        let isFutureDate = isFuture(due);
        return terms.status !== "Paid" && isFutureDate;
    });


    //check if reminder exist and remove it
    removePaymentReminder({
        policy_number: policyNumber,
        reminder_type: "paybalance"
    })

    if (nextTerm.length > 0) {
        addPaymentReminder(
            {
                policynum: policyNumber,
                customer:
                    user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.company_name,
                email: `${user.email_address}`,
                phone: user.phone_numbers[0].phone_number,
                dueDate: nextTerm[0].date,
                reminder_type: "paybalance",
            },

        );
    }
    responseAfterUpdate = {};

    return completedSteps.current.extension;

}

async function handleAdditionalPremiumTransaction({
    policyFormInfo,
    activeRisk,
    changeMarketValue,

    policy,
    changeOnPremium,

    policyPaymentPlans,
    setError,
    openErrorModal2,
    user,
    PolicyNetworkLayer,
    completedSteps,
    /*    assignReceipt,
       paymentDetails,
       
       drawReceipt,
       toggleReceiptErrorModalState,
       createLog,
       transactionType,
       reference,
       error,
       checkExpToken,
       amount,
       billingDetails,
       policyNumber,
       currency,
       tmpToken, */
    dispatch,
    setLoadingMessage,
    toast,
    filesNeeded,
    FILESNEEDED,
    formatMoney,
    newPremium,
    policyManagerData,
    isNotEmpty,
    nodeApi,
    files
}) {
    const newSumInsured = policyFormInfo.newMarketValues.find(
        marketValue => marketValue?.riskID?.trim() === activeRisk?.risk_id
    )?.marketValue;
    let marketValueResult;
    let only_calculate_premium = false;
    try {

        const vehicleOver5MillionMarketValueLimit = 5000000;

        let _newRisks = [];
        let _policy = policy;

        const ratingCodeToRemove = 702;

        const currentYear = new Date().getFullYear();

        if (newSumInsured < vehicleOver5MillionMarketValueLimit
            || currentYear - activeRisk.year > 5) {
            let risks = [...[activeRisk]];

            _newRisks = removeRatingCodesFromRisks(risks, [ratingCodeToRemove]);

            _policy = {
                ...policy,
                risks: policy?.risks?.map(risk => risk?.risk_id === _newRisks[0]?.risk_id ?
                    _newRisks[0] : risk)
            }
        }

        marketValueResult = await changeMarketValue(

            _policy,
            _newRisks[0] ?? activeRisk,
            newSumInsured,
            changeOnPremium,
            policyPaymentPlans,
            only_calculate_premium,
        );

        completedSteps.current.additionalPremium = marketValueResult;
        if (marketValueResult?.error_message) throw marketValueResult?.error_message;
        
    } catch (error) {
        setError(error);
        openErrorModal2(true);
    }

    let vehiclesInfo = [];
    let userName = user.is_a_company ? user.company_name : user?.first_name + " " + user?.last_name;
    let customerInfo = {
        name: userName,
        phoneNumber: { wasChanged: false, value: "" },
        otherPhoneNumber: {
            wasChanged: false,
            value: "",
        },
        email: { wasChanged: false, value: user.email_address },
        homeAddress: { wasChanged: false, value: "" },
    };

    let transScheduleResponse = await PolicyNetworkLayer.getTransactionSchedule(

        marketValueResult.transaction_id
    );
    files.push({ ...transScheduleResponse.data, type: "Transaction Schedule" });
    let driversInfo = [];
    /*    let receipt = assignReceipt(policy, paymentDetails.paymentAmount);
       let successful = false;
   
       if (!completedSteps.current.receipt || completedSteps.current.receipt.success === false) {
           /** Generate receipt in underWriter /
           let receiptResponse;
           let count = 0;
           try {
               while (count < 2 && !receiptResponse) {
                   receiptResponse = await drawReceipt( receipt);
                   count++;
               }
               if (!receiptResponse || !receiptResponse?.success) {
                   toggleReceiptErrorModalState.openModal();
                   successful = false;
               } else {
                   completedSteps.current.receipt = receiptResponse;
                   successful = true;
               }
           } catch (e) {
               toggleReceiptErrorModalState.openModal();
           } finally {
               createLog({
                   trn: user?.national_id,
                   type: `${transactionType}-Paymentform-DrawReceipt`,
                   data: {
                       receiptNum: reference,
                       filesGenerated: files.map(f => f.filename),
                       receiptResponse,
                       Count: count,
                   },
                   errors: { message: error || !receiptResponse?.success ? receiptResponse?.error_message : "" },
               });
   
               try {
                   await checkExpToken({
                       endpoint: "/email/quickpay",
                       payload: {
                           amount: `${amount.replace(/[, ]+/g, "").trim()}`,
                           receipt_number: `${reference}`,
                           name: `${billingDetails.firstName} ${billingDetails.lastName}`,
                           email: billingDetails.email,
                           phone: billingDetails.phoneNumber,
                           policy_number: policyNumber,
                           type: paymentDetails.paymentPurpose,
                           currency: currency,
                           successful: successful,
                       },
                       
                   });
               } catch (e) {
                   toast.error(e.message);
               }
           }
       } */

    let selectedRisk = policy.risks.find(risk => risk.risk_id === policyFormInfo.newMarketValues[0].riskID);
    // create certificate
    dispatch(setLoadingMessage("Creating Certificate"));
    let certResponse = await PolicyNetworkLayer.createCertificate(policy?.policy_id, selectedRisk.risk_id);
    let file;
    if (certResponse?.success) {
        // get/save certificate data
        file = certResponse.data;
    } else {
        toast.error(`Create Certificate failed ${certResponse.error_message}`);
    }

    files.push({ ...file, type: "Certificate of Insurance" });
    if (policyFormInfo?.newMarketValues[0]?.files?.length === 0) {
        filesNeeded.push(FILESNEEDED.Valuation);
    }

    let vehicleInfo = {
        make: selectedRisk.make,
        model: selectedRisk.model,
        year: selectedRisk.year,
        registrationNumber: {
            wasChanged: false,
            value: selectedRisk.registration_number || "",
        },
        marketValue: {
            wasChanged: true,
            value: formatMoney(policyFormInfo.newMarketValues[0].marketValue).toString(),
        },
        premium: { wasChanged: true, value: formatMoney(newPremium).toString() },
        files: files,
        filesNeeded: filesNeeded,
    };

    vehiclesInfo.push(vehicleInfo);

    policyManagerData = {
        fullname: user.is_a_company ? `${user.company_name}` : `${user.first_name} ${user.last_name}`,
        email: isNotEmpty(policyFormInfo?.newEmail?.trim()) ? policyFormInfo?.newEmail?.trim() : user.email_address,
        registrationNumbers: [],
        certificates: files,
    };

    let emailResult = await checkExpToken({
        endpoint: "/email/policyManager", payload: {
            customerInfo,
            vehiclesInfo,
            driversInfo,
        }
    });

    if (emailResult.success) {
        dispatch(setLoadingMessage("Sending email"));
        toast.success("An email has been sent with an outline of all your changes.");
    }
    if (policyFormInfo?.newMarketValues[0]?.files?.length === 0) {
        await checkExpToken({
            endpoint: "/email/policyManagerReminder", payload: {
                customerInfo,
                vehiclesInfo,
            }
        });
    }

    return marketValueResult;
}

export {
    handleRenewalTransaction,
    handlePayBalanceTransaction,
    handleAdditionalPremiumTransaction,
};