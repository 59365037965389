import { TRANSWORDINGS } from "pages/PolicyManager/constants";
import { PolicyNetworkLayer } from "helpers";

import { APPLICABLE_RIDERS, getAdditionalBenefits } from "helpers/policy";
import { format } from "date-fns";

export default async function changeMarketValue(
	policy,
	risk,
	marketValue,
	premiumChange,
	policyPaymentPlans,
	only_calculate_premium
) {
	if (marketValue === risk.sum_insured) return;

	const startDate = getStartDate(policy);

	let endDate = getEndDate(policy, policyPaymentPlans);

	const action = marketValue > risk.sum_insured ? "risk_increase_sum_insured" : "risk_decrease_sum_insured";

	let value = getTransWording(action, premiumChange, marketValue, risk);

	let _policy = { ...policy };
	let _risks = [..._policy.risks];

	//update sum_insured for risk
	_risks = _risks.map(_risk => {
		let tempRisk = { ..._risk };
		if (_risk.risk_id === risk.risk_id) {
			tempRisk.sum_insured = marketValue;
		}
		return tempRisk;
	});
	//update policy with updated risks
	_policy = {
		..._policy,
		risks: _risks,
	};

	let batch_actions = [];

	//update risk extensions and riders
	_policy = await updateExtensions(_policy, risk);
	let _batch_actions = await updateBatchActions(_policy, policy, batch_actions);

	_batch_actions.push({
		action: action,
		parameters: {
			risk_id: risk.risk_id,
			chassis_number: risk.chassis_number,
			risk_item_type: "Vehicle",
			sum_insured: marketValue,
		},
	});

	_risks = [..._policy.risks];

	console.log("risks", _risks);

	const transaction_premium = only_calculate_premium ? 0 : value.includes("refund") ? 0 : premiumChange;

	const changesumInsuredArgumentsObject = {
		policy: _policy,
		startDate: startDate,
		endDate: endDate,
		trans_wording: value,
		risks: _risks,
		batch_actions: _batch_actions,
		only_calculate_premium: only_calculate_premium,
		transaction_premium,
	};
	console.log(changesumInsuredArgumentsObject);

	let result;
	result = await PolicyNetworkLayer.batchPolicyModification({ ...changesumInsuredArgumentsObject });

	return result;
}

export function getTransWording(action, premiumChange, marketValue, risk) {
	let value;

	if (premiumChange > 0 && action === "risk_increase_sum_insured") {
		value = TRANSWORDINGS.IncreasedAdditional;
	} else if (premiumChange > 0 && action === "risk_decrease_sum_insured") {
		value = TRANSWORDINGS.DecreasedAdditional;
	} else if (premiumChange <= 0 && action === "risk_increase_sum_insured") {
		value = TRANSWORDINGS.IncreasedRefund;
	} else if (premiumChange <= 0 && action === "risk_decrease_sum_insured") {
		value = TRANSWORDINGS.DecreasedRefund;
	}
	return value;
}
export function getEndDate(policy, policyPaymentPlans) {
	let endDate = policy.end_date;

	if (policyPaymentPlans?.payment_terms && Object.entries(policyPaymentPlans?.payment_terms).length > 0)
		endDate = policyPaymentPlans.payment_terms.findLast(term => term.transaction_id).expiry_date;

	return endDate;
}
export function getStartDate(policy) {
	let start_date;

	const secondsInMinute = 60;

	const minutesInHour = 60;

	const hoursInDay = 24;

	const timeDiff =
		(new Date().getTime() - new Date(policy.start_date).getTime()) /
		(1000 * secondsInMinute * minutesInHour * hoursInDay);

	const maxPolicyDaysElapsedFromInception = 7;

	const today = format(new Date(), "MM/dd/yyyy H:mm:ss");

	start_date = timeDiff > maxPolicyDaysElapsedFromInception ? today : policy.start_date;

	return start_date;
}

export async function updateBatchActions(_policy, policy, batch_actions) {
	let old_extensions = [...policy.extensions.filter(x => x.type === "").map(x => x.extension_code)];
	let new_extensions = [..._policy.extensions.map(x => x.extension_code)];
	let _batch_actions = [...batch_actions];

	if (JSON.stringify(old_extensions) !== JSON.stringify(new_extensions)) {
		let removed_extensions = await removeExtensions(new_extensions, old_extensions);
		removed_extensions && _batch_actions.push(removed_extensions);
		let added_extensions = await addExtensions(new_extensions, old_extensions);
		added_extensions && _batch_actions.push(added_extensions);
	}

	return _batch_actions;
}

export async function addExtensions(new_extensions, old_extensions) {
	let added_extensions = new_extensions.filter(extension => !old_extensions.includes(extension));
	let _extensions = [];
	let action;

	if (added_extensions.length !== 0) {
		for (let i = 0; i < added_extensions.length; i++) {
			let _extension = {
				extension_code: added_extensions[i],
			};
			_extensions.push(_extension);
		}
		console.log(_extensions);

		let updatedAction = {
			action: "add_extensions",
			parameters: {
				extensions: _extensions,
			},
		};
		action = { ...updatedAction };

		console.log(updatedAction);
	}
	return action;
}

export async function removeExtensions(new_extensions, old_extensions) {
	let removed_extensions = old_extensions.filter(extension => !new_extensions.includes(extension));

	console.log(removed_extensions);
	let _extensions = [];
	let action;

	if (removed_extensions.length !== 0) {
		for (let i = 0; i < removed_extensions.length; i++) {
			_extensions.push(removed_extensions[i]);
		}
		console.log(_extensions);

		let updatedAction = {
			action: "remove_extensions",
			parameters: {
				extension_codes: _extensions,
			},
		};
		action = { ...updatedAction };
		console.log(action);
	}
	return action;
}

export async function updateExtensions(_policy, _risk) {
	const currency = "JA";

	let addBenefits = await getAdditionalBenefits(_policy);
	let exts = [],
		tiers = [],
		rates = [];
	let manual_rates = _policy?.risks?.find(risk => _risk.risk_id === risk.risk_id)?.manual_rates;

	addBenefits.benefits.map(rBenefits => {
		let { benefits } = rBenefits;

		benefits.map(b =>
			b.tiers.map(ti => {
				// find already existing rates on policy
				let found =
					manual_rates.findIndex(rate => {
						let benefit_tier_on_policy = rate.code === ti.code;
						let benefit_alternate_on_policy =
							APPLICABLE_RIDERS.um_rider.findIndex(t => {
								// this tier is UM?
								return t === ti.code;
							}) > -1 &&
							APPLICABLE_RIDERS.um_rider.findIndex(t => {
								// is another UM about in the rates?
								return t === rate.code;
							}) > -1;

						return benefit_tier_on_policy || benefit_alternate_on_policy;
					}) > -1;
				// when found apply rates to policy
				if (
					_risk.risk_id === rBenefits.risk_id &&
					found &&
					rates.findIndex(ra => ra.rate_code_description == b.rate_code_description) == -1
				) {
					// get Rates
					rates.push({
						category: b.category,
						category_position: b.category_position,
						code: ti.code,
						is_blocked: false,
						limit: b.limit,
						position: b.position,
						premium: ti.premium,
						rate_code_description: b.rate_code_description,
						type: b.type,
						value: ti.premium,
					});
					tiers.push(ti);
				}
			})
		);
		tiers.map(t =>
			t.manual_extension.map(e => {
				if (_risk.risk_id === rBenefits.risk_id) {
					exts.push(e);
				}
			})
		);
	});
	console.log("Extentions", exts);

	// update policy with risk new extensions
	_policy = {
		..._policy,
		extensions: exts,
		risks: _policy.risks.map(r => {
			if (_risk.risk_id === r.risk_id)
				r = {
					...r,
					is_manual_rating: false,
					rate_handling: "renewal_rates",
					//manual_rates:[r.manual_rates]
				};

			return r;
		}),
	};
	console.log("Name", _policy);

	return _policy;
}

/**
 * Formats the information entered into a readable address
 * @param {Object} address
 * @param {Object} [address.addressLine1]
 * @param {String=} address.addressLine1.buildingNumber
 * @param {String=} address.addressLine1.complexName
 * @param {String=} address.addressLine1.buildingLabel
 * @param {String=} address.addressLine1.unitNumber
 * @param {Object} [address.addressLine2]
 * @param {String=} address.addressLine2.streetNumber
 * @param {String=} address.addressLine2.streetName
 * @param {String=} address.addressLine2.streetType
 * @param {Object} [address.area]
 * @param {Boolean} address.area.isInternationalAddress
 * @param {String=} address.area.generalArea
 * @param {String=} address.area.internationalArea
 * @param {String=} address.town
 * @param {String=} address.parish
 * @param {String=} address.zipCode
 * @param {String=} address.country
 *
 */
export const getFormattedAddress = address => {
	const addressLine1Empty =
		Object.values(address.addressLine1 || {}).filter(val => {
			return val.length;
		}).length === 0;
	const addressLine2Empty =
		Object.values(address.addressLine2 || {}).filter(val => {
			return val.length;
		}).length === 0;
	const areaEmpty =
		Object.values(address.area || {}).filter(val => {
			if (typeof val !== "boolean") {
				return val.length;
			}
		}).length === 0;
	const fullAddress = `${
		!addressLine1Empty
			? (address.addressLine1.buildingNumber ? " " + address.addressLine1.buildingNumber : "") +
			  (address.addressLine1.complexName ? " " + address.addressLine1.complexName : "") +
			  (address.addressLine1.buildingLabel ? " " + address.addressLine1.buildingLabel : "") +
			  (address.addressLine1.unitNumber ? " Unit " + address.addressLine1.unitNumber : "")
			: ""
	}${
		!addressLine2Empty
			? (!addressLine1Empty ? "," : "") +
			  (address.addressLine2.streetNumber ? " " + address.addressLine2.streetNumber : "") +
			  (address.addressLine2.streetName ? " " + address.addressLine2.streetName : "") +
			  (address.addressLine2.streetType ? " " + address.addressLine2.streetType : "")
			: ""
	}${
		!areaEmpty
			? address.area.isInternationalAddress === false
				? address.area.generalArea
					? (!addressLine1Empty || !addressLine2Empty ? "," : "") + " " + address.area.generalArea
					: ""
				: address.area.internationalArea
				? (!addressLine1Empty || !addressLine2Empty ? "," : "") + " " + address.area.internationalArea
				: ""
			: ""
	}${address.town ? (!addressLine1Empty || !addressLine2Empty || !areaEmpty ? "," : "") + " " + address.town : ""}${
		address.parish
			? (!addressLine1Empty || !addressLine2Empty || !areaEmpty || address.town ? "," : "") + " " + address.parish
			: ""
	}${
		address.zipCode
			? (!addressLine1Empty || !addressLine2Empty || !areaEmpty || address.town || address.parish ? "," : "") +
			  " " +
			  address.zipCode
			: ""
	}${
		address.country
			? (!addressLine1Empty || !addressLine2Empty || !areaEmpty || address.town || address.parish || address.zipCode
					? ","
					: "") +
			  " " +
			  address.country
			: ""
	}`;
	return fullAddress;
};

/**
 * Checks for any changes made to the policy information:
 * If there are any changes it sets to totalPolicyFormChanges state the total number of changes
 * If there are any changes to the address on the contact information tab it sets the isAddressInfoDirty to true
 * @param {Object} tempPolicyFormInfo
 * @param {Array} tempEditedAddressFields
 * @param {Boolean=} isEdited
 * @returns {{addressChanged: Boolean, totalChanges: Number}}
 */
export const checkForPolicyFormInfoChanges = (tempPolicyFormInfo, tempEditedAddressFields, isEdited = false) => {
	/**Keys in PolicyFormInfo not related to the user's address */
	const nonAddressKeyNames = [
		"newEmail",
		"phoneNumber",
		"otherPhoneNumber",
		"newMarketValues",
		"newRegistrationNumbers",
	];

	let totalChanges = 0;
	let totalAddressChanges = 0;
	const { proofOfAddress, proofOfAddressType, valuationFiles, ...rest } = tempPolicyFormInfo;
	tempPolicyFormInfo = { ...rest };
	/**This ForEach loop checks the registration numbers for changes based on the current policy ID
	 *then checks for Contact Information Changes
	 *then Home Address information changes specifically*/
	Object.keys(tempPolicyFormInfo).forEach(key => {
		if (
			tempPolicyFormInfo[key].length > 0 ||
			(tempEditedAddressFields.indexOf(key) !== -1 && key !== "otherPhoneNumber")
		) {
			if (nonAddressKeyNames.indexOf(key) === -1) {
				totalAddressChanges += 1;
			}
			totalChanges += 1;
		} else if (key === "otherPhoneNumber" && isEdited) {
			totalChanges += 1;
		}
	});

	if (totalAddressChanges > 0) {
		return { addressChanged: true, totalChanges: totalChanges };
	} else {
		return { addressChanged: false, totalChanges: totalChanges };
	}
};
