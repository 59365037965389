import React, { Suspense } from "react";
import Loader from "Loader";

/**
 *
 * @param {React.LazyExoticComponent<React.ComponentType<*>>} LazyComponent
 * @param {String|JSX.Element=} fallback
 */
const withSuspense = (LazyComponent, fallback) => {
	return props => (
		<Suspense fallback={fallback === undefined ? Loader : fallback}>
			<LazyComponent {...props} />
		</Suspense>
	);
};

export default withSuspense;
