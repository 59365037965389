const MARKETVALUEMINIMUM = {
	MPCC: 400001,
	MPCCS: 400001,
	MCOC: 400001,
	MCOCS: 400001,
	MCPC: 400001,
	MUWC: 2500000,
	MUWCS: 2500000,
	MPRC: 3000000,
	MPRCS: 3000000,
	MLXC: 6000000,
	MLXCC: 6000000,
	MPPC: 500000,
	MPPCB: 500000,
	MPPCS: 500000,
	MPPCBS: 500000,
	MPPTF: 500000,
};

const ADDITIONALPREMIUMMINIMUM = {
	MPCCS: 2000,
	MCOCS: 2000,
	MPRC: 2000,
	MPRCC: 2000,
	MUWC: 2000,
	MUWCC: 2000,
	MLXC: 2000,
	MLXCC: 2000,
	MMCCS: 2000,
	MPCCN: 2000,
	MPCC: 2500,
	MCOC: 2500,
	MPCTF: 2500,
	MCOTF: 2500,
	MCPC: 2500,
	MPPC: 10000,
	MPPCB: 10000,
	MPPCS: 10000,
	MPPCBS: 10000,
	MPPTF: 10000,
};

const TRANSWORDINGS = {
	DecreasedRefund: "Decrease Sum Insured - Refund Premium U.",
	DecreasedAdditional: "Decrease Sum Insured -Add't Premium U.",
	IncreasedRefund: "Increase Sum Insured - Refund U.",
	IncreasedAdditional: "Increase Sum Insured - Add't Premium U.",
};

const FILESNEEDED = {
	Valuation: "A Valuation Report (including photographs of the vehicle) for all comprehensive policies",
	Registration: "Motor Vehicle Registration",
};

export { MARKETVALUEMINIMUM, ADDITIONALPREMIUMMINIMUM, TRANSWORDINGS, FILESNEEDED };
