import { checkKey } from "helpers/auth";

export const getCertificate = async ( policy_id, risk_id) => {
	let cert = {};
	let payload = {
		policy_id: policy_id,
		risk_id: risk_id,
	};

	let endpoint = `/getCertificate`;

	cert = await checkKey({ endpoint, payload });

	return cert;
};

export const getCoverNote = async ( policy_id, risk_id) => {
	let data = {};

	let payload = {
		policy_id: policy_id,
		risk_id: risk_id,
	};

	let endpoint = `/getCoverNote`;
	data = await checkKey({ endpoint, payload });
	return data;
};

export const createCertificate = async ( policy_id, risk_id) => {
	let data = {};
	const endpoint = `/createCertificate`;
	const payload = {
		//
		policy_id,
		risk_id,
	};
	//const reqType = "get";

	data = await checkKey({ endpoint, payload });

	return data;
};
