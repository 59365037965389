import { createSlice } from "@reduxjs/toolkit";
import { fullLogout } from "globals/misc";

const defaultState = {
	notification: {
		type: "",
		message: "",
		visible: false,
	},
	loading: {
		visible: false,
		message: "",
	},
};

let overlaySlice = createSlice({
	name: "overlay",
	initialState: defaultState,
	reducers: {
		/** @param {{payload: Boolean}} action */
		setOverlays: (state, action) => {
			state.loading.visible = action.payload;
			state.notification.visible = action.payload;
		},

		/** @param {{payload: { message: String, type: String}}} action */
		setNotification: (state, action) => {
			state.notification.visible = true;
			state.notification.message = action.payload.message;
			state.notification.type = action.payload.type;
		},

		/** @param {{payload: Boolean}} action */
		setLoading: (state, action) => {
			state.loading.visible = action.payload;
			if (!action.payload) state.loading.message = "";
		},

		/** @param {{payload: String}} action */
		setLoadingMessage: (state, action) => {
			state.loading.message = action.payload;
		},

		/** @param {{payload: Boolean}} action */
		hideNotification: (state, action) => {
			state.notification.visible = action.payload;
		},

		resetOverlays: () => defaultState,
	},
	extraReducers: builder => {
		builder.addCase(fullLogout, (state, action) => defaultState);
	},
});

/** @param {{overlay: defaultState}} state */
export const isLoaderVisible = ({ overlay: state }) => state.loading.visible;

/** @param {{overlay: defaultState}} state */
export const isNotificationVisible = ({ overlay: state }) => state.notification.visible;

/** @param {{overlay: defaultState}} state */
export const getLoaderMessage = ({ overlay: state }) => state.loading.message;

export const { setOverlays, setNotification, setLoading, resetOverlays, setLoadingMessage, hideNotification } =
	overlaySlice.actions;

export default overlaySlice.reducer;
