import React, { useRef, useEffect, useState, useMemo } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { setPaymentResponse } from "globals/payments";
import { Formik, Form, Field } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import { object, string } from "yup";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import { getUser, getAuthState, getTransactionType, getPolicyHolders } from "globals/auth";
import Card from "./Card";
import { Row, Col } from "reactstrap";

import "./BillingForm.scss";
import CustomCheckbox from "components/CustomCheckbox";
import { lastDayOfMonth } from "date-fns";
import { InfoIcon } from "components";
import { QUICK_PAY } from "pages/RenewPolicy/costants";
import * as Yup from 'yup';
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";

const gatewayURL = process.env.REACT_APP_GATEWAY_URL;
const jQueryURL = "https://code.jquery.com/jquery-1.7.2.min.js";

/** Possible form states */
const transactionStates = {
	ERROR: "error",
	DEFAULT: "default",
	LOADING: "loading",
	SUCCESS: "success",
};

/** Initializers for form state */
const defaults = {
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
	billingAddress: "",
	country: "Jamaica",
	region: "",
	zip_code: "",
	cardNumber: "",
	cardName: "",
	cardExpiryMonth: "",
	cardExpiryYear: "",
	cardCVC: "",
	sameAddress: false,
};

/** Schema using the Yup library for easier field validation */
const billingFormSchema = Yup.object({
	firstName: Yup.string().required("Please enter your first name"),
	lastName: Yup.string().required("Please enter your last name"),
	email: Yup.string().required("Please enter your email address").email().trim(),
	phoneNumber: Yup.string()
		.required("Please enter your phone number")
		.matches(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g, "Phone Number format is invalid"),
	billingAddress: Yup.string().required("Please enter your billing address"),
	country: Yup.string().required("Please choose your card's billing country"),
	region: Yup.string().when("country", {
		is: country => country === "Jamaica",
		then: Yup.string().required("Please choose your parish"),
	}),
	sameAddress: Yup.boolean(),
	zip_code: Yup.string(),
	cardName: Yup.string().required("Please enter the name of the cardholder"),
	//cardNumber: Yup.string().required("Card Number required").matches(/\d+/g, "Entry should be a valid number").length(19, "Invalid credit card number"),
	cardExpiryMonth: Yup.string()
		.required("Please enter your card's expiry date")
		.matches(/(0[1-9]|1[0-2])/g, `Invalid month entered, please enter a valid month from 01-12`),
	cardExpiryYear: Yup.string()
		.required("Please enter your card's expiry date")
		.matches(/\d{2}/g, `Invalid year entered, please use the last two digits of the year`)
		.test("date-not-expired", `Card is invalid or expired. Please provide a valid date.`, checkValidDate),
	//cardCVC: Yup.string().required("Please enter a valid CVC/CVV").max(4, "Please enter a valid CVC/CVV"),
});

const phoneNumberMask = ["(", /\d/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
const creditCardMask = [
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	"-",
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	"-",
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	"-",
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];

/**
 * Checks to ensure a valid date is provided
 * @param {String} year
 */
function checkValidDate(year) {
	if (!this.parent.cardExpiryMonth) return false;

	const dateEntry = lastDayOfMonth(new Date(parseInt(`20${year}`), this.parent.cardExpiryMonth - 1)); //Month is 0-indexed
	const currentDate = new Date();
	if (dateEntry < currentDate) return false;

	return true;
}

/** Default form state */
const defaultFormStatus = { transactionState: transactionStates.DEFAULT };

/**
 * TODO: Add to this later
 * @param {Object} props
 */
function BillingDetails({ setBillingDetails, showPaymentModal, showError, transaction }) {
	const dispatch = useDispatch();
	const user = useSelector(getUser, shallowEqual);
	const policyHolders = useSelector(getPolicyHolders, shallowEqual);
	const isLoggedIn = useSelector(getAuthState, shallowEqual);
	const [isHomeAdress, setIsAddress] = useState(false);
	const history = useHistory();
	const transactionType = useSelector(getTransactionType, shallowEqual);
	const formDefaults = useRef(defaults);
	/** ref is necessary to prevent re-render bug: text getting removed */
	const cardType = useRef("");
	const cardNumber = useRef(null);
	const cardName = useRef(null);
	const cardCVC = useRef(null);
	const [cardNumburDirty, setCardNumburDirty] = useState(false);
	const [cvcDirty, setCVCDirty] = useState(false);
	const [cardFieldsDirty, setCardFieldsDirty] = useState(false)

	useEffect(() => {
		if (cardNumburDirty && cvcDirty) setCardFieldsDirty(true);
	}, [cardNumburDirty, cvcDirty]);

	const handleInputChange = (selector) => {

		if (selector === "#cardCVC") setCVCDirty(true);
		if (selector === "#cardNumber") setCardNumburDirty(true);
	};
	/** Initializers for form state */
	const defaultFormValues = useMemo(() => {
		if (transactionType !== QUICK_PAY)
			return {
				...formDefaults.current,
				firstName: transaction.firstName !== null ? transaction.firstName : "",
				lastName: transaction.lastName !== null ? transaction.lastName : "",
				email:
					transactionType === QUICK_PAY && formDefaults.current.email !== null
						? formDefaults.current.email
						: transactionType !== null
							? user.email_address !== null && user.email_address !== undefined
								? user.email_address
								: ""
							: "",
				region:
					transactionType === QUICK_PAY && formDefaults.current.region !== null ? formDefaults.current.region : "",
				phoneNumber:
					transactionType === QUICK_PAY && formDefaults.current.phoneNumber !== null
						? formDefaults.current.phoneNumber
						: "",
				billingAddress:
					transactionType === QUICK_PAY && formDefaults.current.billingAddress !== null
						? formDefaults.current.billingAddress
						: "",
				fromRenewalFlow: transaction.policyNumber === null ? false : true,
			};
		else
			return {
				...formDefaults.current,
				fromRenewalFlow: false,
			};
	}, [transaction, transactionType, user]);

	const [scriptLoaded, setScriptLoaded] = useState(
		typeof window !== "undefined" && typeof window["myScript"] !== "undefined"
	);

	/**
	 * Handles script loading
	 * @param {*} _
	 * @param {*} addedTags
	 */
	const handleScriptLoadState = (_, addedTags) => {
		if (addedTags && addedTags.scriptTags) {
			const foundScript = addedTags.scriptTags.find(({ src }) => src === gatewayURL);
			if (foundScript) {
				foundScript.addEventListener("load", () => setScriptLoaded(true), { once: true });
			}
		}
		window.PaymentSession?.onFocus(['card.number'], function (selector) {
			handleInputChange(selector);
		});
		window.PaymentSession?.onFocus(['card.securityCode'], function (selector) {
			handleInputChange(selector);
		})
	};

	/**
	 * Handles the submission of the form
	 * @param {defaultFormValues} values
	 */
	async function handleFormSubmit(values) {
		setBillingDetails(values);
		window.PaymentSession.updateSessionFromForm("card");
	}

	/**
	 * Additional name validation if coming from renewal flow
	 * @param {String} value
	 */
	function validateName(value) {
		let error;
		if (Object.keys(user).length === 0 || policyHolders?.length === 0) return error;
		let _value = value.toLowerCase().trim();

		// dont validate if user is a corporate client
		if (user?.is_a_company) return;

		const foundPolicyHolder = policyHolders.find(policyHolderObject => {

			const policyHolder = policyHolderObject.global_name;

			if (_value === `${policyHolder?.first_name?.toLowerCase().trim()} ${policyHolder?.last_name?.toLowerCase().trim()}`)
				return policyHolder;
		});

		if (!foundPolicyHolder)
			error = "Name does not match Policy Holder";

		return error;
	}

	useEffect(() => {
		if (scriptLoaded) {
			if (window.self === window.top) {
				let antiClickjack = document.getElementById("antiClickjack");
				if (antiClickjack) antiClickjack.parentNode.removeChild(antiClickjack);
			} else {
				window.top.location = window.self.location;
			}

			window.PaymentSession.configure({
				fields: {
					// ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
					card: {
						number: "#cardNumber",
						securityCode: "#cardCVC",
						expiryMonth: "#cardExpiryMonth",
						expiryYear: "#cardExpiryYear",
					},
				},
				//SPECIFY YOUR MITIGATION OPTION HERE
				frameEmbeddingMitigation: ["javascript"],
				callbacks: {
					initialized: function (response) {
						// HANDLE INITIALIZATION RESPONSE
					},
					formSessionUpdate: function (response) {
						// HANDLE RESPONSE FOR UPDATE SESSION
						if (response.status) {
							if (response.status === "ok") {
								//("Session updated with data: " + response.session.id, response);
								dispatch(setPaymentResponse({ session: response.session, sourceOfFunds: response.sourceOfFunds }));
								showPaymentModal(true);

								//check if the security code was provided by the user
								if (response.sourceOfFunds.provided.card.securityCode) {
								}

								if (response.sourceOfFunds.provided.card.number) {
								}

								//check if the user entered a MasterCard credit card
								if (response.sourceOfFunds.provided.card.scheme === "MASTERCARD") {
								}
							} else if ("fields_in_error" === response.status) {
								const error = Object.keys(response.errors).reduce(
									(errorStr, label) =>
										`${label.toLowerCase()} is ${response.errors[label]}${errorStr.length ? `, ${errorStr}` : ""}`,
									""
								);
								showError(`Oops! Unable to process payment: '${error}'`);
							} else if ("request_timeout" === response.status) {
								showError(`Oops! Unable to process payment. 'Took too long'`);
							} else if ("system_error" === response.status) {
								showError(`Oops! Unable to process payment. 'A problem arose.'`);
							}
						} else {
						}
					},
				},
			});
			setScriptLoaded(false);
		}
	}, [scriptLoaded, dispatch, showPaymentModal, showError]);



	return (
		<>
			<Formik
				initialStatus={defaultFormStatus}
				initialValues={defaultFormValues}
				enableReinitialize
				validationSchema={billingFormSchema}
				validateOnFocus={true}
				onSubmit={handleFormSubmit}
			>
				{({ isSubmitting, errors, values, touched, setFieldTouched, status, isValid, handleChange, submitForm, dirty, setFieldValue, setFieldError }) => (
					<>

						<div className="wrap-form" style={{ overflow: "hidden" }}>
							<h2 className="title">
								Billing Details{" "}
								<div
									className="solid-rule"
									style={{
										borderTop: "unset",
										height: "4px",
										backgroundColor: "var(--background-color-2)",
										width: "100%",
										marginTop: "auto",
										marginBottom: "10px",
									}}
								/>
							</h2>
						</div>
						<div className="highlight-form">
							<div className="wrap-form">
								<Form className="form billing">
									{/** @type {First Name} */}
									<Row>
										<Col md={4}>
											<CustomInput
												name="firstName"
												type="text"
												label="First Name"
												touched={touched.firstName}
												error={errors.firstName}
												value={values.firstName}
												required
												onKeyUp={e => {
													if (e.target.value !== "") {
														//transaction.firstName = e.target.value;
														//formDefaults.current.firstName = e.target.value;
														setFieldValue("firstName", e.target.value);
													}
												}}
												disabled={values.fromRenewalFlow && !user?.is_a_company}
											/>

										</Col>
										<Col md={4}>
											{/** @type {Last Name} */}
											<CustomInput
												name="lastName"
												type="text"
												label="Last Name"
												touched={touched.lastName}
												error={errors.lastName}
												value={values.lastName}
												required
												onKeyUp={e => {
													if (e.target.value !== "") {
														//transaction.lastName = e.target.value;
														//formDefaults.current.lastName = e.target.value;
														setFieldValue("lastName", e.target.value);
													}
												}}
												disabled={values.fromRenewalFlow && !user?.is_a_company}
											/>
										</Col>
										<Col md={4}>
											{/** @type {Email} */}
											<CustomInput
												name="email"
												type="text"
												label="Email"
												touched={touched.email}
												error={errors.email}
												value={values.email}
												required
												disabled={values.fromRenewalFlow}
												onKeyUp={e => {
													if (e.target.value !== "") {
														//formDefaults.current.email = e.target.value;
														setFieldValue("email", e.target.value);
													}
												}}
											/>

										</Col>
									</Row>

									<Row className="pt-4">
										<Col md={6}>
											{isLoggedIn && (
												<div
													style={
														values.sameAddress
															? {
																display: "flex",
																flexDirection: "row",
																color: "#002868",
																fontWeight: "bold",
																gridColumn: "1/-2",
																position: "relative",
															}
															: {
																display: "flex",
																flexDirection: "row",
																fontWeight: "bold",
																color: "#858586",
																gridColumn: "1/-2",
																position: "relative",
															}
													}
												>
													{transactionType !== QUICK_PAY && (
														<Field
															name="sameAddress"
															type="checkbox"
															//checked={values.sameAddress}
															value={values.sameAddress}
															onFocus={(e) => {
																handleChange(e);
																console.log(e);
																setFieldError('billingAddress', '');
																setFieldError('phoneNumber', '');
																setFieldError('region', '');

															}}

														>
															{({ field }) => (

																<CustomCheckbox
																	{...field}

																	onClick={() => {

																		setFieldValue("sameAddress", !values.sameAddress);

																		setFieldValue(
																			"billingAddress",
																			user.locations[0]?.street_number +
																				" " +
																				user.locations[0]?.street_name +
																				" " +
																				user.locations[0]?.street_type !==
																				null && !values.sameAddress
																				? user.locations[0]?.street_number +
																				" " +
																				user.locations[0]?.street_name +
																				" " +
																				user.locations[0]?.street_type
																				: ""
																		);
																		setFieldValue(
																			"phoneNumber",
																			user.phone_numbers[0]?.phone_number !== null && !values.sameAddress
																				? user?.phone_numbers?.[0]?.phone_number
																				: ""
																		);

																		setFieldValue(
																			"region",
																			user?.locations[0]?.parish !== null && !values.sameAddress
																				? user?.locations[0]?.parish.includes("St.")
																					? user?.locations[0]?.parish.replaceAll("St.", "Saint")
																					: user?.locations[0]?.parish
																				: ""
																		);



																	}}

																	checked={values.sameAddress}
																/>
															)}
														</Field>
													)}

													{transactionType !== QUICK_PAY && (
														<p style={{ textAlign: "left", marginLeft: "35px" }}>
															My billing address is the same as my home address
														</p>
													)}
												</div>
											)}
										</Col>
									</Row>
									<Row style={{ marginTop: 10 }}>
										<Col style={{ marginBottom: 20 }} md={8}>
											{/** @type {Address} */}
											<CustomInput
												type="text"
												name="billingAddress"
												label="Billing Address"
												touched={touched.billingAddress}
												error={errors.billingAddress}
												required
												disabled={values.sameAddress}
												value={values.billingAddress}
												onBlur={e => {
													if (e.target.value !== "") {
														//formDefaults.current.billingAddress = e.target.value;
														setFieldValue("billingAddress", e.target.value);
													}
													setFieldTouched("billingAddress", true, true)
												}}
											/>
										</Col>
										<Col style={{ marginBottom: 20 }} md={4}>
											{/** @type {Country} */}
											<CustomSelect
												name="country"
												value={values.country}
												label="Country"
												touched={touched.country}
												error={errors.country}
												priorityOptions={["JM", "US", "CA", "GB"]}
												onChange={(_, e) => {
													handleChange(e)

													if (e.target.value !== "")
														//formDefaults.current.country = e.target.value;
														setFieldValue("country", e.target.value);
												}}
												disabled={values.sameAddress}
												required
											/>
										</Col>
									</Row>
									<Row style={{ marginTop: 10 }}>
										<Col style={{ marginBottom: 20 }} md={4}>
											{/** @type {Province} */}

											{values.country === "Jamaica" ? (
												<CustomSelect
													name="region"
													country={values.country}
													label="Parish"
													touched={touched.region}
													error={errors.region}
													value={values.region}
													onChange={(_, e) => {
														handleChange(e)

														if (e.target.value !== "")
															//formDefaults.current.region = e.target.value;
															setFieldValue("region", e.target.value);

													}}

													required
													disabled={values.sameAddress}
												/>
											) : (
												<CustomInput
													name="region"
													type="text"
													label="Province"
													touched={touched.region}
													error={errors.region}
													value={values.region}
													onKeyUp={e => {
														if (e.target.value !== "")
															//formDefaults.current.region = e.target.value;
															setFieldValue("region", e.target.value);
													}}
													disabled={values.sameAddress}

												/>
											)}
										</Col>
										<Col style={{ marginBottom: 20 }} md={4}>
											{/** @type {ZIP Code} */}
											<CustomInput
												type="text"
												name="zip_code"
												label="ZIP Code"
												touched={touched.zip_code}
												error={errors.zip_code}
												maxLength={10}
												value={values.zip_code}
												onKeyUp={e => {
													if (e.target.value !== "")
														//formDefaults.current.phoneNumber = e.target.value;
														setFieldValue("zip_code", e.target.value);
												}} />

										</Col>
										<Col style={{ marginBottom: 20 }} md={4}>
											{/** @type {Phone Number} */}
											<CustomInput
												type="text"
												label="Phone Number"
												name="phoneNumber"
												as={MaskedInput}
												touched={touched.phoneNumber}
												error={errors.phoneNumber}
												mask={phoneNumberMask}
												disabled={values.sameAddress}
												value={values.phoneNumber}
												required
												onKeyUp={e => {
													if (e.target.value !== "")
														//formDefaults.current.phoneNumber = e.target.value;
														setFieldValue("phoneNumber", e.target.value);
													setFieldTouched("phoneNumber", true, true);
												}}

											/>


										</Col>
									</Row>
								</Form>
							</div>
						</div>

						<div
							className="wrap-form cards-wrap"
							style={{ display: "grid", gridTemplateColumns: "auto auto 1fr", overflow: "hidden" }}
						>
							<h2 className="title">Card Details</h2>
							<Card type={cardType.current} />
							<div
								className="solid-rule"
								style={{
									borderTop: "unset",
									height: "4px",
									backgroundColor: "var(--background-color-2)",
									width: "100%",
									marginTop: "auto",
									marginBottom: "10px",
								}}
							/>
						</div>
						<div className="highlight-form">
							<div className="wrap-form">
								<Form
									className="form cc"
									style={{ padding: errors.cardExpiryMonth && errors.cardExpiryYear ? "1.5rem" : "1rem 1.5rem" }}
								>
									<Row>
										<Col style={{ marginBottom: 20 }} md={6} xl={3}>
											{/** @type {Credit Card Number} */}
											<label
												className={
													"form-label cc-field-fw" +
													//`${touched.cardNumber && errors.cardNumber ? " error" : ""}` +
													`${values.cardNumber !== "" ? " hasValue" : ""}`
													//`${touched.cardNumber && errors.cardNumber ? " hasError" : ""}`
												}
											>
												<div>
													<span className="field-title">Card Number</span>
													<span className="required"> * </span>
												</div>
												<Field
													type="text"
													className={`field cc-number ${isSubmitting ||
														status.transactionState === transactionStates.LOADING ||
														status.transactionState === transactionStates.SUCCESS
														? "locked"
														: ""
														}`}

													innerRef={cardNumber}
													maxLength={16}

												>
													{({ field }) => (
														<MaskedInput
															id="cardNumber"
															{...field}
															value={values.cardNumber}
															mask={creditCardMask}
															guide={false}
															tabIndex="0"
															readOnly
														/>
													)}


												</Field>

												{/* touched.cardNumber && errors.cardNumber && (
											<div className="error-message">{errors.cardNumber}</div>
										) */}
											</label>
										</Col>
										<Col style={{ marginBottom: 20 }} md={6} xl={3}>
											{/** @type {Name on Card} */}
											<label
												className={
													"form-label cc-field-fw" +
													`${touched.cardName && errors.cardName ? " error" : ""}` +
													`${values.cardName !== "" ? " hasValue" : ""}` +
													`${touched.cardName && errors.cardName ? " hasError" : ""}`
												}
											>
												<div>
													<span className="field-title">Name on Card</span>
													<span className="required"> * </span>
												</div>
												<Field
													name="cardName"
													type="text"
													tabIndex="0"
													innerRef={cardName}
													validate={val => validateName(val)}
													value={values.cardName}
													onKeyUp={e => {
														if (e.target.value !== null) {
															//formDefaults.current.cardName = e.target.value
															setFieldValue("cardName", e.target.value);
														}


														setFieldTouched("cardName", true, true)

													}}

												/>
												{touched.cardName && errors.cardName && <div className="error-message">{errors.cardName}</div>}
											</label>
										</Col>
										<Col style={{ marginBottom: 20 }} md={6} xl={3} className="responsive-spacer">
											{/** @type {Card CVC} */}
											<label
												className={
													"form-label cc-field-fw" +
													//`${touched.cardCVC && errors.cardCVC ? " error" : ""}` +
													`${values.cardCVC !== "" ? " hasValue" : ""}`
													//`${touched.cardCVC && errors.cardCVC ? " hasError" : ""}`
												}
											>
												<div>
													<span className="field-title">CVC</span>
													<span className="required"> * </span>
													<InfoIcon label="CVC (The CVV/CVC code refers to the 3 digit number located on the back of your credit/debit card on the right side of the white signature strip)" />
												</div>
												<Field
													type="password"
													id="cardCVC"
													autoComplete="cc-csc"
													value={values.cardCVC}
													maxLength={cardType.current === "american-express" ? 4 : 3}
													className={`input-field ${isSubmitting ||
														status.transactionState === transactionStates.LOADING ||
														status.transactionState === transactionStates.SUCCESS
														? "locked"
														: ""
														}`}
													disabled={
														isSubmitting ||
														status.transactionState === transactionStates.LOADING ||
														status.transactionState === transactionStates.SUCCESS
													}
													innerRef={cardCVC}
													tabIndex="0"
													readOnly

												/>
												{/* {touched.cardCVC && errors.cardCVC && <div className="error-message">{errors.cardCVC}</div>} */}
											</label>
										</Col>
										<Col style={{ marginBottom: 20 }} md={6} xl={3} className="responsive-spacer">
											{/** @type {Expiry Date} */}
											<label
												className={
													"form-label cc-field-fw" +
													`${values.cardExpiryMonth !== "" && values.cardExpiryYear !== "" ? " hasValue" : ""}` +
													`${(touched.cardExpiryYear || touched.cardExpiryMonth) &&
														(errors.cardExpiryMonth || errors.cardExpiryYear)
														? " hasError"
														: ""
													}`
												}
											>
												<div style={{ whiteSpace: "nowrap" }}>
													<span className="field-title">Expiry Date (MM/YY)</span>
													<span className="required"> * </span>
												</div>
												<div className="expiry-fields-wrapper">
													<Field
														id="cardExpiryMonth"
														value={values.cardExpiryMonth}
														className="field"
														tabIndex="0"
														type="text"
														maxLength={2}
														onKeyUp={e => {
															if (e.target.value !== "")
																//formDefaults.current.cardExpiryMonth = e.target.value;
																setFieldValue("cardExpiryMonth", e.target.value);

														}}
													/>
													<div className="field-divider">/</div>
													<Field
														id="cardExpiryYear"
														value={values.cardExpiryYear}
														className="field"
														tabIndex="0"
														type="text"
														maxLength={2}
														onKeyUp={e => {
															if (e.target.value !== "")
																//formDefaults.current.cardExpiryYear = e.target.value;
																setFieldValue("cardExpiryYear", e.target.value);

															setFieldTouched("cardExpiryYear", true, true)
														}}
													/>
												</div>
												{(touched.cardExpiryYear && errors.cardExpiryMonth && (
													<div className="error-message">{errors.cardExpiryMonth}</div>
												)) ||
													(touched.cardExpiryYear && errors.cardExpiryYear && (
														<div className="error-message">{errors.cardExpiryYear}</div>
													))}
											</label>
										</Col>
									</Row>
								</Form>
								<div className="pay-now-wrapper">
									{transactionType && (
										<button className="button secondary-theme-btn" onClick={() => history.goBack()}>
											Back
										</button>
									)}
									<button
										className="button primary-theme-btn"
										type="submit"
										onClick={submitForm}
										disabled={
											!cardFieldsDirty || !(isValid && dirty) || isSubmitting

										}
										data-isloading={status.transactionState === transactionStates.LOADING}
										data-success={status.transactionState === transactionStates.SUCCESS}
										tabIndex={0}
									>
										{(status.transactionState === transactionStates.DEFAULT ||
											status.transactionState === transactionStates.ERROR) &&
											`Pay Now`}
										{status.transactionState === transactionStates.LOADING && "<DotsLoader />"}
										{status.transactionState === transactionStates.SUCCESS && "<Success />"}
									</button>
								</div>
							</div>
						</div>
					</>
				)}
			</Formik>
			<Helmet onChangeClientState={handleScriptLoadState}>
				{typeof window !== "undefined" && typeof window["myScript"] === "undefined" && (
					<script async defer src={gatewayURL}></script>
				)}
				{typeof window !== "undefined" && typeof window["myScript"] === "undefined" && (
					<script async defer src={jQueryURL}></script>
				)}
				{typeof window !== "undefined" && typeof window["myScript"] === "undefined" && (
					<style id="antiClickjack">
						{`{body {
                                display: none !important;
                            }`}
					</style>
				)}
			</Helmet>
		</>
	);
}

export default BillingDetails;
