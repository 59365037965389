import React from "react";
import Obfuscate from "react-obfuscate";

/**
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {(Object) => void} props.locate
 * @param {Boolean} props.active
 */
function Branches({ data, locate, active }) {
	function changeLocation() {
		locate(data);
	}

	return (
		<div className="sub_branch">
			<div className="animated bounce branch img location-pin" onClick={changeLocation}></div>

			<BranchAddress active={active} data={data} />
		</div>
	);
}

function BranchAddress({ active = false, data }) {
	return (
		<p className="branch-details">
			<span className={`branch-title${active ? " active" : ""}`}>{data.title}</span>
			<span> {data.street}</span>
			<span>
				{data.town}, {data.parish}
			</span>
			<span className="telephoneNum">
				<Obfuscate tel={data.telephone} obfuscateChildren={false}>
					{data.telephone}
					{data.ext}
				</Obfuscate>
			</span>
		</p>
	);
}

export default Branches;

export { BranchAddress };
