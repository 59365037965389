import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import error_icon from "assets/img/modal_icons/error.webp";
import info_icon from "assets/img/modal_icons/info.webp";
import success_icon from "assets/img/modal_icons/success.webp";
import oops_icon from "assets/img/modal_icons/oops.webp";
import { withSwal } from "react-sweetalert2";
import "./SweetModal.scss";

const CustomSwal = withReactContent(Swal);

/**
 *
 * @param {Object} props
 * @param {String=} props.title
 * @param {Object}props.content
 * @param {String=} props.primaryLabel
 * @param {String=} props.secondaryLabel
 * @param {() => void=} props.primaryCallback
 * @param {() => void=} props.secondaryCallback
 * @param {Boolean} props.alignButtonsVertically
 */
export const ErrorModal = async ({
	title,
	primaryLabel,
	secondaryLabel,
	primaryCallback,
	secondaryCallback,
	content,
	alignButtonsVertically=false
}) => {
	const modal = await CustomSwal.fire({
		title,
		iconHtml: (
			<img
				className="modal-icon animate__animated animate__bounceIn"
				src={/*  content?.props?.children?.includes("Oops") || content?.includes("Oops") ? oops_icon : */ error_icon}
				alt="error"
			/>
		),
		reverseButtons: true,
		confirmButtonText: primaryLabel,
		scrollbarPadding: true,
		html: content,
		cancelButtonText: secondaryLabel,
		showCancelButton: secondaryLabel !== undefined,
		allowOutsideClick: false,
		allowEscapeKey: false,
		// showClass: {
		// 	popup: "animate__animated animate__flipInX",
		// },
		// hideClass: {
		// 	popup: "animate__animated animate__flipOutX",
		// },
		customClass:{
			actions: alignButtonsVertically ? 'vertical-buttons' : '',
			cancelButton: alignButtonsVertically ? 'top-margin' : ''
		}
	});
	if (modal.isConfirmed) {
		primaryCallback();
	}
	if (modal.isDismissed) {
		secondaryCallback();
	}
	return <div></div>;
};

/**
 *
 * @param {Object} props
 * @param {String=} props.title
 * @param {Object}props.content
 * @param {String=} props.primaryLabel
 * @param {String=} props.secondaryLabel
 * @param {() => void=} props.primaryCallback
 *  @param {Object[]=} props.list
 * @param {() => void=} props.secondaryCallback
 * @param {String=}  props.inputType
 * @param {Boolean} props.isDismissed
 * @param {() => void=} props.dismissProcedure
 * @param {boolean=} props.showCloseButton
 * @param {Boolean} props.alignButtonsVertically
 */
export const InfoModal = async ({
	title,
	primaryLabel,
	secondaryLabel,
	primaryCallback,
	secondaryCallback,
	list,
	content,
	successIcon = false,
	isDismissed = false,
	dismissProcedure,
	showCloseButton,
	alignButtonsVertically=false
}) => {
	let modal = await CustomSwal.fire({
		title,
		iconHtml: successIcon ? <img src={success_icon} alt="success" className="modal-icon" /> : <img src={info_icon} className="modal-icon" alt="info" />,
		reverseButtons: true,
		confirmButtonText: primaryLabel,
		scrollbarPadding: true,
		html: (
			<>
				<div className="modal-content">{content}</div>

				{list && (
					<ul className="list-items">
						{list.map((docs, index) => (
							<li className="list-item" key={index}>
								{docs}
							</li>
						))}
					</ul>
				)}
			</>
		),
		cancelButtonText: secondaryLabel,
		showCancelButton: secondaryLabel !== undefined,
		showClass: {
			popup: "animate__animated animate__fadeInDown",
		},
		hideClass: {
			popup: "animate__animated animate__fadeOutUp",
		},
		allowOutsideClick: false,
		allowEscapeKey: false,
		showCloseButton: showCloseButton,
		customClass:{
			actions: alignButtonsVertically ? 'vertical-buttons' : '',
			cancelButton: alignButtonsVertically ? 'top-margin' : ''
		}
	});

	if (modal.isConfirmed) {
		primaryCallback();
	}
	if (modal.isDismissed) {
		if(isDismissed){
			if(modal.dismiss === CustomSwal.DismissReason.cancel){
				secondaryCallback();
			} else {
				dismissProcedure();
			}
		} else {
			secondaryCallback();
		}
	}
	return <div></div>;
};

/**
 *
 * @param {Object} props
 * @param {String=} props.title
 * @param {Object}props.content
 * @param {String=} props.primaryLabel
 * @param {String=} props.secondaryLabel
 * @param {() => void=} props.primaryCallback
 * @param {() => void=} props.secondaryCallback
 * @param {Object} props.secondaryContent
 * @param {Boolean} props.shouldDelete
 * @param {Boolean} props.showCloseButton
 * @param {Boolean} props.alignButtonsVertically
 */

export const ConfirmModal = async ({
	title,
	primaryLabel,
	secondaryLabel,
	primaryCallback,
	secondaryCallback,
	content,
	secondaryContent,
	shouldDelete = true,
	showCloseButton = true,
	alignButtonsVertically=false
}) => {
	let modal = await CustomSwal.fire({
		title,
		iconHtml: <img src={info_icon} className="modal-icon" alt="info" />,
		reverseButtons: true,
		confirmButtonText: primaryLabel,
		scrollbarPadding: true,
		html: content,
		cancelButtonText: secondaryLabel,
		showCancelButton: secondaryLabel !== undefined,
		allowOutsideClick: false,
		allowEscapeKey: false,
		showCloseButton: showCloseButton,
		showClass: {
			popup: "animate__animated animate__fadeInDown",
		},
		hideClass: {
			popup: "animate__animated animate__fadeOutUp",
		},
		customClass:{
			actions: alignButtonsVertically ? 'vertical-buttons' : '',
			cancelButton: alignButtonsVertically ? 'top-margin' : ''
		}
	});

	if (modal.isConfirmed) {
		primaryCallback();
		if (shouldDelete) {
			CustomSwal.fire({
				title: "Deleted!",
				iconHtml: <img src={success_icon} alt="success" className="modal-icon" />,
				html: secondaryContent,
			});
		}
	}
	if (modal.isDismissed) {
		secondaryCallback();
		Swal.close();
	}

	return <div></div>;
};

export const InputModal = async ({
	title,
	primaryLabel,
	secondaryLabel,
	primaryCallback,
	secondaryCallback,
	content,
	inputType,
	alignButtonsVertically=false
}) => {
	let modal = await CustomSwal.fire({
		title,
		input: inputType,
		reverseButtons: true,
		confirmButtonText: primaryLabel,
		scrollbarPadding: true,
		html: content,
		cancelButtonText: secondaryLabel,
		showCancelButton: secondaryLabel !== undefined,
		allowOutsideClick: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showClass: {
			popup: "animate__animated animate__fadeInDown",
		},
		hideClass: {
			popup: "animate__animated animate__fadeOutUp",
		},
		customClass:{
			actions: alignButtonsVertically ? 'vertical-buttons' : '',
			cancelButton: alignButtonsVertically ? 'top-margin' : ''
		}
	});

	if (modal.isConfirmed) {
		primaryCallback();
	}
	if (modal.isDismissed) {
		secondaryCallback();
		
	}
	return <div></div>;
};
