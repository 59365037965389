export const chunk = (arr, chunkSize = 1, cache = []) => {
	const tmp = [...arr];
	if (chunkSize <= 0) return cache;
	while (tmp.length) cache.push(tmp.splice(0, chunkSize));
	return cache;
};

export function formatMoney(
	amount,
	decimalCount = 2,
	decimal = ".",
	thousands = ",",
	showNegative = true,
	includeCurrencySymbol = true
) {
	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = showNegative && amount < 0 ? "-" : "";

		// The integer part of the number
		let integerPortion = parseInt(Math.abs(parseInt(amount) || 0).toFixed(decimalCount)).toString();
		// number or 3
		let j = integerPortion.length > 3 ? integerPortion.length % 3 : 0;

		const moneySymbol = includeCurrencySymbol ? "$" : "";
		const head = j ? integerPortion.substr(0, j) + thousands : "";
		const body = integerPortion.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands);
		const cents = decimal + (Math.abs(parseFloat(amount)) - parseFloat(integerPortion)).toFixed(decimalCount).slice(2);
		const formattedMoney = `${negativeSign}${moneySymbol}${head}${body}${cents}`;

		return formattedMoney;
	} catch (e) {
		console.error(e);
		return null;
	}
}
