import { dNet } from "api/Middleware";
import { checkKey } from "helpers/auth";

export const auth = async () => {
	let token = "";

	await dNet.post("/auth/token").then(res => {
		token = res.data[0].Response[0].key;
	});

	return token;
};

export const mwGlobalNameSearch = async (payload) => {
	let globalId = "";
	let success = false;
	let details = "";

	payload = {
		params: {
			trn: payload.national_id,
			dob: payload.dob,
			gender: payload.gender,
			is_a_company: payload.is_a_company,
			company_name: payload.company_name,
			national_id_type: payload.national_id_type,
		},
	};
	const endpoint = `/globalNameSearch`;
	const reqType = "get";

	let response = {};
	response = await checkKey({ endpoint, payload, reqType });
	globalId = response.global_name_id;
	success = response.success;
	details = response.hasOwnProperty("Details") ? response.Details : "";

	return { global_name_id: globalId, success, details };
};

export const mwGlobalNameCompanySearch = async (payload) => {
	let globalId = "";
	let success = false;
	let details = "";

	payload = {
		params: {
			trn: payload.national_id,
			company_name: payload.company_name,
			dob: payload.dob,
			gender: payload.gender,
			is_a_company: payload.is_a_company,
		},
	};
	const endpoint = `/globalNameSearchCompany`;
	const reqType = "get";

	let response = {};
	response = await checkKey({ endpoint, payload, reqType });
	globalId = response.global_name_id;
	success = response.success;
	details = response.hasOwnProperty("Details") ? response.Details : "";

	return { global_name_id: globalId, success, details };
};

export const globalNameGet = async (globalId) => {
	let globalName,
		response = {};

	const endpoint = `/globalNameGet`;
	const reqType = "get";
	const payload = {
		params: {
			globalNameId: globalId,
		},
	};

	response = await checkKey({ endpoint, payload, reqType });
	globalName = response.global_name;

	// await dNet.get(`/globalNameGet?token=${token}&globalNameId=${encodeURIComponent(globalId)}`).then(res => {
	//     globalName = res.data.global_name
	// })

	return globalName;
};

export const globalNamePolicies = async (globalId) => {
	let globalPolicies = {};

	const endpoint = `/globalNamePolicies`;
	const payload = {
		params: {
			globalNameId: globalId,
			source_type: "",
		},
	};

	const reqType = "get";

	globalPolicies = await checkKey({ endpoint, payload, reqType });

	// await dNet.get(`/globalNamePolicies?token=${token}&globalNameId=${encodeURIComponent(globalId)}`).then(res => {
	//     globalPolicies = res
	// })

	// globalPolicies = checkKey({endpoint, payload});

	return globalPolicies;
};

export const globalNameSubmit = async (payload) => {
	const endpoint = `/globalNameSubmit`;

	let response = {};
	response = await checkKey({ endpoint, payload});

	return response;
};
