import { checkExpToken, checkKey } from "helpers/auth";

export const getPolicy = async ( payload) => {
	let response = {};
	const endpoint = "/getPolicyForClaim";

	response = await checkKey({ endpoint, payload });
	return response;
};

export const getClaimsHandler = async ( payload) => {
	let response = {};
	const endpoint = "/getUsers";

	response = await checkKey({ endpoint, payload,  reqType: "get" });
	return response;
};

export const updateClaim = async ( payload) => {
	let response = {};
	const endpoint = `/claimUpdate`;

	response = await checkKey({ endpoint, payload });
	return response;
};

export const getClaimLossResult = async ( payload) => {
	const endpoint = `/getClaimLossResult`;
	let response = {};

	response = await checkKey({ endpoint, payload });
	return response;
};

export const getSourceTypes = async ( payload) => {
	const endpoint = "/getSources";
	let response = {};
	response = await checkKey({ endpoint, payload });
	return response;
};

export const getClaimLossCauses = async ( payload) => {
	let response = {};
	const endpoint = `/getClaimLossCauses`;

	response = await checkKey({ endpoint, payload });
	return response;
};

export const getClaim = async ( payload) => {
	let response = {};
	const endpoint = `/searchClaim`;

	response = await checkKey({ endpoint, payload });
	return response;
};

export const getPolicyClaim = async (claim_id) => {
	let payload = {params: { claimID: claim_id}
};
	let response = {};
	const endpoint = `/getClaim`;

	response = await checkKey({ endpoint, payload,  reqType: "get"  });
	return response;
};

export const getClaims = async ( payload) => {
	let response = {};
	// const token = `${token1}&policy_id=${payload}`;

	// encodeURIComponent(globalId)
	const endpoint = `/getClaims`;

	response = await checkKey({ endpoint, payload });
	return response;
};

export const submitFile = async ( payload, callback = () => {}) => {
	let response = {};
	const endpoint = `/submitFile`;

	response = await checkKey({ endpoint, payload }, { onUploadProgress: callback });
	return response;
};

export const linkFile = async ( payload, callback = () => {}) => {
	let response = {};
	const endpoint = `/registryFileSubmit`;

	response = await checkKey({ endpoint, payload }, { onUploadProgress: callback });
	return response;
};

export const getRegistryTypes = async ( payload) => {
	let response = {};
	const endpoint = "/getRegistryTypes";
	response = await checkKey({ endpoint, payload,  reqType: "get" });
	return response;
};

export const addParticipant = async ( payload) => {
	let response = {};
	const endpoint = `/addParticipant`;

	response = await checkKey({ endpoint, payload });
	return response;
};

export const submitClaim = async ( payload) => {
	let response = {};
	const endpoint = `/submitClaim`;

	response = await checkKey({ endpoint, payload });
	return response;
};

export const getParticipantType = async () => {
	let response = {};
	const endpoint = `/getParticipantType`;

	response = await checkKey({ endpoint });
	return response;
};
export const getAllClaimStatus = async (payload) => {
	let response = {};
	const endpoint = `/claims/all/status`;

	response = await checkExpToken({ endpoint, payload});
	return response;
};

export const getClaimStatus = async (claim_status) => {
	let payload ={claim_status: claim_status}
	let response = {};
	const endpoint = `/claims/status`;

	response = await checkExpToken({ endpoint, payload});
	return response;
};

export const getClaimParticipant = async ( participant_id ) => {
	const payload = {
        params: {
            participant_id: participant_id,
        },
    };
	let response = {};
	const endpoint = "/getClaimParticipant";
	response = await checkKey({ endpoint, payload, reqType: "get" });
	return response;
};