import { createSlice } from "@reduxjs/toolkit";
import { fullLogout } from "globals/misc";

const defaultState = {
	navBarState: [],
};

let overlaySlice = createSlice({
	name: "navbar",
	initialState: defaultState,
	reducers: {
		/** @param {{payload: Array}} action */
		setNavbarState: (state, action) => {
			state.navBarState = action.payload;
		},

		resetNavbar: () => defaultState,
	},
	extraReducers: builder => {
		builder.addCase(fullLogout, (state, action) => defaultState);
	},
});

/** @param {{navbar: defaultState}} state */
export const getNavbarState = ({ navbar: state }) => state.navBarState;

export const { setNavbarState, resetNavbar } = overlaySlice.actions;

export default overlaySlice.reducer;
