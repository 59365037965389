import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { string, object } from "yup";
import axios from "axios";

const defaultFormValues = {
	name: "",
	email: "",
	subject: "",
	message: "",
};

const formStates = {
	DEFAULT: "Default",
	ACTIVE: "Active",
	ERROR: "Error",
	SENDING: "Sending",
	SENT: "Sent",
};

const contactFormSchema = object().shape({
	name: string().trim().required("Your Name is Required"),
	email: string().trim().email().required("Your Email is Required"),
	subject: string().trim().required("A Subject is Required"),
	message: string().trim().required("A Message is Required"),
});

function ContactForm() {
	/** @type {[String, React.Dispatch<React.SetStateAction<String>>]} */
	const [formState, setFormState] = useState(formStates.DEFAULT);

	/** Async function to allow Formik to return to proper state when promise is resolved */
	async function handleSubmit(values, { resetForm }) {
		setFormState(formStates.SENDING);
		axios
			.post(`${process.env.REACT_APP_API_URL}/email`, values, {
				headers: {
					Accept: "application/json;*/*",
					"Content-Type": "application/json",
				},
			})
			.then(() => {
				toast.success("Your email has been sent!");
				setFormState(formStates.SENT);
				resetForm({ name: "", email: "", subject: "", message: "" });
				setTimeout(() => setFormState(formStates.ACTIVE), 4000);
			})
			.catch(() => setFormState(formStates.ERROR));
	}

	return (
		<Formik
			initialValues={defaultFormValues}
			validationSchema={contactFormSchema}
			onSubmit={handleSubmit}
			validateOnMount={true}
		>
			{({ isSubmitting, errors, isValid, touched }) => (
				<>
					<div className="email-title">
						Email Us
						<div className="solid-rule" />
					</div>
					<div className="email-section">
						<div className="error">{errors && errors[0]}</div>

						<Form
							className={`form contact-form${formState === (formStates.SENDING || formStates.SENT) ? " active" : ""}`}
						>
							<div className="overlay">
								{formState === formStates.SENDING && (
									<h3 className="sending">
										Sending <span className="dot">.</span>
										<span className="dot">.</span>
										<span className="dot">.</span>
									</h3>
								)}

								{formState === formStates.SENT && <h3 className="success">Sent</h3>}
							</div>

							<label className="field form-label no-header">
								<Field name="name" type="text" className="form-control" placeholder="Full Name*" />
								{touched.name && errors.name && <div className="error">{errors.name}</div>}
							</label>

							<label className="field email form-label no-header">
								<Field
									name="email"
									type="email"
									className="form-control"
									aria-describedby="emailHelp"
									placeholder="Email*"
								/>
								{touched.email && errors.email && <div className="error">{errors.email}</div>}
							</label>

							<label className="field subject form-label no-header">
								<Field name="subject" type="text" className="form-control" placeholder="Subject*" />
								{touched.subject && errors.subject && <div className="error">{errors.subject}</div>}
							</label>
							<label className="field message ">
								<Field
									as="textarea"
									name="message"
									rows={10}
									className="form-control"
									placeholder="Write Message*"
									style={{ resize: "none" }}
								/> 
								<div style={{display:"flex",justifyContent:"space-between", margin:"0 5px"}}>
									<div style={{justifyItems:"start"}}>
										{touched.message && errors.message && <div className="error">{errors.message}</div>}
									</div> 
									<div style={{justifyItems:"end"}}>
										<span style={{color: "#666", fontStyle: "italic"}}>* Required</span>
									</div>
								</div>
							</label>
							<button type="submit" className="button" disabled={!isValid || isSubmitting}>
								Submit
							</button>
						</Form>
					</div>
				</>
			)}
		</Formik>
	);
}

export default ContactForm;
