import { Field } from "formik";
import React from "react";

export default function CustomInput({
	touched,
	name,
	label = "",
	value,
	error,
	required = null,
	maxLength = 50,
	...otherProps
}) {
	return (
		<>
			<label
				className={
					"form-label" +
					`${touched && error ? " error" : ""}` +
					`${value !== "" ? " hasValue" : ""}` +
					`${touched && error ? " hasError" : ""}`
				}
				htmlFor={`${name}`}
			>
				<div>
					<span className="field-title">{label}</span>
					{required && <span className="required"> * </span>}
				</div>

				
					<Field type="text" name={name} className="field" tabIndex="0" value={value} {...otherProps} />
				
				{touched && error && <div className="error-message">{error}</div>}
			</label>
		</>
	);
}
