/** Policies that can be renewed/extended */
export const includedPolicies = [
	"MCOTP",
	"MCOTPS",
	"MCOTN",
	"MPCTP",
	"MCPTP",
	"MMCTP",
	"MPCTN",
	"MPCTPS",
	"MPPTP",
	"MPPTPB",
	"MPPTPBS",
	"MPPTPS",
	"MLXC",
	"MLXCC",

	"CBSS",
	"MCOC",
	"MCOCS",
	"MCPC",
	"MPCC",
	"MPCCN",
	"MPCCS",
	"MPPC",
	"MPPCB",
	"MPPCBS",
	"MPPCS",
	"MPRC",
	"MUWC",
	"MUWCC",
	"MPRCC",

	"MCOCN",
	"MCOTF",
	"MPCTF",
	"MPPTF",
	"MMCC",

	"MSTTP",
	"MSTC",
	"MSTLF",
	"MSTTF",
];

export const applicableNarratives = [
	"2000",
	"2008",
	"2012",
	"2020",
	"2021",
	"2022",
	"2031",
	"2032",
	"2036",
	"2042",
	"2043",
	"2044",
	"2045",
	"2046",
	"2049",
	"2050",
	"2051",
	"2052",
	"2053",
	"2056",
	"2057",
	"2058",
	"2055",
	"2041",
	"2019",
	"2047",
	"2023",
	"2033",
];

export const homePolicies = [];

export const applicableRiders = {
	pa_rider: ["131", "132", "133", "134", "135" /*, "138A", "139A", "136A" */],
	um_rider: ["140", "141", "142", "143", "144", "145", "147"],
};

export const comprehensiveCodes = [
	"CBSS",
	"MCOC",
	"MCOCS",
	"MCPC",
	"MPCC",
	"MPCCN",
	"MPCCS",
	"MPPC",
	"MPPCB",
	"MPPCBS",
	"MPPCS",
	"MPRC",
	"MUWC",
	"MUWCC",
	"MPRCC",
	"MLXC",
	"MXLXCC",
];

export const comprehensiveAgreedValueCodes = ["MUWC", "MUWCC", "MPRC", "MPRCC", "MLXC", "MXLXCC"];

export const includedComprehensiveNonAgreedPolicies = [
	"CBSS",
	"MCOC",
	"MCOCS",
	"MCPC",
	"MPCC",
	"MPCCN",
	"MPPC",
	"MPPCB",
	"MPPCBS",
	"MPPCS",
	"MPCCS",
];

export const claimAcceptablePolicies = ["NCBMLC"];

export const includedAgreedPolicies = ["MPRCC", "MUWC", "MPRC", "MUWCC"];

export const includedAgreedPoliciesWithGN = [{ prefix: "MPCCS", group_name: "NCBAP" }];

export const thirdPartyCode = [
	"MCOTP",
	"MCOTPS",
	"MCOTN",
	"MPCTP",
	"MCPTP",
	"MMCTP",
	"MPCTN",
	"MPCTPS",
	"MPPTP",
	"MPPTPB",
	"MPPTPBS",
	"MPPTPS",
];

export const PA_death_Disablement = ["131", "132", "133", "134", "135"];

export const uninsured_Motorist = ["140", "141", "142", "143", "144"];

export const applicable_policy_prefix_promo = ["MLXCC", "MLXC", "MPRCC", "MUWC", "MPCC", "MPCTP", "MPRC", "MUWCC"];
