import React from "react";
import { formatMoney } from "helpers/policy";
import {Row, Col} from 'reactstrap'
/**
 * @typedef {Object} paymentFormValues
 * @property {String=} paymentFormValues.policyNumber
 * @property {String=} paymentFormValues.paymentAmount
 * @property {String=} paymentFormValues.paymentPurpose
 * @property {String=} paymentFormValues.paymentPurposeDetailed
 * @property {String=} paymentFormValues.currencyType
 * @property {Boolean=} paymentFormValues.fromRenewalFlow
 * @property {Object=} paymentFormValues.codes
 * @property {Object=} paymentFormValues.premiumPA_UM
 * @property {Object=} paymentFormValues.extensions
 */

/**
 * @typedef {Object} billingFormValues
 * @property {String=} billingFormValues.firstName
 * @property {String=} billingFormValues.lastName
 * @property {String=} billingFormValues.email
 * @property {String=} billingFormValues.phoneNumber
 * @property {String=} billingFormValues.billingAddress
 * @property {String=} billingFormValues.country
 * @property {String=} billingFormValues.region
 * @property {String=} billingFormValues.zip_code
 * @property {String=} billingFormValues.cardNumber
 * @property {String=} billingFormValues.cardName
 * @property {String=} billingFormValues.cardExpiryMonth
 * @property {String=} billingFormValues.cardExpiryYear
 * @property {String=} billingFormValues.cardCVC
 */

/**
 * Shows the Payment Details
 * @param {Object} props
 * @param {paymentFormValues} props.paymentDetails
 * @param {{session: *, sourceOfFunds: *}} props.paymentResponse
 * @param {(value: String) => void} props.maskCreditCard
 * @param {billingFormValues} props.billingDetails
 */
const PaymentContent = ({ paymentDetails, paymentResponse, maskCreditCard, billingDetails }) => (
	<>
		<p style={{ textAlign: "center" }}>Policy No: {paymentDetails.policyNumber}</p>
		<p style={{ marginBottom: "2rem", textAlign: "center" }}>
			{"Type of Transaction: "}
			{paymentDetails.paymentPurpose === "Other"
				? `Other - ${paymentDetails.paymentPurposeDetailed}`
				: paymentDetails.paymentPurpose}
		</p>
				<p style={{ textAlign: "center" }}>
			Card Number: {paymentResponse && maskCreditCard(paymentResponse.sourceOfFunds.provided.card.number)}
		</p>
		
		<p style={{ marginBottom: "2rem", textAlign: "center" }}>Name on Card: {billingDetails.cardName}</p>
		<p style={{ marginBottom: "2rem", textAlign: "center" }}>
			<strong>{`Payment Amount: ${paymentDetails.currencyType} ${formatMoney(paymentDetails.paymentAmount)}`}</strong>
		</p>
	</>
);

export default PaymentContent;
