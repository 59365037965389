import { createSlice, current } from "@reduxjs/toolkit";
import { changePolicy, fullLogout } from "globals/misc";
const defaultState = {
	benefits: [],
	benefitsTotal:0,
	selectedRisk: null,
	cart: [],
};

const getIndex = ({ benefits, selectedRisk }, benefitId) => {
	let riskIndex = benefits?.findIndex(riskBenefit => selectedRisk === riskBenefit.risk_id);
	// find benefit
	let bIndex = benefits?.[riskIndex]?.benefits.findIndex(benefit => benefit.id === benefitId);

	return { riskIndex, bIndex };
};

let additionalBenefitSlice = createSlice({
	name: "additionalbenefit",
	initialState: defaultState,
	reducers: {
		setActiveRiskId: (state, action) => {
			state.selectedRisk = action.payload;
		},
		/** @param {{payload: Array}} action */
		setBenefits: (state, action) => {
			state.benefits = action.payload;
		},
		setBenefitsTotal: (state, action) => {
			state.benefitsTotal = action.payload;
		}, 
		selectBenefitTier: (state, action) => {
			let { riskIndex, bIndex } = getIndex(current(state), action.payload.benefitId);
			state.benefits[riskIndex].benefits[bIndex].selectedTier = action.payload.selectedTier;
		},
		selectBenefit: (state, action) => {
			let { riskIndex, bIndex } = getIndex(current(state), action.payload.benefitId);

			state.benefits[riskIndex].benefits[bIndex].selected = true;
		},
		unselectBenefit: (state, action) => {
			let { riskIndex, bIndex } = getIndex(current(state), action.payload.benefitId);
			state.benefits[riskIndex].benefits[bIndex].selected = false;
		},
		disableBenefit: (state, action) => {
			let { riskIndex, bIndex } = getIndex(current(state), action.payload.benefitId);
			state.benefits[riskIndex].benefits[bIndex].disabled = true;
		},
		enableBenefit: (state, action) => {
			let { riskIndex, bIndex } = getIndex(current(state), action.payload.benefitId);
			state.benefits[riskIndex].benefits[bIndex].disabled = false;
		},
		addToCart: (state, action) => {
			state.cart.push(action.payload);
		},

		update: (state, action) => {
			state.cart[action.payload.index] = { ...state.cart[action.payload.index], ...action.payload.data };
		},
		/** remove from cart by index */
		removeFromCart: (state, action) => {
			state.cart.splice(action.payload.index, 1);
		},
		/**
		 * remove this item for this car
		 *
		 * @param {*} state
		 * @param {Object} action
		 * @param {Object} action.payload
		 * @param {String} action.payload.risk_for
		 * @param {String} action.payload.id
		 */
		removeItemFromCart: (state, action) => {
			let index = state.cart.findIndex(c => c.id === action.payload.id && c.risk_for === state.selectedRisk);
			state.cart.splice(index, 1);
		},
		emptyCart: state => {
			state.cart = [];
		},
		setDefault: () => defaultState,
	},
	extraReducers: builder => {
		builder.addCase(fullLogout, (state, action) => defaultState).addCase(changePolicy, (state, action) => defaultState);
	},
});

export const getBenefitsTotal = ({ additionalbenefit: state }) => state.benefitsTotal;
export const getBenefits = ({ additionalbenefit: state }) => state.benefits;
export const getActiveRiskIdForBenefit = ({ additionalbenefit: state }) => state.selectedRisk;
export const getCart = ({ additionalbenefit: state }) => state.cart;

export const {
	setBenefits,
	setBenefitsTotal,
	selectBenefitTier,
	selectBenefit,
	setActiveRiskId,
	unselectBenefit,
	disableBenefit,
	enableBenefit,
	setDefault,
	emptyCart,
	removeFromCart,
	removeItemFromCart,
	update,
	addToCart,
} = additionalBenefitSlice.actions;
export default additionalBenefitSlice.reducer;
