import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useHistory } from "react-router";

const ReceiptErrorModal = ({ policyNumber, amount, currency, customer, paymentType }, ref) => {
    const [modalState, setModalState] = useState(false);
    const history = useHistory();

    useImperativeHandle(ref, () => ({
        openModal: () => setModalState(true),
        closeModal: () => setModalState(false),
        toggleModal: () => setModalState(!modalState)
    }));

    if (!modalState) return null;


    const referenceNumber = new Date().getDay() + "" + new Date().getUTCMonth() + "" + new Date().getFullYear() + "" + new Date().getMilliseconds() + "_"
        + policyNumber;

    const orderDate = new Date().toISOString().split("T")[0];

    return (
        <div className="global-modal">
            <div className="global-modal-content">
                { /*   <div onClick={() => setModalState(false)} className="global-modal-cancel">
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </div> 
                */
                }
                <div className="global-modal-title">
                    Payment Successful
                </div>
                <div className="global-modal-content-body">
                    <div className="modal-message">
                        Your payment has been successfully processed. However, your payment receipt was not generated. Please click 'OK' to continue.<br /> <br /> Please contact us at 876-978-3690-9
                        with the payment details below.
                    </div>
                    <div className="modal-payment-details-container">
                        <table>
                            <tr>
                                <td className="cell-title">Policy Number</td>
                                <td className="cell-value">{policyNumber}</td>
                            </tr>
                            <tr>
                                <td className="cell-title">Payment Type</td>
                                <td className="cell-value">{paymentType}</td>
                            </tr>
                            <tr>
                                <td className="cell-title">
                                    Billing Name
                                </td>
                                <td className="cell-value">
                                    {customer.firstName} {customer.lastName}
                                </td>
                            </tr>
                            <tr>
                                <td className="cell-title">
                                    Billing Address
                                </td>
                                <td className="cell-value">
                                    {customer.address}
                                </td>
                            </tr>
                            <tr>
                                <td className="cell-title">
                                    Order Date
                                </td>
                                <td className="cell-value">
                                    {orderDate}
                                </td>
                            </tr>
                            <tr>
                                <td className="cell-title">
                                    Order Amount
                                </td>
                                <td className="cell-value">
                                    {currency} {`$${amount}`}
                                </td>
                            </tr>
                            <tr>
                                <td className="cell-title">
                                    Reference Number
                                </td>
                                <td className="cell-value">
                                    {referenceNumber}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="button-container">
                    <button className="button-home" onClick={() => {
                        history.push("/confirmation");
                        setModalState(false);
                    }}>
                        OK
                    </button>
                </div>
            </div>
        </div>
    )
}


export default forwardRef(ReceiptErrorModal);