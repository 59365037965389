import { CLAIMS, PAY_BALANCE, RENEWAL, POLICY_MANAGER, TRACK_A_CLAIM } from "pages/RenewPolicy/costants";

export const LEFT = "LEFT";
export const RIGHT = "RIGHT";

export const arrayOfAcceptedPages = [
	// `/${CLAIMS}/login`,
	// `/${RENEWAL}/login`,
	// `/${PAY_BALANCE}/login`,
	// `/${POLICY_MANAGER}/login`,
	`/${TRACK_A_CLAIM}/contact-info`,
	//`/${TRACK_A_CLAIM}/login`,
	`/${CLAIMS}/otp`,
	`/${RENEWAL}/otp`,
	`/${PAY_BALANCE}/otp`,
	`/${POLICY_MANAGER}/otp`,
	`/${TRACK_A_CLAIM}/otp`,
	`/${CLAIMS}/otp-verification`,
	`/${RENEWAL}/otp-verification`,
	`/${PAY_BALANCE}/otp-verification`,
	`/${POLICY_MANAGER}/otp-verification`,
	`/${TRACK_A_CLAIM}/otp-verification`,
	`/${CLAIMS}/contact-info`,
	`/${RENEWAL}/contact-info`,
	`/${PAY_BALANCE}/contact-info`,
	`/${TRACK_A_CLAIM}/contact-info`,
	`/${POLICY_MANAGER}/contact-info`,
	// `/${POLICY_MANAGER}/policy-listing`,
	`/${TRACK_A_CLAIM}/policy-listing`,
];

export const noticeNarratives = ["2055", "2059", "2060", "2034", "2010", "2042", "2042\n"];

// `/${RENEWAL}/policy-listing`,
