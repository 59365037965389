const branchInfo = {
	uuid: "",
	branch_name: "AGIC Online",
	receipt_branch_name: "AGIC Online",
	branch_id: "AGIO",
	branch_email: process.env.REACT_APP_BRANCH_EMAIL,
	tablet_id: "1",
	tablet_type: "Customer Tablet",
};

export default branchInfo;
