import React from "react";
import PersonInfo from "components/template/AboutUs/components/PersonInfo";

import "./AboutUs.scss";

export default function AboutUs({ title, smallTitle, description, persons }) {
	return (
		<div className="about-template">
			{title ? <h1>{title}</h1> : smallTitle && <h3>{smallTitle}</h3>}
			<p className="description">{description}</p>

			<div className="content">
				{Array.isArray(persons) ? (
					persons.map((person, key) => (
						<div key={`about_${title || smallTitle || "NA"}_${key}`}>
							<PersonInfo {...person} />
						</div>
					))
				) : (
					<div className="empty">Currently Vacant</div>
				)}
			</div>
		</div>
	);
}
