import { nodeApi } from "api/Middleware";

async function createLog({ trn, type, data, errors }) {
	// const axios = require("axios").default;
	const response = await nodeApi.post(
		`/log/add`,
		{ trn, type, data, errors },
		{
			headers: {
				Accept: "application/json;*/*",
				"Content-Type": "application/json",
			},
		}
	);

	return response.data;
}

export { createLog };
