import { nodeApi } from "api/Middleware";
import { checkExpToken } from "helpers/auth";

export const addPaymentReminder = async payload => {
	let response = {};
	const endpoint = `/paymentreminder/set`;

	response = await checkExpToken({ endpoint, payload });
	return response;
};

// export const getPaymentReminder = async (payload) => {
// 	let response = {};
// 	const endpoint = `/paymentreminder/get`;

// 	response = await checkExpToken({ endpoint, payload});
// 	return response;
// };

export const removePaymentReminder = async payload => {
	let response = {};
	const endpoint = `/paymentreminder/remove`;

	response = await checkExpToken({ endpoint, payload });
	return response;
};
