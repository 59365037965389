import { createSlice } from "@reduxjs/toolkit";
import { changePolicy, fullLogout } from "globals/misc";

const defaultState = {
	confirmationData: {
		receiptNum: "",
		responseAfterUpdate: null,
		receiptDetails: null,
		billingDetails: null,
		paymentDetails: null,
		isInFlow: false,
		files: [],
	},
};

let confirmationSlice = createSlice({
	name: "confirmation",
	initialState: defaultState,
	reducers: {
		/**
		 * @typedef {Object} confData
		 * @property {Object} confData.payload
		 * @property {String} confData.payload.receiptNum
		 * @property {Object} confData.payload.responseAfterUpdate
		 * @property {Object} confData.payload.billingDetails
		 * @property {Object} confData.payload.paymentDetails
		 * @property {Object} confData.payload.isInFlow
		 *@property {Object} confData.payload.files
		 * @param {confData} action
		 */
		setConfirmationData: (state, action) => {
			state.confirmationData = { ...state.confirmationData, ...action.payload };
		},
		setIsInFlow: (state, action) => {
			state.confirmationData.isInFlow = action.payload;
		},
		setReceiptDetails: (state, action) => {
			state.confirmationData.receiptDetails = action.payload;
		},

		clearConfirmationData: () => defaultState,
	},
	extraReducers: builder => {
		builder.addCase(fullLogout, (state, action) => defaultState);
		builder.addCase(changePolicy, (state, action) => defaultState);
	},
});

/** @param {{confirmation: defaultState}} state */
export const getConfirmationData = ({ confirmation: state }) => state.confirmationData;

export const { setConfirmationData, clearConfirmationData, setReceiptDetails, setIsInFlow } = confirmationSlice.actions;

export default confirmationSlice.reducer;
