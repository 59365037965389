import axios from "axios";

/** This is so we can specify the baseurl and other common headers for each query immidiately */
export const tomCat = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	headers: {
		Accept: "application/json;*/*",
		"Content-Type": "application/json",
	},
});

export const dNet = axios.create({
	baseURL:process.env.REACT_APP_DOT_NET_BACKEND_URL,
	headers: {
		Accept: "application/json;*/*",
		"Content-Type": "application/json",
		"X-Api-Key" :  process.env.REACT_APP_DOTNET_API_KEY,
		
	},
});

export const nodeApi = axios.create({
	baseURL: process.env.REACT_APP_API_URL, 
	headers: {
		Accept: "application/json;*/*",
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/json",
	},
});

// export const googleApi = axios.create({
// 	baseURL: process.env.REACT_APP_GOOGLE_API_URL,
// 	headers: {
// 		Accept: "application/json;*/*",
// 		"Access-Control-Allow-Origin": "*",
// 		"Content-Type": "application/json",
// 	},
// });
