import React from "react";
import MCColor from "assets/img/interface/MC-color.png";
import VISAColor from "assets/img/interface/Visa-colour.png";
import MCColorWebp from "assets/img/interface/MC-color.webp";
import VISAColorWebp from "assets/img/interface/Visa-colour.webp";

/** Processes the card validator result and displays relevant CC type image */
export default function Card({ type }) {
	return (
		<div className="cards">
			<picture>
				<source srcSet={MCColorWebp} type="image/webp" />
				<source srcSet={MCColor} type="image/png" />
				<img src={MCColor} alt="MasterCard" loading="lazy" />
			</picture>
			<picture>
				<source srcSet={VISAColorWebp} type="image/webp" />
				<source srcSet={VISAColor} type="image/png" />
				<img src={VISAColor} alt="Visa Card" loading="lazy" />
			</picture>
		</div>
	);
}
