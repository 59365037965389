/**
 * This merges the policy with the cart and all required extensions ( preexisting extensions). This is consolidated for easy use in the future.
 *
 * @param {Object} props
 * @param {Object} props.policy
 * @param {Object[]} props.cart
 * @param {Object[]} props.extensions
 * @param {Object[]} props.promoCodes
 * @returns
 */
export function formatPolicyForRenewal({ policy, cart, extensions, promoCodes }) {
	//Adding manual_extensions
	let _manualExtensions = [];
	let _manualLimits = [];

	// reformated crt
	let length = policy.risks[0].manual_rates.length;
	const _cart = cart?.map((cart_item, index) => {
		if (cart_item.manual_extensions) _manualExtensions.push(...cart_item.manual_extensions);
		if (cart_item.manual_limits) _manualLimits.push(...cart_item.manual_limits);
		return {
			position: length + index + 1,
			type: cart_item.type,
			limit: cart_item.limit,
			category_position: cart_item.category_position,
			category: cart_item.category,
			code: cart_item.code,
			value: cart_item.value,
			premium: cart_item.premium,
			rate_code_description: cart_item.rate_code_description,
			is_blocked: cart_item.is_blocked,
			risk_for: cart_item.risk_for,
		};
	});

	// add PA/UM rider if they existed before
	_manualExtensions.push(...(extensions || []));

	// Call Renewal APIs
	let _policy = {
		...policy,
		// this ensures the correct updates benefit_items are added on the policy in underwriter
		manual_extensions: _manualExtensions,
		manual_limits: _manualLimits,
		risks: policy.risks.map(r => ({
			...r,
			discounts: [...r.discounts, ...promoCodes],
			manual_rates: [...r.manual_rates, ..._cart?.filter(cartitem => cartitem.risk_for === r.risk_id), ...promoCodes],
			// needs to be false at all times or no inhouse edits can be made
			is_manual_rating: false,
			// updates the policy to add the additional benefits to it.
			rate_handling: "as_submitted",
		})),
	};

	return {
		_cart,
		_manualExtensions,
		_policy,
	};
}
