import React, { useEffect, useState, useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Girl from "assets/img/assistant/Girl-welcome.png";
import GirlWebp from "assets/img/assistant/Girl-welcome.webp";
import "./Carousel.scss";

/**
 * Custom Carousel that transitions on a delay
 * @param {Object} props
 * @param {Number=} props.internalWidth
 * @param {Array} props.children
 */

function CarouselC({ internalWidth, children: slides }) {
	const length = slides.length;
	/** @type {React.MutableRefObject<HTMLUListElement>} */
	const carouselRef = useRef(null);
	const slideIntervalRef = useRef(null);
	const [carouselPos, setCarouselPos] = useState(0);
	const [containerWidth, setContainerWidth] = useState(-1);

	const pages = Array.apply(null, Array(length)).map((i, k) => (
		<div
			className={`page-icon ${k === carouselPos ? "selected" : ""}`}
			key={`pg_icon_${k}`}
			onClick={() => setCarouselPos(limit(k))}
		></div>
	));

	const limit = num => (num >= length ? num - length : num < 0 ? length + num : num);

	useEffect(() => {
		const limit = num => (num >= length ? num - length : num < 0 ? length + num : num);

		if (pages.length > 1) {
			slideIntervalRef.current = setInterval(() => setCarouselPos(limit(carouselPos + 1)), 15000);

			return () => {
				clearInterval(slideIntervalRef.current);
			};
		}
	}, [carouselPos, pages.length, length]);

	useEffect(() => {
		if (containerWidth > -1 && carouselRef.current) {
			const slider = carouselRef.current;

			slider.scrollLeft = carouselRef.current.clientWidth * carouselPos;
		}
	}, [carouselPos, containerWidth]);

	/**
	 * Updates the width when the screen is resized or rotated
	 */
	useEffect(() => {
		const handleResize = () => {
			setContainerWidth(carouselRef.current.clientWidth);
		};

		handleResize();
		window.addEventListener("resize", handleResize);
		window.addEventListener("orientationchange", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("orientationchange", handleResize);
		};
	}, []);

	return (
		<div className="carousel">
			<div className="controls arrows" style={{ position: "relative" }}>
				<button
					className="left"
					onClick={() => {
						setCarouselPos(limit(carouselPos - 1));
					}}
				>
					<FaChevronLeft />
				</button>
			</div>

			<div className="controls dots" style={{ alignSelf: "flex-end" }}>
				<div className="pages">{pages}</div>
			</div>

			<div className="controls arrows">
				<button
					className="right"
					onClick={() => {
						setCarouselPos(limit(carouselPos + 1));
					}}
				>
					<FaChevronRight />
				</button>
			</div>

			<div className="woman" style={{ position: "absolute", zIndex: 1, bottom: 0, maxWidth: "50%" }}>
				<picture>
					<source srcSet={GirlWebp} type="image/webp" />
					<source srcSet={Girl} type="image/png" />
					<img src={Girl} alt="slider1" style={{ width: "60%", float: "right" }} loading="lazy" />
				</picture>
			</div>

			<div className="content " style={{ position: "absolute" }}>
				<ul className="slider" ref={carouselRef}>
					{slides &&
						slides.map((slide, key) => (
							<li className={`slide ${key === carouselPos ? "active" : ""}`} key={`slide_${key}`}>
								<div className="slide-content position-center">{slide}</div>
							</li>
						))}
				</ul>
			</div>
		</div>
	);
}

export default CarouselC;
