import React, { useState, useEffect } from "react";
import { setTimeDisplayed, setTimeMeridian, getTimeDisplayed, getTimeMeridian } from "globals/claims";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "./TimePickerV2.scss";

/**
 *
 * @param {Object} props
 * @param {import("react-select").Styles=} props.customStyle
 */
const TimePickerV2 = ({ customStyle }) => {
	const dispatch = useDispatch();
	const meridian = useSelector(getTimeMeridian);
	const timePicked = useSelector(getTimeDisplayed);
	const [mer, changeMer] = useState(meridian || "");
	const [hour, setHour] = useState(timePicked?.substr(0, 2) || "");
	const [minute, setMinute] = useState(timePicked?.substr(3, timePicked.length) || "");
	const [timeChanged, setTimeChanged] = useState(false);
	const hourArray = [];
	for (let i = 1; i <= 12; i++) {
		hourArray.push(i);
	}
	const hours = hourArray.map((hours, i) => {
		return { value: `${hours < 10 ? 0 : ""}${hours}`, label: `${hours < 10 ? 0 : ""}${hours}` };
	});

	const minutesArray = [];
	for (let i = 0; i <= 59; i++) {
		minutesArray.push(i);
	}
	const minutes = minutesArray.map((minutes, i) => {
		return { value: `${minutes < 10 ? 0 : ""}${minutes}`, label: `${minutes < 10 ? 0 : ""}${minutes}` };
	});

	/** @type {import("react-select").Styles} */
	const customStyles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isSelected ? "#002868" : state.isFocused ? "#adcdff" : "#f0f3f6",
			color: state.isSelected ? "#fff" : "#212529",
			fontWeight: "normal",
			padding: "0.5rem 1.3rem",
		}),
		singleValue: (_, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "";
			const style = {
				borderStyle: "none",
				fontWeight: 800,
			};

			return { ...style, opacity, transition };
		},
		control: provided => ({
			...provided,
			color: "#212529",
			width: "100%",
			height: 33.3,
			padding: "0.5rem .5rem",
			borderRadius: "0",
			border: "none",
			backgroundColor: "#fff",
		}),
		dropdownIndicator: provided => ({
			...provided,
			opacity: 1,
			fontSize: 12,
			color: "#f0f3f6",
			position: "absolute",
			right: -3,
			display: "grid",
			gridTemplateColumns: "1fr",
			bottom: -11,
			justifyContent: "center",
		}),
		indicatorSeparator: provided => ({
			...provided,
			visibility: "hidden",
		}),
		placeholder: () => ({
			fontWeight: "bold",
			opacity: 0.5,
		}),
		input: () => ({
			marginTop: 0,
		}),
		valueContainer: provided => ({
			...provided,
			padding: "1px 14px",
		}),
	};

	const changeHour = e => {
		// const newTime = `${hour}:${minute}`;
		if (e.value !== undefined) setHour(e.value);
		setTimeChanged(true);

		// dispatch(setTimeDisplayed(newTime));
	};
	const changeMinute = e => {
		// const newTime = `${hour}:${minute}`;
		if (e.value !== undefined) setMinute(e.value);
		setTimeChanged(true);
		// dispatch(setTimeDisplayed(newTime));
	};

	const switchAMPM = e => {
		if (e.value !== undefined) {
			dispatch(setTimeMeridian(e.value));
			changeMer(e.value);
		}

		setTimeChanged(true);
	};
	useEffect(() => {
		if (timeChanged) {
			const newTime = `${hour}:${minute}`;
			dispatch(setTimeDisplayed(newTime));
			setTimeout(() => {
				setTimeChanged(false);
			}, 1000);
		}
	}, [timeChanged, dispatch, hour, minute]);

	return (
		<div className="timer-wrapper">
			<div className="fieldset-row">
				<label className="time-field">
					<Select
						name="hour"
						options={hours}
						placeholder="hh"
						value={hour ? { value: hour, label: hour } : null}
						styles={customStyle || customStyles}
						onChange={changeHour}
						classNamePrefix="react-select"
					/>
				</label>
				<span className="divider">:</span>
				<label className="time-field">
					<Select
						name="minute"
						placeholder="mm"
						options={minutes}
						value={minute ? { value: minute, label: minute } : null}
						styles={customStyle || customStyles}
						onChange={changeMinute}
						classNamePrefix="react-select"
						className="react-select-container"
					/>
				</label>
				{!customStyle && (
					<label className="time-field">
						<Select
							name="meredian"
							placeholder="AM/PM"
							options={[
								{ value: "AM", label: "AM" },
								{ value: "PM", label: "PM" },
							]}
							value={mer ? { value: mer, label: mer } : null}
							styles={customStyle || customStyles}
							onChange={switchAMPM}
							classNamePrefix="react-select"
							className="react-select-container"
						/>
					</label>
				)}
			</div>
		</div>
	);
};

export default TimePickerV2;
