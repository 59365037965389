import { createSlice } from "@reduxjs/toolkit";
import { fullLogout } from "globals/misc";

const defaultState = {
	motorPolicyQuestionsAndResponses: [
		{
			question: `Have you or anyone driving your vehicle had any accidents in the past 12 months that has not been reported to Advantage General (regardless of fault)?`,
			answer: null,
		},
		{
			question: `Does the owner of the vehicle reside outside of Jamaica for more than six (6) months each year?`,
			answer: null,
		},
		{
			question: `Have you or any relative or close associate been entrusted with prominent public function (e.g. Politician, Senior Government, Judicial or Security Force Officials) in any Country?`,
			answer: null,
		},
		{
			question: `Do you or any driver have physical disability or infirmity that may impair your ability to drive?`,
			answer: null,
		},
		{
			question: `Is the owner of the vehicle deceased?`,
			answer: null,
		},
		
	],
	homePolicyQuestionsAndResponses: [
		{
			question: `Have you had any loss in the past 12 months that has not been reported to Advantage General (regardless of fault)?`,
			answer: null,
		},
		{
			question: `Does the owner of the property reside outside of Jamaica for more than six (6) months each year?`,
			answer: null,
		},
		{
			question: `Have you or any relative or close associate been entrusted with prominent public function (e.g. Politician, Senior Government, Judicial or Security Force Officials) in any Country?`,
			answer: null,
		},
		{
			question: `Is the owner of the property deceased?`,
			answer: null,
		}, 
	],
	sourceType: "",
	brokerPolicyCreated:{},

};

let renewalSlice = createSlice({
	name: "renewal",
	initialState: defaultState,
	reducers: { 
		setHomePolicyQuestionsAndResponses: (state, action) => {
			state.homePolicyQuestionsAndResponses = action.payload;
		},
		setMotorPolicyQuestionsAndResponses: (state, action) => {
			state.motorPolicyQuestionsAndResponses = action.payload;
		},
		setSourceType: (state, action) => {
			state.sourceType = action.payload;
		},
		setBrokerPolicyCreated: (state, action) => {
			state.brokerPolicyCreated = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(fullLogout, (state, action) => defaultState);
	},
});

export const getMotorPolicyQuestionsAndResponses = ({ renewal: state }) => state.motorPolicyQuestionsAndResponses;
export const getHomePolicyQuestionsAndResponses = ({ renewal: state }) => state.homePolicyQuestionsAndResponses;
export const getSourceType = ({ renewal: state }) => state.sourceType;
export const getBrokerPolicyCreated= ({ renewal: state }) => state.brokerPolicyCreated;
export const { setMotorPolicyQuestionsAndResponses, setHomePolicyQuestionsAndResponses, setSourceType, setBrokerPolicyCreated} = renewalSlice.actions;
export default renewalSlice.reducer;
 