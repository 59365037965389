import { nodeApi } from "api/Middleware";
import store from "../../globals/index";

/**
 * Makes a network request to compress a file
 * @param {Object} params
 */
export async function compressPDF({ endpoint, payload },headers = {}) {
	let bearerToken = store.getState().auth.token;
	let response = {};

	const jwt_auth = process.env.REACT_APP_JWT_AUTHENTICATION;

	if (jwt_auth && bearerToken) {
		headers['Authorization'] = "Bearer " + bearerToken;
	}

    await nodeApi
	.post(endpoint, payload, {
		headers,
	})
        .then(res => {
            response = res.data;
        })
        .catch(error => {
            if (error.response) return error.response;
        });
	const data = response;
	if (!data.success) {
		return null;
	}
	return data.data;
}

/**
 * Makes a network request to compress a file
 * @param {Object} params
 */
 export async function createEFT({ endpoint, payload }, config = {}) {
	let response = {};

    await nodeApi
        .post(endpoint, payload, config)
        .then(res => {
            response = res;
        })
        .catch(error => {
            if (error.response) return error.response;
        });
	const data = response;
	if (data.success==false) {
		return data.message;
	}
	return data.data;
}