import React, { useEffect } from "react";
import { getServiceProvider, setClaimRepairsEstimateFiles, setCurrentBlob, setCurrentBlobModal } from "globals/claims";
import { setLoading, setLoadingMessage } from "globals/overlays";
import { useDropzone } from "react-dropzone";
import { BiUpload } from "react-icons/bi";
import { IoIosCloseCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getClaimsRepairsEstimateFiles } from "globals/claims";
import { toBase64 } from "../../helpers/policy/index.js";
import "./CustomFileUpload.scss";
import { toast } from "react-toastify";
import upload_scd_active from "assets/img/claims_icons/upload-scd-active.svg";
import upload_pe from "assets/img/claims_icons/upload-pe.svg";
import ClaimsNetworkLayer from "../../helpers/claim/index.js";
import MarketValue from "./MarketValue/index.jsx";
import delete_btn from "assets/img/claims_icons/delete_icon.svg";
import { FaEye } from "react-icons/fa";
import { IconContext } from 'react-icons';
import { FaExclamationTriangle, FaCheck } from 'react-icons/fa';

import uploadFolder from "assets/img/document-upload-icons/upload_folder_icon.svg";
import uploadFile from "assets/img/document-upload-icons/upload_file_icon.svg";
import trashIcon from "assets/img/document-upload-icons/trash_icon.svg";
import successIcon from "assets/img/document-upload-icons/success_icon.svg";
import pdfUploadIcon from "assets/img/document-upload-icons/pdf_upload_icon.svg";
import imageUploadIcon from "assets/img/document-upload-icons/image_upload_icon.svg";
import expansionIcon from "assets/img/document-upload-icons/expansion_icon.svg";
import dropdownIcon from "assets/img/document-upload-icons/dropdown_icon.svg";
import { useState } from "react";
import { object } from "yup";
import { truncate } from "helpers/globalFunctions";
import { useScreenType } from "globals/screenTypeHook.jsx";

export const MAX_SIZE = 4194304;

//Accepted file types for preview
export const ACCEPTED_PREVIEW_FILE_TYPES = ["pdf", "png", "jpeg", "jpg"];

/**
 *
 * @param {Object} props
 * @param {(e) => void} props.action
 * @param {Object[]} props.files
 * @param {(file, i) => void} props.remove
 * @param {String} props.placeHolder
 * @param {String=} props.description
 * @param {Boolean=}props.isSCDFlow
 * @param {Boolean=}props.isUploadCenter
 * @param {String=}props.sectionTitle
 * @param {Boolean=}props.isMarketValue
 * @param {Boolean=}props.isPolicyEditor
 * @param {Boolean=}props.isPolicyEditorContact
 * @param {String=} props.fileTypeName
 * @param {Number=} props.maxFiles
 * @param {Boolean=} props.disabled
 * @param {Boolean=} props.isMarketValueUpdate
 */
export default function CustomFileUpload({
	action,
	files,
	remove,
	placeHolder,
	description,
	fileTypeName,
	isSCDFlow = false,
	isUploadCenter = false,
	sectionTitle = "",
	isMarketValue = false,
	isPolicyEditor = false,
	isPolicyEditorContact = false,
	isMarketValueUpdate = false,
	maxFiles,
	disabled = false,
}) {
	const serviceProvider = useSelector(getServiceProvider);
	const dispatch = useDispatch();
	let imgPositionIncrement = 0;
	const { name } = serviceProvider;
	const cachedFile = useSelector(getClaimsRepairsEstimateFiles);
	const [uploadDropdown, setUploadDropdown] = useState(false);
	const [documentViewSection, setDocumentViewSection] = useState(false);
	const [charLimit, setCharLimit] = useState(9);
	const { width, screenType, isMobile, isDesktop, isTablet, isLargeDesktop } = useScreenType();

	useEffect(()=>{
		if(screenType === "mobile" || screenType === "small-mobile" || screenType === "medium-mobile"){
			setCharLimit(14);
		}else if(screenType === "medium-mobile" || screenType === "tablet"){
			setCharLimit(16);
		}else {
			setCharLimit(19);
		}
	},[width])

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop: async droppedFiles => {
			let fileUpload = await onDrop(files, droppedFiles, description, fileTypeName);
			action(fileUpload);
			if (isMarketValue) dispatch(setClaimRepairsEstimateFiles(fileUpload[0]));
		},

		onDropRejected: rejectedReason =>
			rejectedReason[0].errors.forEach(err =>
				toast.error(
					err.message.includes("larger")
						? "This document has exceeded the maximum size of 4MB and will not be submitted, please try another"
						: err.message.includes("type")
						? "This file type cannot be uploaded"
						: err.message,
					{ autoClose: 10000 }
				)
			),
		noClick: true,
		maxSize: MAX_SIZE,
		maxFiles: maxFiles,
		disabled,
		accept: "image/png, image/jpeg, application/pdf",
	});


	const previewFileType = file => {
		dispatch(setCurrentBlob(file.blob));
		dispatch(setCurrentBlobModal(true));
	}; 
	const onDrop = async (files, acceptedFiles, description, name) => {
		let temp = [...files];
		for (let i = 0; i < acceptedFiles.length; i++) {
			let file = acceptedFiles[i];
			const fileExt = file.name.split(".").pop().toLowerCase();
			let optimizedFile;
			if (fileExt === "png" || fileExt === "gif" || fileExt === "jpg" || fileExt === "jpeg") {
				dispatch(setLoadingMessage("Compressing your image files..."));
				dispatch(setLoading(true));
				optimizedFile = await ClaimsNetworkLayer.compressImage(file);
			}

			if (fileExt === "pdf") {
				dispatch(setLoadingMessage("Compressing your pdf files..."));
				dispatch(setLoading(true));
				let temp = await toBase64(file);
				optimizedFile = await ClaimsNetworkLayer.compressFile(file, temp);
				// optimizedFile = optimizedFile.replace(new RegExp("^data:.*base64,", "i"), "");
			}

			const blob =
				fileExt === "pdf"
					? optimizedFile
						? optimizedFile
						: await toBase64(file)
					: optimizedFile
					? await toBase64(optimizedFile)
					: await toBase64(file);
			const fileName = name ? name + "." + file.name.split(".").pop() : file.name;

			const size = file.size;
			const newObject = { name: fileName, blob: blob, description, size };

			const index = temp.findIndex(f => f.name === fileName);
			if (index > -1) {
				temp[index] = newObject;
			} else {
				temp = [...temp, newObject];
			}
		}
		dispatch(setLoading(false));
		return temp;
	};

	function limitText(text, charLimit){
		let textLength = text.length;
		if(textLength > charLimit){
			return text.substring(0, charLimit) + '...';
		}else{
			return text;
		}
	}

	return (
		(isUploadCenter || isMarketValue && !isMarketValueUpdate) ?
		<div className="custom-upload-container">
			{files.length > 0 && <img
				className="success-icon"
				src={successIcon} 
				alt="upload folder"
			/>}
			<div 
				className="custom-upload-section"
				style={{
					borderBottom: files.length > 0 ? "1px solid #002868" : "none"
				}}
			>
				<div className="document-to-upload">
					<img
						src={uploadFolder} 
						alt="upload folder"
					/>
					<h6>{description}</h6>
				</div>
				<div className="upload-container">
					<input {...getInputProps()} />   
					<div className={`doc-upload-btn ${disabled ? "disabled" : ""}`} onClick={open}> 
						<img 
							src={uploadFile}
							onClick={()=>{
								open();
								setUploadDropdown(false);
							}}
							alt="upload"
						/>
						<span>Upload Now</span>
					</div>
					<div className="upload-info">
						<small>Maximum upload file size is: 4MB</small>
						<br />
						<small>JPG,PNG,PDF</small>
					</div>
				</div>
			</div> 
			{
				files.length > 0 &&
				<div className="uploaded-docs-section">
					<div className="dropdown-section">
						<span>
							{files.length}
							{" "}
							Uploads
						</span>
						<div 
							onClick={()=>{
								setUploadDropdown((prev)=>{
									return !prev;
								})
							}}
							className="dropdown-icon-container"
						>
							<div
							style={{
								position:'relative',
								display:uploadDropdown ? "none" : "block"
							}}
							> 
								{files?.slice(0, 3).map((file, index) => { 
									const defaultDisplayAmt = 3, absoluteRightPosition = 25;
									let remainderSize = files?.length - 3;  
									let zIndexValue = defaultDisplayAmt - index;
									imgPositionIncrement += index === 0 ? 0 : absoluteRightPosition; 

									let img = file.name.split(".").pop().toLowerCase() != "pdf" ? <img
													className="mini-preview"
													src={file.blob}
													alt="upload preview"
													style={{
														position: "absolute",
														right: `${imgPositionIncrement}px`,
														zIndex: zIndexValue
													}}
													// onClick={
													// 	ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
													// 		? () => previewFileType(file)
													// 		: null
													// }
												/>  : <iframe 
												className="mini-preview"
												src={file.blob}  
												style={{
													position: "absolute",
													right: `${imgPositionIncrement}px`,
													zIndex: zIndexValue
												}}
												// onClick={
												// 	ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
												// 		? () => previewFileType(file)
												// 		: null
												// }
											>
											</iframe>
									return (
										defaultDisplayAmt === (index+1) && remainderSize > 0 ? 
											<div
												style={{
													position: "relative"
												}}
											>
												<div id="overlay">
													<div id="text">{remainderSize}+</div>
												</div>
												{img}
											</div> :
											img
										)
								})}
							</div>
							<img 
								className={`dropdown-animation ${uploadDropdown ? "open" : "--close"}`}
								src={dropdownIcon} 
								alt="dropdown"
							/>
						</div>
					</div>
					<div 
						className={`doc-display-section ${uploadDropdown ? "show" : "hide"}`} 
					>
						{files?.map((file, i) => (
							<div key={`wind-file-item-${file.name}${i}`} className="uploaded-docs">
								<div className="uploaded-doc-info">
									<img
										src={
											file.name.split(".").pop().toLowerCase() === "pdf" 
											? pdfUploadIcon
											: imageUploadIcon
										} 
										alt="upload folder"
									/> 
									<p>{file.name}</p>
									{/* <>{file.name.split(".").pop().toLowerCase()}</> */}
								</div>
								<div className="doc-preview"> 
									<div className="preview-window"> 
										<img
											className="expand-btn"
											src={expansionIcon} 
											alt="expansion icon"
											onClick={
												ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
													? () => previewFileType(file)
													: null
											}
										/>  
										{
											file.name.split(".").pop().toLowerCase() === "pdf"?
											<iframe 
												className="--preview" 
												src={file.blob}  
												onClick={
													ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
														? () => previewFileType(file)
														: null
												}
											>
											</iframe>
											:<img
												className="--preview"
												src={file.blob}
												alt="upload preview"
												onClick={
													ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
														? () => previewFileType(file)
														: null
												}
											/> 
										}
										
										<img
											className="delete-btn"
											src={trashIcon} 
											alt="delete icon"
											onClick={() => remove(file, i)} 
										/>  
									</div> 
								</div>
							</div>
						))}
					</div>
				</div>
			}
			
		</div>
		:<div
			className={`${
				isSCDFlow
					? "custom-file-upload-scd"
					: isMarketValue
					? "custom-file-upload-mv"
					: isPolicyEditor
					? "custom-file-upload-pe"
					: isPolicyEditorContact
					? "custom-file-upload-pe contact"
					: (isUploadCenter || isMarketValue || isMarketValueUpdate)
					? ""
					: "custom-file-upload"
			}`}
			style={{
				backgroundColor: isSCDFlow && files.length > 0 ? "#f0f3f6" : "#fff",
				border: isSCDFlow && files.length ? "1px solid #f0f3f6" : "1px solid #fff",
			}}
		> 
			{isSCDFlow ? (
				<>
					<div
						{...getRootProps({
							className: `upload-area-scd ${disabled ? "upload-area-scd-disabled" : ""}`,
						})}
					>
						<input {...getInputProps()} />
						<div className="cnd-browse-files-scd" onClick={open}>
							<img className={"scd-upload"} src={upload_scd_active} onClick={open} alt="upload" />
							<span className={disabled ? "upload-placeholder-disabled" : "upload-placeholder"}>{placeHolder}</span>
						</div>
						{/* <BiUpload color={${disabled ? "" : ""}} onClick={open} className="btn-icon" title="Upload" /> */}
					</div>
					{files.length === 0 && (
						<div style={{ textAlign: "right" }}>
							<small>Maximum upload file size is: 4MB</small>
							<br />
							<small>JPG,PNG,PDF</small>
						</div>
					)}
				</>
			) : false ? (
				<MarketValue files={files} getInputProps={getInputProps} open={open} remove={remove} disabled={disabled} />
			) : (isPolicyEditor || isPolicyEditorContact) ? (
				<>
					<div
						{...getRootProps({
							className: `upload-area-pe ${disabled ? "upload-area-pe-disabled" : ""}`,
						})}
					>
						<input {...getInputProps()} />
						<div className="cnd-browse-files-pe" onClick={open}>
							<img
								className={"pe-upload"}
								src={isPolicyEditorContact ? upload_scd_active : upload_pe}
								onClick={open}
								alt="upload"
							/>
							<span className={disabled ? "upload-placeholder-disabled" : "upload-placeholder"}>{placeHolder}</span>
						</div>
						{/* <BiUpload color={${disabled ? "" : ""}} onClick={open} className="btn-icon" title="Upload" /> */}
					</div>
				</>
			) : ((!isUploadCenter || !isMarketValue) && !isMarketValueUpdate) && (
				<div
					{...getRootProps({
						className: `cnd-browse-files upload-area ${disabled ? "disabled" : ""}`,
					})}
				>
					<input {...getInputProps()} />
					<span className="upload-placeholder">{placeHolder}</span>
					<span className={`upload-button ${disabled ? "disabled" : ""}`} onClick={open}>
						<BiUpload className="btn-icon" title="Upload" />
						Upload
					</span>
				</div>
			)}
			{(!isMarketValue && !isUploadCenter && !isMarketValueUpdate) && (
				<>
					{name === "Other"
						? Object.keys(files).length === 0 && <div className="blue-box"> </div>
						: files?.length === 0 && <div className="blue-box"> </div>}
				</>
			)}

			{isSCDFlow && files.length !== 0 && (!isMarketValueUpdate && !isMarketValue && !isUploadCenter) && (
				<div className="input-verbiage">
					<small className="confirm-verbiage"> {files.length} Document(s)</small>
					<div style={{ textAlign: "right" }}>
						<small>Maximum upload file size is: 4MB</small>
						<br />
						<small>JPG,PNG,PDF</small>
					</div>
				</div>
			)}

			{(!isMarketValue && !isUploadCenter && !isMarketValueUpdate) && (
				<>
					{(!isSCDFlow && !isUploadCenter) && (
						<>
							{!isPolicyEditor && !isPolicyEditorContact && (
								<div className="input-verbiage">
									<small className="confirm-verbiage"> {files.length} Document(s)</small>
									<div style={{ textAlign: "right" }}>
										<small>Maximum upload file size is: 4MB</small>
										<br />
										<small>JPG,PNG,PDF</small>
									</div>
								</div>
							)}
						</>
					)}
				</>
			)}

			{(!isMarketValue && !isUploadCenter && !isMarketValueUpdate) && (
				<div style={{ border: "none" }} className="file">
					{files?.map((file, i) => (
						<>
							<div
								key={`wind-file-item-${file.name}${i}`}
								className={"files " + (files.length > 1 && i > 0 ? "files-border" : "")}
							>
								<div 
									className="uploaded-container"
									onClick={
										ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
											? () => previewFileType(file)
											: null
									}
								>
									
									<p
										style={{margin:0, alignSelf:"center", color:"#002868"}}
										className={`${
											ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
												? "filename-clickable"
												: "filename"
										} text-truncate`} 
									>
										{" "}
										({i + 1}) {file.name}
									</p>
								</div> 
								<div className="btn-container"> 
									<FaEye
										onClick={
											ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
												? () => previewFileType(file)
												: null
										} 
										className="preview-icon" 
									/> 
									<div style={{borderLeft: "2px solid #00bfff", height: "35px"}}></div>
									<img src={delete_btn} style={{cursor:"pointer"}} alt="delete" onClick={() => remove(file, i)} />
								</div> 
							</div>
						</>
					))}
				</div>
			)}


			{(isUploadCenter && !isMarketValueUpdate) && (<div className="document-center-upload-container">
					<div style={{display:"block"}}>
						<div className="upload-button-block">
							<div className="d-flex align-self-end">
								{
									files.length ?
										<IconContext.Provider
											value={{
												color: "#c4d600",
												size: "3em",
												style: { verticalAlign: "middle", marginLeft: "45%", marginBottom: 10, transform: "translateX(-40%)" },
											}}
										>
											<div>
												<FaCheck contentStyleType={"fad"} />
											</div>
										</IconContext.Provider>
									:	<IconContext.Provider
											value={{
												color: "#ffa000",
												size: "3em",
												style: { verticalAlign: "middle", marginLeft: "45%", marginBottom: 10, transform: "translateX(-40%)" },
				
											}}
										>
											<div>
												<FaExclamationTriangle contentStyleType={"fad"} />
											</div>
										</IconContext.Provider>
								} 
								<p className="title align-self-center">{sectionTitle}</p>
							</div>

							<div
								{...getRootProps({
									className: `d-flex align-self-start cnd-browse-files upload-area ${disabled ? "disabled" : ""}`,
								})}
							>
								<input {...getInputProps()} />  
								<span className="upload-placeholder">{placeHolder}</span>
								<span className={`upload-button ${disabled ? "disabled" : ""}`}  onClick={open}>
									<BiUpload className="btn-icon" title="Upload" />
									Upload
								</span>
							</div> 
						</div>
					</div>
					

				
					<div style={{ border: "none" }} className="file">
						{files?.map((file, i) => (
							<>
								<div
									key={`wind-file-item-${file.name}${i}`}
									className={"files " + (files.length > 1 && i > 0 ? "files-border" : "")}
								>
									<div 
										className="uploaded-container"
										onClick={
											ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
												? () => previewFileType(file)
												: null
										}
									>
										
										<p
											style={{margin:0, alignSelf:"center", color:"#002868"}}
											className={`${
												ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
													? "filename-clickable"
													: "filename"
											} text-truncate`} 
										>
											{" "}
											({i + 1}) {file.name}
										</p>
									</div> 
									<div className="btn-container"> 
										<FaEye
											onClick={
												ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
													? () => previewFileType(file)
													: null
											} 
											className="preview-icon" 
										/> 
										<div style={{borderLeft: "2px solid #00bfff", height: "35px"}}></div>
										<img src={delete_btn} style={{cursor:"pointer"}} alt="delete" onClick={() => remove(file, i)} />
									</div> 
								</div>
							</>
						))}
					</div>
		 
					<div className="input-verbiage"> 
						<div style={{ textAlign: "right" }}>
							<small>Maximum upload file size is: 4MB</small>
							<br />
							<small>JPG,PNG,PDF</small>
						</div>
					</div> 
			</div>
		)} 

		{(isMarketValueUpdate) && (
			<div className={` d-sm-flex justify-content-between`} style={{ minHeight: "50px", height: "auto", borderRadius: "25px", background:"#97dadb", padding:"5px", position: "relative", display: "flex"}}>
				<div className="px-2"  style={{ width: "100%", textAlignLast: "left", height: "fit-content", margin: "auto 0" }}>
					{files?.length === 0 ? (
						<span style={{ color: "#fff", fontWeight: "bold", marginBottom:0, fontSize: "18px" }}>Files</span>
					) : (
						<div> 
							<div style={{ color: "#fff" }}>
								<div
									style={{cursor:"pointer", marginRight:"40px"}}
									onClick={()=>setDocumentViewSection((prev)=>!prev)}
								>
									<span style={{ color: "#fff", fontWeight: "bold", marginBottom:0, fontSize: "13px" }}>Files:{ documentViewSection ? ` ▲ Hide ${files.length > 1 ? ` (${files.length}) uploaded files` : `uploaded file`}` : ` ▼ View  ${files.length > 1 ? `(${files.length}) uploaded files` : `uploaded file`}` }</span>
								</div> 
							</div>
							<div style={{display:documentViewSection ? "block" : "none", paddingTop:"15px"}}>
								{files?.map((file, index)=>{ 
								return	<div
											className="d-flex justify-content-between py-1" 
											style={{
												fontSize:"13px"
											}}
										>
											<p className="font-weight-bold text-center" style={{ margin:"0", color: "#fff" }}>
												{/* {" - "} {truncate(file?.name?.split(".")?.slice(0, -1).join(), 15)}.{file?.name?.split(".").pop()} */}
												({index + 1}) {limitText(file.name, charLimit)} 
											</p>
											<picture>
												<source src={delete_btn} type="image/svg+xml" />
												<img
													onClick={() => {
														remove(file, index)
														// let temp = marketValues.splice(index, 1);
														// dispatch(setClaimRepairsEstimateFiles(temp));
													}}
													src={delete_btn}
													alt="delete-icon"
													style={{ height: 20, cursor:"pointer" }}
												/> 
												<span style={{color:"white"}}>{" | "}</span>
												<FaEye
													onClick={
														ACCEPTED_PREVIEW_FILE_TYPES.includes(file.name.split(".").pop().toLowerCase())
															? () => previewFileType(file)
															: null
													} 
													className="---preview-icon" 
													style={{ height: 20, margin:0, cursor:"pointer" }}
												/> 
											</picture>
										</div>
								})}
							</div>
						</div>
					)}
				</div>
				<div 
					className="m-0" style={{ cursor:"pointer", position: "absolute", right: "5px", top: "5px", background: "white", borderRadius: "100%", width: "40px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center"}}
					onClick={open}
				>
					<input {...getInputProps()} />  
					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 14.5006C10.1993 14.5006 10.39 14.4215 10.5307 14.2809C10.6713 14.1402 10.7504 13.9495 10.7504 13.7506V2.77756L12.4304 4.73856C12.5598 4.88973 12.744 4.9833 12.9424 4.99868C13.1408 5.01405 13.3372 4.94998 13.4884 4.82056C13.6395 4.69113 13.7331 4.50695 13.7485 4.30854C13.7639 4.11013 13.6998 3.91373 13.5704 3.76256L10.5704 0.262558C10.5 0.180223 10.4125 0.114119 10.3141 0.0687945C10.2157 0.0234697 10.1087 0 10.0004 0C9.89203 0 9.78498 0.0234697 9.68658 0.0687945C9.58818 0.114119 9.50077 0.180223 9.43036 0.262558L6.43036 3.76256C6.36628 3.83741 6.31756 3.92415 6.287 4.01783C6.25644 4.11151 6.24463 4.2103 6.25224 4.30854C6.25986 4.40678 6.28675 4.50257 6.33138 4.59042C6.37601 4.67827 6.43751 4.75647 6.51236 4.82056C6.58722 4.88464 6.67396 4.93336 6.76764 4.96392C6.86132 4.99448 6.9601 5.00629 7.05835 4.99868C7.15659 4.99106 7.25237 4.96417 7.34022 4.91954C7.42808 4.87491 7.50628 4.81341 7.57036 4.73856L9.25036 2.77856V13.7506C9.25036 14.1646 9.58636 14.5006 10.0004 14.5006Z" fill="#003A97"/>
						<path d="M14 7.75098C13.298 7.75098 12.947 7.75098 12.694 7.91998C12.5852 7.99276 12.4918 8.0862 12.419 8.19498C12.25 8.44798 12.25 8.79898 12.25 9.50098V13.751C12.25 14.3477 12.0129 14.92 11.591 15.342C11.169 15.7639 10.5967 16.001 10 16.001C9.40326 16.001 8.83097 15.7639 8.40901 15.342C7.98705 14.92 7.75 14.3477 7.75 13.751V9.50098C7.75 8.79898 7.75 8.44798 7.581 8.19498C7.50821 8.0862 7.41478 7.99276 7.306 7.91998C7.053 7.75098 6.702 7.75098 6 7.75098C3.172 7.75098 1.757 7.75098 0.879 8.62998C-5.96046e-08 9.50798 0 10.921 0 13.75V14.75C0 17.58 -5.96046e-08 18.993 0.879 19.872C1.757 20.751 3.172 20.751 6 20.751H14C16.828 20.751 18.243 20.751 19.121 19.872C20 18.993 20 17.579 20 14.751V13.751C20 10.922 20 9.50798 19.121 8.62998C18.243 7.75098 16.828 7.75098 14 7.75098Z" fill="#003A97"/>
					</svg>
				</div> 
			</div> 
		)
		
		}


		</div>
	);
}


















































































































