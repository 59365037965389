import React from "react";
import get from "lodash.get";

/**
 * @typedef {() => Promise<{[name: string]: *}>} THN
 * @typedef {(thenable: THN, name?: string) => React.LazyExoticComponent<React.ComponentType<*>>} LazyNamed
 */

/**
 * Improvement on top of React.lazy() that allows lazy rendering of named imports
 * Usage:
 *
 *  -- alternative to import { primary } from './Button'
 *  const PrimaryButton = lazier(() => import('./Button'), 'primary');
 *
 *  -- get the default import
 *  lazier(() => import('./Button'), 'default');
 *
 *  -- or
 *  lazier(() => import('./Button'));
 *
 * @param {() => Promise<*>} thenable
 * @param {string=} [name="default"]
 * @type {LazyNamed}
 */
const lazier = (thenable, name = "default") => React.lazy(() => thenable().then(mod => ({ default: get(mod, name) })));

export default lazier;
