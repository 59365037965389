import React from "react";
import { NavLink } from "react-router-dom";

function CustomNavLink({ to, children }) {
	return (
		<NavLink className={`link`} to={to} exact={false}>
			{children}
		</NavLink>
	);
}

export default CustomNavLink;
