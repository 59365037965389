import { AuthNetworkLayer } from "helpers";
import { setToken } from "../../globals/auth";
import { store } from "../../globals/index";
import { dNet, nodeApi } from "api/Middleware";
import { useDispatch } from "react-redux";
import PolicyNetworkLayer from "helpers/policy/network";
import { differenceInDays, parse } from "date-fns";
import { calculateBalance } from "components/PayBalanceProcess/PolicyListing/functions";

const MAX_ATTEMPTS = 3;

/*
 * Fetch and set the Token in redux state
 * @param {String} authType
 * @param {{dob: String, gender: String, national_id: String, national_id_type: String}} param1
 */
export async function useSetGlobalToken(authType, { dob, gender, national_id, national_id_type }) {
	const dispatch = useDispatch();
	dispatch(setToken((await getNewGlobalToken(3)).token));
}

/**
 * Makes a network request requiring a token and will attempt to
 * retry a failed request up to [MAX_ATTEMPTS] time(s) if the request
 * fails because of Bad Key or Invalid Token
 * @param {Object} params
 */
export async function checkBadKey({ endpoint, payload, headers }) {
	const token = store.getState().auth.token;

	let response = await nodeApi.post(endpoint, payload, { headers: { ...headers } }).catch(error => {
		if (error.response) return error.response;
	});

	const data = response.data;

	if (!data.success) {
		let error = data.error_message || data.Info || data.Details || "";
		error = error.toLowerCase();

		if (
			error.includes("bad") ||
			error.includes("expired") ||
			error.includes("invalid authorization token") ||
			error.includes("json data failed") ||
			error.includes("failed to authenticate")
		) {
			let request = await getNewGlobalToken(MAX_ATTEMPTS);
			if (!request.success) return request;

			let newResponse = await nodeApi.post(endpoint, payload, {
				headers: { ...headers, "x-auth-token": request.token },
			});
			return newResponse.data;
		}
	}

	return data;
}

/**
 * Makes a network request requiring a token and will attempt to
 * retry a failed request up to [MAX_ATTEMPTS] time(s) if the request
 * fails because of Bad Key or Invalid Token
 * @param {Object} params
 */
export async function checkKey({ endpoint, payload, reqType = "post" }, config = {}) {
	const token = store.getState().auth.token;

	let response = {};

	if (reqType.includes("post")) {
		await dNet
			.post(endpoint, payload, config)
			.then(res => {
				response = res.data;
			})
			.catch(error => {
				if (error.response) return error.response;
			});
	} else {
		await dNet
			.get(endpoint, payload)
			.then(res => {
				response = res.data;
			})
			.catch(error => {
				if (error.response) return error.response;
			});
	}
	const data = response;
	if (!data.success) {
		let error = data.error_message || data.Info || data.Details || "";
		error = error.toLowerCase();
		if (
			error.includes("bad") ||
			error.includes("expired") ||
			error.includes("invalid authorization token") ||
			error.includes("json data failed") ||
			error.includes("failed to authenticate")
		) {
			let request = await getNewGlobalToken(MAX_ATTEMPTS);
			if (!request.success) return request;

			if (reqType.includes("get")) payload.params.token = request.token;

			let newResponse = reqType.includes("post")
				? await dNet.post(endpoint, payload)
				: await dNet.get(endpoint, payload);
			return newResponse.data;
		}
	}
	return data;
}

/**
 * This exists because of the API returning unusual/inconsistent data
 *
 * E.g. Sucess {Array{}} instead of success {Boolean}
 *
 * E.g.	Error {Array} instead of error_message {String}
 * @param {Object} params
 */
export async function checkWeirdBadKey({ endpoint, payload, reqType = "post" }) {
	const token = store.getState().auth.token;
	let response = {};

	if (reqType.includes("post")) {
		await dNet
			.post(endpoint, payload)
			.then(res => {
				response = res.data;
			})
			.catch(error => {
				if (error.response) return error.response;
			});
	} else {
		await dNet
			.get(endpoint, payload)
			.then(res => {
				response = res.data;
			})
			.catch(error => {
				if (error.response) return error.response;
			});
	}

	const data = response;

	if (data.success === false || data.success === "false") {
		/**
		 * Data is returned in the Sucess param as an array
		 * ...don't ask why
		 */
		let error = data.error_message || data.Info || data.Details || "";
		error = error.toLowerCase();
		/**
		 * Return early because if error_message doesn't exist, it's not a token error
		 */
		if (!error) return { success: false };

		if (
			error.includes("bad") ||
			error.includes("expired") ||
			error.includes("invalid authorization token") ||
			error.includes("json data failed") ||
			error.includes("failed to authenticate")
		) {
			let request = await getNewGlobalToken(MAX_ATTEMPTS);
			if (!request.success) return request;

			if (reqType.includes("get")) payload.token = request.token;

			let newResponse = reqType.includes("post")
				? await dNet.post(endpoint, payload)
				: await dNet.get(endpoint, payload);
			let newData = newResponse.data;
			if (newData.Error?.length > 0) {
				const error_message = data.Error[0].Error;
				return {
					success: false,
					error_message: error_message,
				};
			} else if (newData.Sucess) {
				/** Spelling is important for this !!! ... */
				const result = newData.Sucess;

				/**
				 * Consider returning receipt_number(s) in the event that a
				 * part of the process fails and user needs to follow up
				 */
				return {
					result,
					success: true,
				};
			}
			return newResponse.data;
		}
	} else if (Object.keys(data).length < 1) {
		console.log("2nd data: ", data);
		return {
			success: false,
			error_message: "No data returned",
		};
	} else if (data?.Error?.length > 0) {
		const error_message = data.Error[0].Error;
		return {
			success: false,
			error_message: error_message,
		};
	} else {
		/** Spelling is important for this !!! ... */
		const result = data.Sucess;

		/**
		 * Consider returning receipt_number(s) in the event that a
		 * part of the process fails and user needs to follow up
		 */
		return {
			result,
			success: true,
		};
	}
}

export const getExtendablePoliciesPaymentPlan = async (policies, token) => {
	let _extendablePolicies = [];
	for (let i = 0; i < policies.length; i++) {
		let policy = policies[i];
		const paymentPlanRequest = await PolicyNetworkLayer.getPolicyPaymentPlan(policy.policy_id);

		let isExtendable = (await paymentPlanRequest?.policy_payment_plan?.payment_plan_name) !== "3/9 Months Payment Plan";

		if (
			paymentPlanRequest.success &&
			paymentPlanRequest.policy_payment_plan &&
			isExtendable &&
			paymentPlanRequest.policy_payment_plan.payment_terms.some(plan => !plan.transaction_id)
		) {
			// find the first payment_term from the start that does not have an attached payment
			const paymentTerm = paymentPlanRequest.policy_payment_plan.payment_terms.find(term => !term.transaction_id);

			// add a late fee if applicable
			let now = new Date();
			let due = parse(paymentTerm.effective_date, "MM/dd/yyyy", now);
			let isLate = differenceInDays(now, due) > 1;
			let lateFee = 0;

			let balance = calculateBalance(
				policy.risks,
				paymentTerm,
				paymentPlanRequest.policy_payment_plan.payment_terms.length,
				policy.tax_percent / 100
			);
			balance.total += lateFee;

			if (isLate) {
				paymentTerm.payment_term_premium_with_tax += lateFee;
			}

			_extendablePolicies.push({
				...policy,
				balance,
				paymentPlan: paymentPlanRequest.policy_payment_plan,
				lateFee,
			});
		}
	}

	return _extendablePolicies;
};

/**
 * Request and Set new token Globally
 * @param {Number} counter - Keeps track of attempts to acquire a new token by calling itself
 */
export async function getNewGlobalToken(counter) {
	if (counter > 0) {
		let loginRequest = await AuthNetworkLayer.login();

		if (!loginRequest.success) {
			getNewGlobalToken(counter - 1);
		} else {
			store.dispatch(setToken(loginRequest.token));
			return {
				success: true,
				token: loginRequest.token,
			};
		}
	} else {
		return {
			success: false,
			error_message: "Unable to fetch new token",
		};
	}
}

/**
 * Check if a token has been expired and attempts to acquire a new token
 * using the refresh token
 * @param {Object} args
 */
export const checkExpToken = async ({ endpoint, payload, headers={}, timeout = null, reqType = "post" }) => {
	let bearerToken = store.getState().auth.token;
	let response = {};

	const jwt_auth = process.env.REACT_APP_JWT_AUTHENTICATION;

	if (jwt_auth && bearerToken) {
		headers['Authorization'] = "Bearer " + bearerToken;
	}

	if (reqType.includes("post")) {
		await nodeApi
			.post(endpoint, payload, {
				headers,
				timeout,
			})
			.then(res => {
				response = res.data;
			})
			.catch(error => {
				if (error.response) return error.response;
			});
	} else if (reqType.includes("put")) {
		await nodeApi
			.put(endpoint, payload, {
				headers,
				timeout,
			})
			.then(res => {
				response = res.data;
			})
			.catch(error => {
				if (error.response) return error.response;
			});
	} else {
		await nodeApi
			.get(endpoint, payload)
			.then(res => {
				response = res.data;
			})
			.catch(error => {
				if (error.response) return error.response;
			});
	}

	const data = response;

	if (data.success === false || data.success === "false") {
		/**
		 * Data is returned in the Sucess param as an array
		 * ...don't ask why
		 */
		let error = data.message || data.Info || data.Details || "";
		error = error.toLowerCase();
		/**
		 * Return early because if error_message doesn't exist, it's not a token error
		 */
		if (!error) return { success: false };


	}
	return data;
};
