// @ts-nocheck
import { useEffect } from "react";
import { useRef } from "react";

/**
 * Live Chat Hook Wrapper
 * @param {Object} props
 * @param {Boolean} props.shouldLoad
 * @param {Number} props.license
 * @param {Number=} props.group
 * @param {()=>void=} props.onChatLoaded
 * @param {[{name: String, value: *}]=} props.params
 * @param {{name: String, email: String}=} props.visitor
 * @param {Boolean=} props.chatBetweenGroups
 * @param {()=>void=} props.onErrorLoading
 * @param {()=>void=} props.onBeforeLoad
 * @param {()=>void=} props.onAfterLoad
 * @param {()=>void=} props.onChatWindowOpened
 * @param {()=>void=} props.onChatWindowMinimized
 * @param {()=>void=} props.onChatWindowHidden
 * @param {()=>void=} props.onChatStateChanged
 * @param {()=>void=} props.onChatStarted
 * @param {()=>void=} props.onChatEnded
 * @param {()=>void=} props.onMessage
 * @param {()=>void=} props.onTicketCreated
 * @param {()=>void=} props.onPrechatSurveySubmitted
 * @param {()=>void=} props.onRatingSubmitted
 * @param {()=>void=} props.onRatingCommentSubmitted
 *
 */
export default function UseLiveChat({
	shouldLoad = false,
	license,
	group = 0,
	onChatLoaded,
	params,
	visitor,
	chatBetweenGroups,
	onErrorLoading = () => {},
	onBeforeLoad,
	onAfterLoad,
	onChatWindowOpened,
	onChatWindowMinimized,
	onChatWindowHidden,
	onChatStateChanged,
	onChatStarted,
	onChatEnded,
	onMessage,
	onTicketCreated,
	onPrechatSurveySubmitted,
	onRatingSubmitted,
	onRatingCommentSubmitted,
}) {
	const mounted = useRef(false);
	useEffect(() => {
		function loadLiveChatApi() {
			if (!window.LC_API) {
				window.__lc = window.__lc || {};
				window.__lc.license = license;
				window.__lc.group = group;
				window.__lc.chat_between_groups = chatBetweenGroups;
				window.__lc.params = params;
				window.__lc.visitor = visitor;
				const lc = document.createElement("script");
				lc.type = "text/javascript";
				lc.async = true;
				lc.defer = true;
				lc.src = "https://cdn.livechatinc.com/tracking.js";
				const s = document.getElementsByTagName("script")[0];
				s.parentNode.insertBefore(lc, s);
				lc.addEventListener("load", chatLoaded);
				lc.addEventListener("error", chatNotLoaded);
			}
		}

		function chatLoaded() {
			if (window.LC_API) {
				setCallbacks();
				if (onChatLoaded) onChatLoaded(window.LC_API);
			}
		}

		function chatNotLoaded() {
			if (onErrorLoading) onErrorLoading();
		}

		function setCallbacks() {
			if (onBeforeLoad) window.LC_API.on_before_load = onBeforeLoad;
			if (onAfterLoad) window.LC_API.on_after_load = onAfterLoad;
			if (onChatWindowOpened) window.LC_API.on_chat_window_opened = onChatWindowOpened;
			if (onChatWindowMinimized) window.LC_API.on_chat_window_minimized = onChatWindowMinimized;
			if (onChatWindowHidden) window.LC_API.on_chat_window_hidden = onChatWindowHidden;
			if (onChatStateChanged) window.LC_API.on_chat_state_changed = onChatStateChanged;
			if (onChatStarted) window.LC_API.on_chat_started = onChatStarted;
			if (onChatEnded) window.LC_API.on_chat_ended = onChatEnded;
			if (onMessage) window.LC_API.on_message = onMessage;
			if (onTicketCreated) window.LC_API.on_ticket_created = onTicketCreated;
			if (onPrechatSurveySubmitted) window.LC_API.on_prechat_survey_submitted = onPrechatSurveySubmitted;
			if (onRatingSubmitted) window.LC_API.on_rating_submitted = onRatingSubmitted;
			if (onRatingCommentSubmitted) window.LC_API.on_rating_comment_submitted = onRatingCommentSubmitted;
		}

		if (!mounted.current && shouldLoad) {
			mounted.current = true;
			window.addEventListener("load", loadLiveChatApi);
		}
		return () => window.removeEventListener("load", loadLiveChatApi);
	}, [
		shouldLoad,
		license,
		group,
		onChatLoaded,
		params,
		visitor,
		chatBetweenGroups,
		onErrorLoading,
		onBeforeLoad,
		onAfterLoad,
		onChatWindowOpened,
		onChatWindowMinimized,
		onChatWindowHidden,
		onChatStateChanged,
		onChatStarted,
		onChatEnded,
		onMessage,
		onTicketCreated,
		onPrechatSurveySubmitted,
		onRatingSubmitted,
		onRatingCommentSubmitted,
	]);
}
