import React from "react";
import ReactDOM from "react-dom";
import { store, persistor } from "./globals";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { withSuspense } from "components";
import { lazy } from "helpers";

//Ensure bootstrap css is loaded before application styling so that it can be overriden in most cases
import "normalize.css";
import "./bootstrap.min.css";
import "animate.css";
import "./index.scss";

const MaxLoader = <div className="suspense-loader max">Loading...</div>;

const Provider = withSuspense(
	lazy(() => import("react-redux"), "Provider"),
	MaxLoader
);
const App = withSuspense(
	lazy(() => import("./App")),
	MaxLoader
);

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

import("./serviceWorker").then(serviceWorker => serviceWorker.unregister());
