import { createSlice } from "@reduxjs/toolkit";
import { changePolicy, fullLogout } from "globals/misc";

const defaultState = {
	sideBarInfo: {
		mode: "",
		quicktip: { externalContent: "", quicktipContainerContent: "", styling: "" }
	},
	previousNavigationLink: {
		pageName: "",
		link: "",
		selectedPolicyEditorTab: {
			mainFlowIndex: null,
			subFlowIndex: null
		}
	},
	payBalanceUserProgress: true,
	missingDocuments: [],
	notification: [],
	userData: {},
	currentUser: {},
	policyHolders: [],
	isLoggedIn: false,
	userId: "",
	token: "",
	redirectTarget: "/",
	policies: [],
	promoCodes: [],
	renewablePolicies: [],
	extendablePolicies: [],
	coverNotePolicies: [],
	fetchExtendableSuccessfully: false,
	activePolicy: {},
	activePolicies: [],
	activeRisk: {},
	breakdown: {},
	otherCalculations: {},
	authFormInfo: {
		TRN: null,
		DOB: null,
		gender: null,
	},
	stickyNavHeaderVisible: null,
	valuationStatus: null,
	halfPayment: {},
	paymentPlans: [],
	paymentType: {},
	persistUser: false,
	transactionType: null,
	policyPaymentPlan: {},
	missingUserData: [],
	noLossDeclarationStatus: false,
	hasMotorPolicies: false,
	hasHomePolicies: false,
	hasLiabilityPolicies: false,
	selectedSession: {
		isSessionCreated: false,
		sessionId: null
	},

};

let authSlice = createSlice({
	name: "auth",
	initialState: defaultState,
	reducers: {
		login: state => {
			state.isLoggedIn = true;
		},
		setNotification: (state, action) => {
			state.notification = action.payload;
		},
		setSideBarInfo: (state, action) => {
			state.sideBarInfo = action.payload;
		},
		setPayBalanceUserProgress: (state, action) => {
			state.payBalanceUserProgress = action.payload;
		},
		setPreviousNavigationLink: (state, action) => {
			state.previousNavigationLink = action.payload;
		},
		setMissingDocuments: (state, action) => {
			state.missingDocuments = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setStickyNavHeaderVisible: (state, action) => {
			state.stickyNavHeaderVisible = action.payload;
		},
		setRedirectTarget: (state, action) => {
			state.redirectTarget = action.payload;
		},
		setTransactionType: (state, action) => {
			state.transactionType = action.payload;
		},
		setValuationStatus: (state, action) => {
			state.valuationStatus = action.payload;
		},
		setUserFormData: (state, action) => {
			state.userData = action.payload;
		},
		setExtendablePolicies: (state, action) => {
			state.extendablePolicies = action.payload;
		},
		setCurrentUser: (state, action) => {
			state.currentUser = action.payload;
		},
		setPolicyHolders: (state, action) => {
			state.policyHolders = action.payload;
		},

		setExtendablePoliciesFetched: (state, action) => {
			state.fetchExtendableSuccessfully = action.payload;
		},
		setPaymentType: (state, action) => {
			state.paymentType = action.payload;
		},
		setUserId: (state, action) => {
			state.userId = action.payload;
		},
		setPolicies: (state, action) => {
			state.policies = action.payload;
		},
		setActivePolicies: (state, action) => {
			state.activePolicies = action.payload;
		},
		setRenewablePolicies: (state, action) => {
			state.renewablePolicies = action.payload;
		},

		setCoverNotePolicies: (state, action) => {
			state.coverNotePolicies = action.payload;
		},

		setActivePolicy: (state, action) => {
			state.activePolicy = action.payload;
		},

		setActiveRisk: (state, action) => {
			state.activeRisk = action.payload;
		},

		setBreakdown: (state, action) => {
			state.breakdown = action.payload;
		},
		setOtherCalculations: (state, action) => {
			state.otherCalculations = action.payload;
		},
		setAuthFormInfo: (state, action) => {
			state.authFormInfo = action.payload;
		},
		setHalfPayment: (state, action) => {
			state.halfPayment = action.payload;
		},
		setPaymentPlans: (state, action) => {
			state.paymentPlans = action.payload;
		},
		addPromoCode: (state, action) => {
			state.promoCodes.push(action.payload);
		},
		removePromoCodeByIndex: (state, action) => {
			state.promoCodes.splice(action.payload, 1);
		},
		setPersistUser: (state, action) => {
			state.persistUser = action.payload;
		},
		setPolicyPaymentPlan: (state, action) => {
			state.policyPaymentPlan = action.payload;
		},
		setMissingUserData: (state, action) => {
			state.missingUserData = action.payload;
		},
		setNoLossDeclarationStatus: (state, action) => {
			state.noLossDeclarationStatus = action.payload;
		},
		setHasMotorPolicies: (state, action) => {
			state.hasMotorPolicies = action.payload;
		},
		setHasHomePolicies: (state, action) => {
			state.hasHomePolicies = action.payload;
		},
		setHasLiabilitiesPolicies: (state, action) => {
			state.hasLiabilityPolicies = action.payload;
		},
		setSelectedSession: (state, action) => {
			state.selectedSession = action.payload;
		},
		logout: () => defaultState,
	},
	extraReducers: builder => {
		builder.addCase(fullLogout, (state, action) => defaultState);
		builder.addCase(changePolicy, (state, action) => {
			state.breakdown = defaultState.breakdown;
			state.otherCalculations = defaultState.otherCalculations;
			state.halfPayment = defaultState.halfPayment;
			state.paymentPlans = defaultState.paymentPlans;
			state.paymentType = defaultState.paymentType;
		});
	},
});



/** @param {{auth: defaultState}} state */
export const getSideBarInfo = ({ auth: state }) => state.sideBarInfo;

/** @param {{auth: defaultState}} state */
export const getUser = ({ auth: state }) => state.currentUser;

/** @param {{auth: defaultState}} state */
export const getPolicyHolders = ({ auth: state }) => state.policyHolders;

/** @param {{auth: defaultState}} state */
export const getNotification = ({ auth: state }) => state.notification;

/** @param {{auth: defaultState}} state */
export const getPreviousNavigationLink = ({ auth: state }) => state.previousNavigationLink;

/** @param {{auth: defaultState}} state */
export const getPayBalanceUserProgress = ({ auth: state }) => state.payBalanceUserProgress;

/** @param {{auth: defaultState}} state */
export const getMissingDocuments = ({ auth: state }) => state.missingDocuments;

/** @param {{auth: defaultState}} state */
export const getUserFormData = ({ auth: state }) => state.userData;

/** @param {{auth: defaultState}} state */
export const didFetchExtendablePolices = ({ auth: state }) => state.fetchExtendableSuccessfully;

/** @param {{auth: defaultState}} state */
export const getAuthState = ({ auth: state }) => state.isLoggedIn;

/** @param {{auth: defaultState}} state */
export const getStickyNavHeaderVisible = ({ auth: state }) => state.stickyNavHeaderVisible;

/** @param {{auth: defaultState}} state */
export const getToken = ({ auth: state }) => state.token;

/** @param {{auth: defaultState}} state */
export const getRedirectTarget = ({ auth: state }) => state.redirectTarget;

/** @param {{auth: defaultState}} state */
export const getUserId = ({ auth: state }) => state.userId;

/** @param {{auth: defaultState}} state */
export const getPolicies = ({ auth: state }) => state.policies;

/** @param {{auth: defaultState}} state */
export const getPaymentType = ({ auth: state }) => state.paymentType;

/** @param {{auth: defaultState}} state */
export const getRenewablePolicies = ({ auth: state }) => state.renewablePolicies;

/** @param {{auth: defaultState}} state */
export const getCoverNotePolicies = ({ auth: state }) => state.coverNotePolicies;

/** @param {{auth: defaultState}} state */
export const getExtendablePolicies = ({ auth: state }) => state.extendablePolicies;

/** @param {{auth: defaultState}} state */
export const getValuationStatus = ({ auth: state }) => state.valuationStatus;

/** @param {{auth: defaultState}} state */
export const getTransactionType = ({ auth: state }) => state.transactionType;

/** @param {{auth: defaultState}} state */
export const getActivePolicy = ({ auth: state }) => state.activePolicy;

/** @param {{auth: defaultState}} state */
export const getActivePolicies = ({ auth: state }) => state.activePolicies;

/** @param {{auth: defaultState}} state */
export const getActiveRisk = ({ auth: state }) => state.activeRisk;

/** @param {{auth: defaultState}} state */
export const getBreakdown = ({ auth: state }) => state.breakdown;

/** @param {{auth: defaultState}} state */
export const getPromoCodesApplied = ({ auth: state }) => state.promoCodes;

/** @param {{auth: defaultState}} state */
export const getOtherCalculations = ({ auth: state }) => state.otherCalculations;

/** @param {{auth: defaultState}} state */
export const getAuthFormInfo = ({ auth: state }) => state.authFormInfo;

/** @param {{auth: defaultState}} state */
export const getHalfPayment = ({ auth: state }) => state.halfPayment;

/** @param {{auth: defaultState}} state */
export const getPaymentPlans = ({ auth: state }) => state.paymentPlans;

/** @param {{auth: defaultState}} state */
export const getPolicyPaymentPlan = ({ auth: state }) => state.policyPaymentPlan;

/** @param {{auth: defaultState}} state */
export const getMissingUserData = ({ auth: state }) => state.missingUserData;

/** @param {{auth: defaultState}} state */
export const getNoLossDeclarationStatus = ({ auth: state }) => state.noLossDeclarationStatus;

/** @param {{auth: defaultState}} state */
export const getHasMotorPolicies = ({ auth: state }) => state.hasMotorPolicies;

/** @param {{auth: defaultState}} state */
export const getHasHomePolicies = ({ auth: state }) => state.hasHomePolicies;

/** @param {{auth: defaultState}} state */
export const getHasLiabilitiesPolicies = ({ auth: state }) => state.hasLiabilityPolicies;

/** @param {{auth: defaultState}} state */
export const getSelectedSession = ({ auth: state }) => state.selectedSession;

export const {
	setSideBarInfo,
	setMissingDocuments,
	setPreviousNavigationLink,
	setPayBalanceUserProgress,
	setNotification,
	login,
	logout,
	setToken,
	setStickyNavHeaderVisible,
	setRedirectTarget,
	setUserFormData,
	setCurrentUser,
	setPolicyHolders,
	setBreakdown,
	setUserId,
	setPolicies,
	setActivePolicy,
	setActivePolicies,
	setActiveRisk,
	setRenewablePolicies,
	setCoverNotePolicies,
	setAuthFormInfo,
	setHalfPayment,
	setPaymentPlans,
	setExtendablePoliciesFetched,
	setPaymentType,
	setValuationStatus,
	setOtherCalculations,
	setExtendablePolicies,
	setPersistUser,
	setTransactionType,
	setPolicyPaymentPlan,
	addPromoCode,
	removePromoCodeByIndex,
	setMissingUserData,
	setNoLossDeclarationStatus,
	setHasMotorPolicies,
	setHasHomePolicies,
	setHasLiabilitiesPolicies,
	setSelectedSession,
} = authSlice.actions;
export default authSlice.reducer;

